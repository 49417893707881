@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lexend&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hanken+Grotesk&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://free.bboxtype.com/embedfonts/?family=FiraGO:100,200,400,500,600,700,800);
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&family=Lato:wght@100;300;400;500;700&family=Montserrat:wght@300;500;600;700;900&family=Open+Sans:wght@300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&family=Inter:wght@300;400;500;600;700&family=Lexend&family=Hanken+Grotesk&family=Patrick+Hand&family=Mulish:wght@300;400;500;600;700;800;900&display=swap"); */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.home-main {
  overflow-x: hidden;
}
img {
  -webkit-user-select: none;
          user-select: none;
}

body {
  overflow-x: hidden;
}
.no-scroll {
  overflow-y: hidden;
}

/* Stop auto zoom when input focused */
@media (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
  .pswp__button--close {
    margin-right: 10px !important;
  }
}
:root {
  --white: #ffffff;
  --brown: #b44d2d;
  --bg-color: #f1f1f3;
  --text-black: #3a3d43;
  --dark: #393b40;
  --listCardBorder: #d6d6d6;
}

.gm-bundled-control-on-bottom {
  top: 300px;
}

.gm-fullscreen-control {
  top: 200px !important;
}

a {
  text-decoration: none;
}

.error-handler-contianer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cards {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.new-items-titles {
  font-size: 28px;
  color: rgba(58, 61, 67, 1);
}

.mobile-cart-fav {
  display: none;
}

/* .card {
  display: flex;
  margin-bottom: 20px;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.card img {
  width: 100px;
  height: 300px;
} */

.popular-items {
  margin-top: 180px;
}

.right-block {
  margin-left: 26px;
  margin-top: 10px;
}
.sale {
  width: 83px;
  height: 40px;
  background-color: #742233;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Lato";
  font-size: 20px;
  margin-bottom: 26px;
}

.item-title {
  font-size: 18px;
  color: var(--brown);
  margin-bottom: 12px;
}

.wine-color,
.region {
  margin-bottom: 11px;
  color: #6f6a68;
  font-size: 14px;
  font-weight: bold;
}

.item-grape {
  margin-bottom: 7px;
  color: #939673;
  font-size: 14px;
  font-weight: 400;
}

.item-characteristic {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
}

.item-final-price {
  margin: 16px 0 5px 0;
  font-family: "Roboto";
  font-size: 18px;
  color: #27242c;
  font-weight: bold;
}

.product-left-block {
  width: 40%;
  height: 300px;
  margin-right: 27px;
}

.product-item-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.card-wine-type {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 17px;
  color: #939673;
  font-weight: 400;
}

.item-first-price {
  color: #cccccc;
  font-family: "Roboto";
  font-weight: bold;
  text-decoration: line-through;
}

.padd {
  padding: 0 10.5%;
}
.notsale {
  background-color: white;
  width: 83px;
  height: 40px;
  margin-bottom: 26px;
}

.view-all,
.view-all-deskt {
  display: flex;
  justify-content: flex-end;
  padding-right: 10.5%;
  text-decoration: none;
}

.view-all-mob {
  display: none;
}
.view-all-btn {
  border: none;
  cursor: pointer;
  background-color: white;
  color: #72665a;
  font-size: 16px;
  border-bottom: 1px solid #8a8177;
  text-decoration: none;
}
.our-wine-cards {
  position: relative;
  transition: transform 0.2s;
  display: flex;
}

.our-wine-cards:hover {
  cursor: pointer;
  transform: scale(1.09);
}
/* .our-wine-card {
  margin-bottom: 25px;
} */
.our-wine-cards:hover .overlay {
  opacity: 1;
}

.overlay {
  width: 180px;
  height: 80px;
  background-color: rgba(39, 36, 44, 0.58);
  opacity: 0;
  position: absolute;
  top: 53%;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
}

.cart-icon-cont {
  position: relative;
  margin-right: 10px;
  text-decoration: none;
}

.cart-icon-cont:nth-child(2) {
  margin-right: 0;
}

.cart-number {
  position: absolute;
  top: -11px;
  right: -6px;
  color: var(--white);
  background-color: var(--brown);
  border-radius: 50%;
  padding: 3px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
}

.cart-icon {
  font-size: 30px;
  color: black;
}
/* .swiper-scrollbar-drag {
  width: 251px !important;
} */

@media (max-width: 1024px) {
  .padd {
    padding: 0 20px;
  }
  .our-wine-cards:hover {
    cursor: pointer;
    transform: scale(1);
  }
  .view-all {
    padding-right: 15px;
  }
  .view-all-deskt {
    padding-right: 15px;
  }
}

@media (max-width: 640px) {
  .overlay {
    display: none;
  }
  .view-all-deskt {
    display: none;
  }
  .mobile-cart-fav {
    display: flex;
  }
  .price-cont {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }
  .item-final-price {
    margin: 0 8px 0 0;
  }
  .view-all-mob {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  .new-items-titles {
    margin-top: 80px;
    font-size: 20px;
  }
}

.title-line {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2px solid #dedede;
  width: 289px;
  height: 1px;
  margin-bottom: 60px;
}

.title-line2 {
  border: 0.2px solid #de6d4a;
  width: 166px;
}
.line-center {
  display: flex;
  justify-content: center;
}

@media (max-width: 640px) {
  .title-line {
    width: 223px;
    margin-bottom: 20px;
  }
  .title-line2 {
    width: 128px;
  }
}

.footer-container {
  padding: 90px 230px 0 230px;
}

.footer-desk {
  background-color: #24262c;
}

.footer-mob {
  display: none;
}
.line {
  border: 1px solid #4b4b4b;
  width: 100%;
  height: 1px;
  margin-top: 117px;
  display: flex;
  justify-content: center;
}

.fourth-block p {
  width: 400px;
}

/* block 1 style  */

.first-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.phone {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.phone:nth-child(1) {
  margin-top: 0;
}

.footer-first-row {
  display: flex;
}

.phone img {
  margin-right: 11px;
}

.phone p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
}
.footer-logo {
  width: 120px;
  height: 65px;
  margin-bottom: 60px;
  margin-right: 106px;
}

/* block 2 */

.sec-block,
.third-block {
  display: flex;
  flex-direction: column;
}

footer ul {
  list-style: none;
}
.third-block-span {
  margin-bottom: 40px;
}
.sec-block span,
.third-block span,
.fourth-block span {
  color: #de7453;
  font-size: 22px;
  opacity: 90%;
  margin-bottom: 24px;
  font-family: "Popins", sans-serif;
}

.sec-block li,
.third-block li {
  margin-bottom: 10px;
}
.sec-block li a,
.third-block li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.fourth-block p {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 23px;
  font-size: 14px;
  padding-right: 100px;
}

/* Section style */

.second-section ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 97px;
}

.second-section ul li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  margin-right: 70px;
}

.second-section p {
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 38px;
  font-size: 12px;
  margin-bottom: 12px;
}

.footer-bg {
  width: 100%;
  height: 35px;
  border: none;
  border-top-left-radius: 100%;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #24262c;
  flex-direction: column;
  padding-bottom: 41px;
}

.footer-socials {
  display: flex;
  align-items: center;
  margin-top: 58px;
  justify-content: space-between;
  width: 186px;
}
.footer-socials p {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f1f1f1;
}

.footer-socials a {
  width: 34px;
  height: 34px;
  background-color: rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.footer-logo {
  width: 104px;
  height: 52px;
  -webkit-user-select: none;
          user-select: none;
}

.footer-first-section {
  display: flex;
  border-bottom: 1px solid #4b4b4b;
  justify-content: space-between;
  padding-bottom: 100px;
  width: 100%;
  /* padding: 0 10.5%; */
  padding: 100px 10.5%;
}

/* .logo-wrapper {
  margin-right: 100px;
} */

.footer-second-row span {
  color: rgba(255, 255, 255, 0.8);
  font-size: 22px;
  margin-bottom: 24px;
  font-family: "Popins", sans-serif;
  margin-bottom: 50px;
}

.footer-second-row a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 400;
}

/* .footer-second-row {
  margin-right: 200px;
} */

.footer-second-row::nth-child(:last-child) {
  margin-right: 0;
}

.links-cont {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.footer-second-row p {
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin-bottom: 12px;
}

.rights-p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin-top: 40px;
  font-family: "Montserrat";
  font-weight: 300;
}

.links-row {
  display: flex;
}

.second-section-ul {
  display: flex;
  text-decoration: none;
  margin-top: 60px;
}

.second-section-ul a,
.footer-ul a,
.footer-first-row p {
  font-family: "Montserrat";
  font-weight: 300;
}

.footer-ul li {
  margin-bottom: 15px;
}
.second-section-ul li {
  margin-right: 60px;
}

.second-section-ul li:nth-child(:last-child) {
  margin-right: 0;
}

.footer-first-section ul {
  margin-top: 17px;
}

.second-section-ul a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}

.about-me-p {
  font-family: "Montserrat";
  font-weight: 300;
  width: 400px;
}

@media (max-width: 1024px) {
  .footer-first-section {
    flex-direction: column;
  }
  .logo-wrapper {
    margin-bottom: 50px;
  }

  .footer-second-row {
    margin-right: 25px;
  }
  .about-me-p {
    width: 200px;
  }
  .footer-logo {
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .links-cont {
    flex-direction: column;
  }
  .footer-second-row {
    margin-top: 30px;
  }
  .second-section-ul li {
    margin-right: 10px;
  }
  .second-section-ul {
    padding-left: 30px;
  }
  .second-section-ul a {
    font-size: 14px;
  }
  footer {
    color: rgba(255, 255, 255, 0.8);
  }
  .footer-desk {
    display: none;
  }
  .footer-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    padding: 57px 47px 28px 47px;
  }
  .footer-mob-img-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
  .footer-mob-img-wrapper span {
    color: #70707047;
    margin-left: 14px;
    font-family: "Montserrat";
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  .footer-mob-img-wrapper img {
    width: 16px;
    height: 16px;
  }
  .about-us-title {
    margin-top: 78px;
    color: rgba(255, 255, 255, 0.6);
    font-family: "Helvetica";
    font-size: 16px;
  }
  .about-me-p-mob {
    text-align: center;
    font-family: "Helvetica Neue";
    line-height: 24px;
    font-size: 12px;
    margin-bottom: 68px;
    color: rgba(255, 255, 255, 0.8);
  }
  .footer-mob a {
    text-decoration: none;
    color: rgba(112, 112, 112, 0.28);
    cursor: pointer;
    margin-bottom: 22px;
    line-height: 17px;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 300;
  }
  .rights-p-mob {
    margin-top: 98px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 10px;
    text-align: center;
    line-height: 12.19px;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 100%;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: background-color 0.8s ease-in-out;
}

.navbar-logo-mob {
  display: none;
}

.header.active {
  background-color: #272a31;
}

.logo {
  width: 120px;
  height: 50px;
  -webkit-user-select: none;
          user-select: none;
}

.navbar-logo {
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  background-color: #272a31;
  opacity: 0.9; */
  display: flex;
  align-items: center;
  width: 35%;
  background-color: #272a31;
  opacity: 0.9;
  padding-left: 10.5%;
}

.navbar-logo.active {
  background-color: var(--white);
  transition: background-color 0.8s ease-out;
}

.navbar-ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10.5% 0 5%;
  list-style: none;
  width: 65%;
  background-color: var(--white);
  transition: background-color 0.8s ease-out;
  /* position: relative; */
  -webkit-user-select: none;
          user-select: none;
}

.wine-catalog-menu {
  display: inline-block;
  background-color: rgba(62, 62, 62, 0.59);
  padding: 19px 0 22px 25px;
  font-family: "Lato";
  font-weight: bold;
  color: white;
  position: relative;
}

.profile-menu {
  display: inline-block;
  background-color: rgba(62, 62, 62, 0.59);
  /* padding: 10px; */
  font-family: "Lato";
  font-weight: bold;
  color: white;
}
.catalog-Dropdown {
  position: absolute;
  left: 0%;
  top: 100%;
  display: flex;
  align-items: flex-start;
  font-family: "Lato";
  font-weight: bold;
  color: white;
  cursor: pointer;
  animation: catalog-slide-downs 0.8s ease-in-out;
}

@keyframes catalog-slide-downs {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.wine-catalog-link {
  min-width: 200px;
}
.article-Dropdown {
  position: absolute;
  left: 0%;
  top: 100%;
  display: flex;
  align-items: flex-start;
  font-family: "Lato";
  font-weight: bold;
  color: #272a31;
  animation: article-slide-downs 0.8s ease-in-out;
}

@keyframes article-slide-downs {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.profile-Dropdown {
  position: absolute;
  left: -150%;
  top: 89%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-family: "Lato";
  font-weight: bold;
  color: #272a31;
  width: 130px;
  animation: profile-slide-down 0.8s ease-in-out;
}

.log-out-btn-navbar {
  color: white;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-left: 10px;
}
@keyframes profile-slide-down {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.profile-sub-menu {
  display: flex;
  flex-direction: column;
  padding: 22px 15px;
}
.profile-Dropdown button {
  width: 100px;
  height: 50px;
  color: #272a31;
  background-color: var(--white);
  margin: 10px 0;
  font-family: "Lato";
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.catalog-sub-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 200px;
}

.catalog-sub-menu a {
  margin-top: 10px;
}

.catalog-sub-menu a:hover {
  color: #e79a74;
}
.wine-catalog-lists {
  display: flex;
  flex-direction: column;
  background-color: rgba(62, 62, 62, 0.59);
  padding: 19px 100px 22px 25px;
  animation: slide-down-wine-catalog 0.8s ease-in-out;
  position: absolute;
  top: -14%;
  left: 100%;
}

@keyframes slide-down-wine-catalog {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.wine-catalog-link {
  font-family: "Lato";
  font-weight: bold;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid white;
}

.wine-catalog-link:nth-child(1) {
  padding-top: 0;
  margin-top: 0;
}

.wines-link {
  color: white;
}

.wine-catalog-link:hover {
  color: #e79a74;
}

.wine-catalog-lists-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #272a31;
  padding: 20px 0;
}
.wine-catalog-link-mob {
  display: block;
  border-bottom: 1px solid #e79a74;
  padding: 10px;
}

.wine-catalog-lists-mob a {
  color: white;
}
.navbar-ul.active {
  background-color: #272a31;
  color: var(--white);
}

.navlink {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* margin-right: 65px; */
  color: #262a2e;
  font-size: 14px;
  font-weight: bold;
  height: 10vh;
  transition: background-color 0.8s ease-out;
}

.navlink.navactive {
  color: var(--white);
}

.nav-item::after,
.nav-item-event::after {
  content: "";
  display: block;
  height: 4px;
  /* width: 100%; */
  background-color: #e79a74;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  /* transform-origin: left; */
}
.nav-item:hover::after,
.nav-item-event:hover::after {
  transform: scaleX(1);
}

.nav-item-event {
  position: relative;
}

.nav-item {
  position: relative;
}

.active {
  color: #e79a74;
}

.wineri-dropMenu {
  position: absolute;
  top: 50px;
  width: 100%;
  background: rgba(62, 62, 62, 0.59);
  text-align: start;
  list-style: none;
  padding-bottom: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 10000;
}

.wineri-dropMenu a {
  text-decoration: none;
  color: var(--white);
}

.wineri-dropMenu li {
  margin-top: 20px;
}

.burger-menu-tab-mob {
  display: none;
}

.profile-ul-tab {
  display: none;
}
@media (max-width: 640px) {
  .navbar-ul {
    display: none;
  }
  .navbar-logo-mob {
    display: block;
    margin-left: 25px;
  }
  .navbar-logo {
    display: none;
  }

  .burger-menu {
    color: white;
  }
  .burger-menu.active {
    color: white;
  }
  .header {
    background-color: #2f3238;
    width: 100%;
  }
  .navbar-ul-tab {
    list-style: none;
  }
  .navbar-logo {
    background-color: #2f3238;
    opacity: 1;
  }
  .header.active {
    background-color: #2f3238;
  }
  .navbar-ul.active {
    background-color: white;
    color: black;
  }
}

@media (max-width: 1024px) {
  .burger-menu-tab-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .profile-ul-tab {
    display: block;
    width: 100%;
    animation: burger-down 0.8s ease-in-out;
  }

  @keyframes burger-down {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .nav-item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    background-color: #2f3238;
  }
  .mob-log-out-btn {
    background-color: #e79a74;
    color: var(--white);
    padding: 10px 5px;
    border: none;
    cursor: pointer;
  }

  .navbar-logo-mob {
    display: block;
    margin-left: 25px;
  }
  .navbar-logo {
    display: none;
  }

  .burger-menu {
    color: white;
  }
  .burger-menu.active {
    color: white;
  }
  .header {
    background-color: #2f3238;
    width: 100%;
  }
  .navbar-ul-tab {
    list-style: none;
  }
  .navbar-logo {
    background-color: #2f3238;
    opacity: 1;
  }
  .navlink {
    border-bottom: 1px solid rgba(216, 217, 218, 0.2);
  }
  .header.active {
    background-color: #2f3238;
  }
  .navbar-ul.active {
    background-color: white;
    color: black;
  }
  .navbar-ul {
    display: none;
  }
  .container {
    display: flex;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-logo {
    width: 50%;
    height: 100px;
  }
  .burger-menu {
    margin-right: 10px;
  }

  .burger-menu.active {
    color: white;
  }
  .lang-reg {
    padding-right: 20px;
  }
  .navlink {
    color: var(--white);
    background-color: #2f3238;
    margin: 0 12%;
  }
  .navbar-ul-tab {
    animation: burger-down 0.8s ease-in-out;
    max-height: 100vh;
    overflow-y: auto;
  }

  @keyframes burger-down {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.event-down {
  width: 366px;
  height: 124px;
  background: rgba(62, 62, 62, 0.59);
  padding: 25px;
  border: none;
}

.event-down.active {
  background: rgba(255, 255, 255, 0.59);
}

.event-down span {
  color: var(--white);
}

.future-event {
  display: flex;
  align-items: center;
}

.future-event.active span {
  color: #3a3d43;
}
.future-event.active img {
  filter: brightness(0) saturate(100%) invert(20%) sepia(16%) saturate(304%)
    hue-rotate(182deg) brightness(97%) contrast(88%);
}

.future-event span {
  margin-left: 12px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
}

.event-info.active span {
  color: #3a3d43;
}

.event-down-line {
  width: 100%;
  border: 1px solid #767676;
  margin: 15px 0;
}

.event-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-info span {
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 400;
}

.triagle {
  width: 0;
  height: 0;

  border-bottom: 29px solid rgba(62, 62, 62, 0.59);
  opacity: 1;
  border-right: 29px solid transparent;
}

.triagle.triagleActive {
  width: 0;
  height: 0;

  border-bottom: 29px solid rgba(255, 255, 255, 0.59);

  opacity: 1;
  border-right: 29px solid transparent;
}

.event-container {
  position: absolute;
  top: 60px;
  left: 32px;
  z-index: 99;
  animation: event-down 0.8s ease-in-out;
}

@keyframes event-down {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.event-loader {
  --dim: 2px;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
  margin-left: 50%;
  margin-top: 14px;
}
.event-loader .circle {
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.event-loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.event-loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.event-loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.event-loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%,
  25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%,
  50% {
    transform: scale(1) rotate(180deg);
  }

  70%,
  75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .event-container {
    top: 54%;
    left: 20%;
  }
  .event-down {
    width: 290px;
  }
}


.form-overlay {
  position: fixed;
  top: 150px;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
}

.btn {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  border: none;
  width: 100px;
  height: 50px;
  color: #292b31;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 1px solid #2e3137;
  cursor: pointer;
}

.registration-form {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 80px 167px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
  border-radius: 4px;
}

.registration-form h2 {
  text-align: center;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 24px;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 525px;
}

.pass-input,
.email-input,
.country-select {
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
  outline: none;
  border: none;
  border: 1px solid #d9d9d9;
  padding-left: 16px;
  border-radius: 4px;
  opacity: 0.7;
  color: rgba(41, 44, 49);
}
.country-select {
  -webkit-appearance: none;
  appearance: none;
  /* border: none; */
  margin-bottom: 24px;
  outline: none;
  border: none;
  border: 1px solid #d9d9d9;
  padding-left: 16px;
  border-radius: 4px;
  color: rgba(41, 44, 49, 0.5);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAJCAYAAAALpr0TAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgBhY7dCcJAEIRn9/TdChS7iAT/SCHRDixBO4idWIEeCPdsBWIkBaSC22QhecjBJfM0++3ADG22xz0JHTAmks+MwBaECVHNBCmmYvB48Nz4W2t/sYzoT+TK1tpawKdoKfzFuWdp9Kj+33K5Wi9anAxDUrj3666eexhO0MqOYRAMJxihs7LYJKS7LE/SLA95A9YFMrOIn/tZAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 10px;
  padding: 15px 20px;
  width: 100%;
  height: 48px;
}

.input-error {
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
  outline: none;
  border: none;
  padding-left: 16px;
  border-radius: 4px;
  border: 1px solid #fe4567;
  opacity: 0.7;
}

.input-error-message {
  color: #fe4567;
  opacity: 0.7;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 0px 0px 5px 10px;
}
.pass-input::placeholder,
.email-input::placeholder {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
}

.close-btn {
  position: absolute;
  right: 26px;
  top: 30px;
  font-size: 30px;
  cursor: pointer;
}

.btn-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.checkbox-input {
  width: 24px;
  height: 24px;
  border: none;
  border: 1px solid #d9d9d9;
  margin-right: 8px;
}

.checkbox-label {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #222e2e;
  margin-left: 6px;
}

.pass-recover {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Inter";
  color: #20302d;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}
.submit-button {
  width: 176px;
  height: 60px;
  margin-top: 30px;
  border: none;
  background-color: #b44d2d;
  color: var(--white);
  border-radius: 4px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 27px;
  cursor: pointer;
  transition: all 1s ease-in-out;
}

.submit-button:hover {
  color: #b44d2d;
  background-color: var(--white);
  border: 1px solid #d9d9d9;
}
button:disabled {
  opacity: 0.7;
}

.reg-btn {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.registration-with-social {
  font-family: "Lexend";
  color: #101828b2;
  font-size: 14px;
}

.social-icons {
  width: 25px;
  height: 25px;
}

.social-bg {
  width: 70px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 12px;
}

.social-title {
  font-family: "Hanken Grotesk";
  color: #101828cc;
}

.registration-sign-in-btn {
  background-color: transparent;
  border: none;
  color: #000000;
  font-family: "Montserrat";
  font-weight: 800;
  cursor: pointer;
  font-size: 18px;
  margin-left: 5px;
  text-decoration: none;
}

.reg-container {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.container {
  display: block;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  position: relative;
}

.container input {
  position: absolute;
  transform: scale(0);
}

.container input:checked ~ .checkmark {
  transform: rotate(40deg);
  height: 1em;
  width: 0.7em;
  border-color: #222e2e;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
}

.container .checkmark {
  display: block;
  width: inherit;
  height: inherit;
  border: 2px solid #222e2e;
  border-radius: 4px;
  transition: all 0.3s;
}

.reg-title {
  color: #000000;
  font-family: "Montserrat";
  font-weight: 800;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
}
@media (max-width: 1024px) {
  .registration-form {
    padding: 80px 110px;
  }
}

@media (max-width: 640px) {
  .registration-form {
    padding: 30px;
  }
  .login-form {
    width: 300px;
  }
  .form-overlay {
    position: fixed;
    top: 100px;
    left: 0;
  }
}

.carousel-map-container {
  border-radius: 10px;
  height: 100vh;
  position: relative;
}
.google-map-search-container {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 99;
  padding: 0 16px 0 20px;
  background-color: #292c31;
  width: 25%;
  top: 2.5%;
  left: 2.5%;
  min-height: 77.5vh;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.location-slider-thumbs {
  position: absolute;
  width: 90%;
  bottom: 0;
  left: 2%;
}

/* .input-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.input-wrapper input {
  padding-left: 4%;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  min-height: 6vh;
  color: var(--white);
} */

select {
  -webkit-appearance: none;
  appearance: none;
}
.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "\25BC";
  font-size: 0.7rem;
  top: 24%;
  right: 8%;

  position: absolute;
  color: white;
}
.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 4%;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  color: var(--white);
}

.google-map-search .MuiInputBase-input {
  height: 4vh;
}
.google-map-search .TextField__label {
  color: white;
}
.google-map-search .MuiInputBase-input,
.google-map-search .MuiButtonBase-root,
.google-map-search .MuiFormLabel-root,
.google-map-search .MuiFormLabel-root .Mui-focused {
  color: white;
}
.google-map-search .css-p0rm37 {
  color: white;
}

.google-map-search .MuiButtonBase-root {
  font-size: 0.7rem;
  padding-right: 18.5px;
}

.google-map-search .MuiAutocomplete-input:focus {
  outline: none;
}
/* .google-map-search .MuiAutocomplete-root {
  border: 1px solid red;
} */

.search-icon2 {
  color: var(--white);
  font-size: 24px;
  position: absolute;
  margin-right: 20px;
}
.input-wrapper input::placeholder {
  color: var(--white);
  opacity: 1;
}

.google-map-search {
  width: 100%;
}

.searchIcon {
  position: absolute;
  width: 24px;
  height: 24px;
}

.search-title {
  color: var(--white);
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 5%;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin: 6% 0;
}
.checkbox-wrapper input {
  margin-right: 11px;
  width: 27px;
  height: 27px;
  accent-color: #707070;
}

.checkbox-wrapper label {
  color: var(--white);
  font-family: "Poppins";
  font-size: 16px;
}

.mob {
  display: none;
}

.search-input {
  width: 100%;
  height: 30px;
}

.select-cont {
  display: flex;
  flex-direction: column;
}

.select-label {
  font-family: "Poppins";
  font-size: 16px;
  color: var(--white);
  font-weight: 400;
  margin-bottom: 3%;
}

.select-box {
  margin-bottom: 8%;
  height: 6vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  padding-left: 10px;
  border-radius: 5px;
  border: none;
  color: var(--white);
}

.option {
  color: var(--white);
  height: 6vh;
  width: 100%;
  background-color: rgba(41, 44, 49, 0.8);
  border: none;
  outline: none;
}
.input-wrapper:focus {
  border: 3px solid yellow !important;
}
.search-toggle {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  margin-right: -44px;
  background-color: #292c31;
}
.search-toggle-false {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  margin-right: -44px;
  background-color: #292c31;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  z-index: 2;
  top: 37%;
}

.search-btn {
  width: 144px;
  height: 6vh;
  border: none;
  background-color: var(--brown);
  color: var(--white);
  cursor: pointer;
  border-radius: 5px;
}

.arrow {
  color: var(--white);
  font-size: 35px;
}
/* 
.hidden {
  transform: translateX(-100%);
  opacity: 0;
} */

@media (max-width: 1080px) {
  .google-map-search-container {
    width: 55%;
  }
}
@media (max-width: 640px) {
  .google-map-search-container {
    padding: 25px;
    width: 68%;
  }
  .search-toggle {
    margin-right: -50px;
  }
  .carousel-map-container {
    margin-top: 21px;
  }
  .search-toggle-false {
    width: 65px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    background-color: #292c31;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    z-index: 2;
    top: 10%;
    left: 0%;
    padding: 15px;
  }
  .search-toggle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    margin-right: 0;
    background-color: none;
    position: absolute;
    top: 2%;
    right: 3%;
  }
  .google-map-search-container {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 99;
    padding: 29px 16px 35px 20px;
    background-color: #292c31;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 75vh;
  }
}

.location-slider .swiper-slide {
  padding-top: 70%;
  position: relative;
  overflow: hidden;
}

.location-slider img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.swiper-button-prev {
  left: 30;
  color: black;
}

.swiper-button-next {
  right: 30;
  color: rgb(255, 255, 255);
}

.location-slider-thumbs {
  margin-top: 20px;
}

.region-small-img {
  /* margin-bottom: 42px; */
  height: 70px;
  width: 80px;
  -webkit-user-select: none;
          user-select: none;
}

.locationss {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loc {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
  justify-content: space-around;
  /* padding: 0; */
  width: 162px;
  border-radius: 7px;
  height: 15vh;
  cursor: pointer;
  margin-left: 5px;
}
.loc span {
  -webkit-user-select: none;
          user-select: none;
}

.loc-span {
  color: #2a2d33;
  font-family: "Open Sans";
  font-weight: 600;
}

.swiper {
  margin-bottom: 1% !important;
}

.infoWindow-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  max-width: 300px;
}

.infoWindow-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 7px;
  margin-right: 14px;
}

.infoWindow-content {
  display: flex;
  flex-direction: column;
}

.infoWindow-title {
  font-family: "Lato";
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
  line-height: 19px;
}

.exact-Address {
  font-family: "Lato";
  font-weight: 400;
  color: #272a31;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  width: 98px;
  border: none;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 7px;
  cursor: pointer;
}

.exact-Address img {
  margin-right: 6px;
}
.infoWindow-region {
  font-family: "Lato";
  font-size: 14px;
  margin-bottom: 15px;
  color: #6f6a68;
  line-height: 16px;
}

.infoWindow-street {
  font-family: "Lato";
  font-size: 14px;
}

@media (max-width: 640px) {
  .infoWindow-container {
    flex-direction: column;
    text-align: center;
  }
  .infoWindow-img {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}

.winery-title {
  margin-top: 25px;
  color: rgba(58, 61, 67, 1);
  text-align: center;
  font-size: 32px;
  font-family: "Montserrat";
  font-weight: bold;
  margin-bottom: 21px;
}

.google-map-section {
  padding: 144px 70px 80px;
  background-color: #f1f1f1;
}

.winery-map-mob-tab {
  display: none;
}

@media (max-width: 640px) {
  .google-map-section {
    padding: 20px 0;
    background-color: var(--white);
  }
  .winery-title {
    font-size: 20px;
  }
}

@media (max-width: 1080px) {
  .google-map-section {
    padding: 50px 0;
  }
  .winery-map-mob-tab {
    display: block;
  }
  .winery-map-desktop {
    display: none;
  }
}

.bg-image {
  display: flex;
  height: calc(100vh - 100px);
}
.header-overlay {
  width: 35%;
  background-color: #2f3238;
  /* height: 80vh; */
  /* height: calc(100vh - 100px); */
  display: flex;
  /* justify-content: center; */
  padding-left: 10.5%;
  padding-bottom: 5%;
  padding-top: 5%;
}
.text-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-left: 100px; */
  justify-content: center;
}

.text-block h4 {
  /* font-family: "Montserrat"; */
  font-size: 16px;
  font-weight: 400;
  color: white;
  letter-spacing: 3px;
  margin-bottom: 25px;
  line-height: 19.5px;
}

.mob-home-page-image {
  display: none;
}
.text-block p {
  font-family: "Montserrat";
  color: white;
  margin-bottom: 24px;
  font-size: 36px;
  font-weight: bold;
}

.text-block span {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 36px;
  line-height: 43.88px;
  color: var(--brown);
  margin-bottom: 15px;
}
.follow {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 19%;
  bottom: 36%;
  align-items: center;
}
.follow p {
  writing-mode: vertical-rl;
  font-size: 24px;
  font-weight: bold;
  color: var(--white);
  margin-bottom: 20px;
  opacity: 0.7;
}
.social-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  color: #f8f8f88a;
  opacity: 0.7;
}

.bg-line {
  border-left: 1px solid #f8f8f88a;
  height: 248px;
  margin-bottom: 58px;
  opacity: 0.7;
}

.image-and-social {
  position: relative;
  width: 65%;
}

.main-bg-wine {
  width: 100%;
  height: calc(100vh - 100px);
  object-fit: cover;
}

.buy-now-btn1 {
  width: 177px;
  height: 60px;
  background-color: transparent;
  border: 1px solid #9b9b9b;
  border-radius: 8px;
  margin-top: 15px;
}

.tab-and-mob-follow-cont {
  display: none;
}

@media (max-width: 1024px) {
  .bg-image {
    flex-direction: column;
    margin-bottom: 100px;
    height: 100vh;
  }
  .text-block {
    padding-left: 0;
  }
  .follow {
    right: 5%;
    bottom: 2%;
  }
  .mob-home-page-image {
    display: block;
    position: relative;
  }

  .bg-line {
    height: 50px;
  }
  .header-overlay {
    width: 100%;
    height: 100vh;
    padding-left: 25px;
  }
  .image-and-social {
    display: none;
  }
  .tab-and-mob-follow-cont {
    display: block;
    margin-top: 50px;
  }
  .social-wrapper {
    width: 207px;
    display: flex;
    justify-content: center;
  }
  .social-wrapper a {
    margin-right: 10px;
  }
  .text-block h4 {
    margin-bottom: 20px;
  }
  /* .text-block p {
    margin-bottom: 20px;
  } */
}
@media (max-width: 640px) {
  .text-block p {
    margin-bottom: 0;
  }
  .bg-image {
    margin-bottom: 0;
  }
  .text-block h4 {
    margin-bottom: 0;
    font-size: 14px;
  }
  .text-block p {
    font-size: 32px;
  }
  .text-block span {
    font-size: 32px;
  }
  .text-block {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
}

/* .card-container {
  padding: 0 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.div1 {
  grid-area: 1 / 1 / 3 / 3;
}
.div2 {
  grid-area: 1 / 3 / 3 / 5;
}
.div3 {
  grid-area: 1 / 5 / 3 / 7;
}
.div4 {
  grid-area: 3 / 1 / 5 / 4;
}
.div5 {
  grid-area: 3 / 4 / 5 / 7;
}

.div1,
.div2,
.div3,
.div4,
.div5 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.images1 {
  width: 100%;
  height: 100%;
  user-select: none;
  transition: all 1.1s;
}

.images2 {
  width: 100%;
  height: 100%;
  user-select: none;
  transition: all 1.1s;
}

.div4,
.div5 {
  height: 291px;
}

.div1:hover .images1,
.div2:hover .images1,
.div3:hover .images1,
.div4:hover .images2,
.div5:hover .images2 {
  transform: scale(1.2);
}


.article-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 319px;
}

.article-title2 {
  width: 319px;
}

.artilcle-title {
  background: rgba(18, 13, 13, 0.25);
  padding: 11px 20px;
  color: white;
  font-size: 16px;
}



.image,
.image2 {
  width: 100%;
  position: relative;
}
.three-cards-row {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.two-cards-row {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 27px;
  margin-bottom: 160px;
}

.article-title2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    padding: 0;
    margin-bottom: 50px;
  }

  .div1 {
    grid-area: 1 / 2 / 3 / 6;
  }
  .div2 {
    grid-area: 3 / 2 / 5 / 6;
  }
  .div3 {
    grid-area: 5 / 2 / 7 / 6;
  }
  .div4 {
    grid-area: 7 / 2 / 9 / 6;
  }
  .div5 {
    grid-area: 9 / 2 / 11 / 6;
  }

  .div1,
  .div2,
  .div3,
  .div4,
  .div5 {
    min-width: 250px;
    height: auto;
  }

  .images2 {
    height: 100%;
  }
  .artilcle-title {
    font-size: 9px;
  }
}
@media (max-width: 1024px) {
  h3 {
    margin-top: 80px;
  }
  .card-container {
    margin-top: 24px;
  }
  .article-title2 {
    top: 70%;
  }
} */

h3 {
  display: flex;
  justify-content: center;
  margin-top: 179px;
  margin-bottom: 14px;
  color: rgba(58, 61, 67, 1);
}

.article-home-page-width {
  width: 100%;
}
@media (max-width: 1240px) {
  .article-home-page-width {
    width: 90%;
  }
}

.contact-main {
  overflow-x: hidden;
}
.contact-bg {
  width: 100%;
  height: 290px;
  background: url("/static/media/Path 10372.6566d83f.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 130px;
}

.contact-section1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 41px;
}

.rigth-bg-contact {
  width: 40%;
  height: 80vh;
  /* background-image: url("../../images/contactPage/map.png");
  background-repeat: no-repeat; */
}
.contact-left-block {
  width: 30%;
  margin-right: 168px;
}

.contact-main-title {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #24262d;
  text-align: center;
  margin-top: 128px;
  margin-bottom: 24px;
}

.contact-desc {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8e8e93;
  margin-bottom: 50px;
}

.contact-contact-detail {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contact-contact-detail:nth-last-child() {
  margin-bottom: 0;
}

.detail-column {
  display: flex;
  flex-direction: column;
}

.contact-contact-detail img {
  -webkit-user-select: none;
          user-select: none;
  margin-right: 20px;
}

.adress-title {
  font-family: "Lato";
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #8e8e93;
}

.contact-detail-info {
  font-family: "Lato";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3a3a3c;
}

.contact-section2 {
  /* width: 100vw; */
  /* height: 100vh; */
  background-color: #f1f1f3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 169px;
  padding-bottom: 220px;
}

.contact-form-container {
  padding: 30px;
  background-color: var(--white);
  box-shadow: 0px 16px 40px 0px rgba(66, 71, 76, 0.1);
  border-radius: 10px;
}

.contact-title-desc {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.message-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 29px;
  background-color: #b44d2d;
  color: var(--white);
  border: none;
  border-radius: 10px;
  margin-top: 41px;
  cursor: pointer;
  margin-bottom: 10px;
}

.message-btn img {
  -webkit-user-select: none;
          user-select: none;
  margin-right: 10px;
}

.contact-title {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #24262d;
  text-align: center;
  margin-bottom: 10px;
}

.contact-desc {
  font-family: "Lato";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #8e8e93;
  margin-bottom: 80pxs;
}

.contact-inputs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}
.contact-label-input {
  display: flex;
  flex-direction: column;
}

.contact-label-input:nth-child(1) {
  margin-right: 22px;
}

.contact-texarea {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  margin-top: 10px;
}

.contact-texarea textarea {
  height: 140px;
  resize: none;
  outline: none;
  border: 1px solid #e5e5ea;
  padding: 16px;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3a3a3c;
}
.contact-label-input label,
.contact-texarea label {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1c1c1e;
  margin-bottom: 10px;
}

.contact-label-input input {
  height: 55px;
  border-radius: 10px;
  width: 28vw;
  border: 1px solid #e5e5ea;
  outline: none;
  padding-left: 16px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3a3a3c;
}

.section-one-bg-mob-contact {
  display: none;
}

.name-email-error {
  color: red;
  font-family: "Lato";
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width: 1020px) {
  .contact-left-block {
    width: 100%;
    margin-right: 0;
    padding: 20px;
  }
  .rigth-bg-contact {
    display: none;
  }
  .contact-bg {
    display: none;
  }
  .section-one-bg-mob-contact {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/bgMob.7512f2e7.jpg);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
}

@media (max-width: 640px) {
  .contact-section1 {
    margin-top: 0;
  }
  .contact-main-title {
    margin-top: 64px;
  }
  .contact-inputs-container {
    flex-direction: column;
  }
  .contact-section2 {
    padding-top: 30px;
    padding-bottom: 110px;
  }
  .contact-label-input {
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-label-input:nth-child(1) {
    margin-right: 0;
  }
  .contact-label-input:nth-child(2) {
    margin-bottom: 0;
  }
  .contact-bg {
    display: none;
  }
  .contact-label-input input,
  .contact-texarea textarea {
    width: 100%;
  }
  .contact-form-container {
    margin: 50px 0;
  }
}

.winery-section {
  padding: 0 10.5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.winery-experience {
  padding: 0 20px;
}
.border-for-winery {
  border: 1px solid #dedada;
  margin-bottom: 20px;
  width: 350px;
}
.winery-right-block {
  margin-left: 68px;
  width: 350px;
}

.wine-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #3a3d43;
  font-family: "Poppins";
}

.right-block-title {
  font-size: 22px;
  font-weight: bold;
  color: #3a3d43;
  margin-bottom: 37px;
}

.wineyh5 {
  margin-top: 40px;
  margin-bottom: 15px;
  color: #3a3d43;
  font-size: 22px;
  font-weight: bold;
}

.winery-left-block {
  min-width: 70%;
}
.left-block-aboutwine {
  font-family: "Poppins";
  color: #3a3d43;
  font-size: 16px;
  margin-bottom: 45px;
}
.right-block-img {
  width: 100%;
}
.awards {
  color: #3a3d43;
  font-size: 22px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.awards-list {
  margin-bottom: 20px;
  color: #3a3d43;
  font-family: "Poppins";
  font-size: 16px;
}

.our-wines {
  display: flex;
  justify-content: space-between;
  margin-top: 42px;
  margin-bottom: 48px;
}

.our-wines-title {
  color: #3a3d43;
  font-size: 22px;
  font-weight: bold;
}
.duration {
  display: flex;
}

.keyCname {
  width: 155px;
  margin-top: 20px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: var(--text-black);
}
.valueclassName {
  margin-top: 20px;
  font-family: "Poppins";
  font-weight: 400;
  color: var(--text-black);
  opacity: 0.7;
}

.about-wine {
  padding: 32px 0 16px 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  color: var(--text-black);
}

.spans {
  display: flex;
  width: 240px;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 34px;
}

.spans .price {
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins";
  color: var(--dark);
}
.spans .person {
  font-size: 16px;
  font-family: "Poppins";
  color: var(--text-black);
  font-weight: 400;
  opacity: 0.7;
}
.from {
  font-size: 16px;
  color: #404348;
  padding: 20px 0;
  font-weight: 400;
}

.advantages {
  display: flex;
  margin-top: 20px;
}

.advantages img {
  margin-right: 10px;
}
.view-detail {
  border: none;
  background-color: var(--white);
  cursor: pointer;
  border-bottom: 1px solid var(--text-black);
  color: var(--text-black);
  font-size: 14px;
  margin-top: 39px;
}

.input-submit {
  width: 100%;
  height: 57px;
  border: none;
  background-color: var(--brown);
  color: var(--white);
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
.reverve-btn {
  width: 100%;
  margin-top: 31px;
  height: 57px;
  border: none;
  background-color: var(--brown);
  color: var(--white);
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
.client-review {
  font-size: 22px;
  color: var(--text-black);
  margin-top: 60px;
  margin-bottom: 40px;
}
.message-title {
  font-size: 22px;
  color: var(--text-black);
  margin-top: 105px;
  margin-bottom: 12px;
}
.message-inputs {
  background-color: #dedada;
  padding: 22px;
  border-radius: 5px;
  margin-bottom: 144px;
}

.text-input {
  width: 100%;
  height: 55px;
  border: none;
  border-radius: 5px;
  padding: 14px 15px;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 100;
  color: #807c7c;
  margin-bottom: 12px;
  outline: none;
}

.message-textarea {
  width: 100%;
  height: 177px;
  resize: none;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 14px 15px;
}

.tab-lists {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding-left: 10.5%;
  margin-top: 50px;
  margin-bottom: 75px;
}

.tab-Head {
  cursor: pointer;
  padding: 16px;
}

.active-tab {
  border-bottom: 3px solid #e79a74;
}

@media (max-width: 1080px) {
  .winery-section {
    flex-direction: column;
    align-items: center;
  }
  .right-block-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .winery-left-block {
    width: 100%;
  }
  .winery-right-block {
    margin-left: 0;
  }
}

@media (max-width: 640px) {
  .winery-section {
    padding: 0 20px;
  }
  .tab-lists {
    display: none;
  }
  .wine-title {
    display: none;
  }
  .tab-Head {
    padding: 8px;
  }
  .tab-lists {
    padding: 0;
  }
  .winery-right-block {
    padding: 0;
    margin-left: 0;
  }
  .winery-left-block {
    width: 100%;
  }
  .winery-right-block {
    display: none;
  }
}

.line-max-width {
  width: 100%;
  border: 0.2px solid #dedede;
}

.our-wine {
  /* width: 900px; */
  height: 300px;
  margin-bottom: 100px;
}

.our-wine-card {
  display: flex;
  align-items: center;
  width: 300px;
  height: 300px;
  -webkit-user-select: none;
          user-select: none;
}
.view-all-our-wines {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}

.our-wine-cards {
  margin-bottom: 58px;
}

.swiper {
  margin-bottom: 50px;
}

@media (max-width: 1020px) {
  .our-wine-cards {
    margin-right: 10px;
  }
  .our-wine-card {
    width: 290px;
  }
}

.view-all-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 200px;
  flex-wrap: wrap;
  grid-gap: 100px;
  gap: 100px;
}

@media (max-width: 1024px) {
  .view-all-container {
    padding: 0 20px;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10.5%;
}

.review-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 170px;
  color: #3a3d43;
}

.review-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border: 1px solid #dedada;
  padding: 22px 91px;
  min-height: 300px;
}

.review-quote {
  font-family: "Poppins";
  font-size: 16;
  font-weight: 400;
  margin-top: 51px;
  margin-bottom: 15px;
  background-color: #efefef;
  padding: 20px;
  opacity: 0.36;
}

.review-author {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
}

.swiper-pagination-bullet-active {
  background-color: #393b40 !important;
}

@media (max-width: 1080px) {
  .navigation {
    padding: 0 20px;
  }
}

.location-title {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 22px;
  margin: 50px 0;
  color: #3a3d43;
}

.star-input {
  display: none;
}
.stars {
  cursor: pointer;
}

.star-container {
  display: flex;
}

.showreview-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 75px 64px 66px 65px;
  border-radius: 4px;
  z-index: 1000000;
  border: 1px solid rgba(41, 44, 49, 0.4);
}

.star-cont {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.stars-rating-cont {
  display: flex;
  align-items: center;
}

.stars-rating-cont div {
  margin-right: 20px;
}

.stars-rating-cont p {
  color: #3a3d43;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.review-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #3a3d43;
}

.review-label input {
  margin-top: 5px;
  height: 60px;
  width: 589px;
  border-radius: 10px;
  border: 1px solid rgba(41, 44, 49, 0.4);
  outline: none;
  padding-left: 16px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 49, 0.5);
}

.close-Btn {
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
  font-size: 24px;
  color: #222e2e;
}
.review-texarea-label {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #3a3d43;
}

.review-texarea-label textarea {
  height: 179px;
  resize: none;
  outline: none;
  border: 1px solid rgba(41, 44, 49, 0.4);
  padding: 16px;
  border-radius: 10px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 49, 0.5);
  width: 350px;
  margin-top: 5px;
  width: 100%;
}
.showreview-container form {
  display: flex;
  flex-direction: column;
}

.showreview-container button {
  width: 170px;
  height: 62px;
  border-radius: 4px;
  background-color: #b44d2d;
  color: var(--white);
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .showreview-container {
    width: 70%;
  }
  .review-label input {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .showreview-container {
    width: 90%;
    padding: 40px;
  }
  .review-label input {
    width: 100%;
  }
}

.gallery-title {
  text-align: center;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #3a3d43;
  margin-top: 80px;
  margin-bottom: 20px;
}

.gellery-item {
  width: 100%;
  height: 241px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.gellery-item img {
  object-fit: cover;
}

.gallery-grid-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 40px;
  justify-content: space-between;
  padding: 0 10.5%;
}

.contain-object-fit img {
  object-fit: contain;
}

@media (max-width: 1020px) {
  .gallery-grid-container {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
    padding: 0 5%;
  }
}

.similar-wine-section {
  padding: 0 10.5%;
  margin-bottom: 180px;
}

.similar-wine-content {
  border: 0.5px solid #d6d6d6;
}

.similar-wine-img {
  width: 100%;
  height: 241px;
  object-fit: cover;
}

.similar-wine-text-content {
  padding: 20px;
}

.similar-wine-title {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: bold;
  color: var(--dark);
  margin-bottom: 10px;
}

.similar-wine-p {
  font-family: "Poppins";
  overflow: hidden;
  height: 40px;
  font-size: 16px;
  color: rgba(81, 84, 89, 0.94);
  margin-bottom: 27px;
}

.similar-wine-location-rating {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
}

.similar-wine-location,
.similar-wine-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.similar-wine-rating-p {
  margin-right: 8px;
  font-size: 18px;
  color: rgba(58, 61, 67, 0.77);
}
.similar-wine-location-p {
  margin-left: 11px;
  font-family: "Poppins";
  color: rgba(58, 61, 67, 0.64);
  font-size: 16px;
}
.similar-wine-main-title {
  font-size: 22px;
  margin-top: 145px;
  margin-bottom: 35px;
}

.similar-wine-btn {
  width: 150px;
  height: 48px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  border: 0.5px solid #969696;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 500;
}

.wine-btn-flex {
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;
  text-decoration: none;
}

.onlyOne-wineryRegion {
  font-weight: bold;
  font-size: 20px;
  color: #b44d2d;
}

@media (max-width: 640px) {
  .similar-wine-section {
    padding: 0 20px;
  }
}

.wineries-detail-bg {
  width: 100%;
  height: 290px;
  background: url(/static/media/wineriesBg.84d57730.png);
  background-repeat: no-repeat;
  margin-bottom: 151px;
  background-size: cover;
  background-position: center;
}
.wineries-detail-bg-mob {
  display: none;
}

@media (max-width: 640px) {
  .wineries-detail-bg {
    display: none;
  }
  .wineries-detail-bg-mob {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 290px;
    background: url(/static/media/detail-bg-mob.5c89f6b9.png);
    background-repeat: no-repeat;
    margin-bottom: 19px;
    background-size: cover;
    background-position: center center;
  }
}

.winePass-cont-forOverflow {
  overflow-x: hidden;
}

.section-one-bg {
  width: 100%;
  height: 290px;
  background: url("/static/media/Path 10372.34ef612d.png");
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 151px;
  background-size: cover;
  background-position: center;
}
.section-one-bg-mob {
  display: none;
}

.text-container {
  width: 35%;
  height: 100%;
  background-color: #24262c;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  justify-content: center;
  padding-left: 10.5%;
}

.text-container h2 {
  color: white;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 36px;
}

.section-one-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 32px;
  color: #3a3d43;
}

@media (max-width: 1024px) {
  .text-container {
    width: 50%;
  }
}

@media (max-width: 640px) {
  .section-one-title {
    font-size: 15px;
  }

  .section-one-bg {
    display: none;
  }

  .section-one-bg-mob {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 290px;
    background: url(/static/media/mob-wine-passimg.af157d3b.png);
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 70px;
    background-size: cover;
    background-position: center center;
  }
  .text-container {
    width: 100%;
    height: 15vh;
    padding-left: 16px;
    align-items: flex-start;
  }
}

.passport-container {
  display: flex;
  background-color: var(--bg-color);
  margin: 0 10.5%;
  margin-bottom: 213px;
}

.passport-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 0 89px;
}

.priority-winepass-title {
  color: var(--white);
}

/* left block style */
.passport-left-block {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 50%;
  /* padding-right: 127px; */
}

.passport-left-block-title {
  margin-bottom: 29px;
  color: var(--brown);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
}

.passport-left-block-subtitle {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 32px;
  color: #24262b;
  margin-bottom: 30px;
}
.passport-left-block-paragraph {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 300;
  color: var(--text-black);
  margin-bottom: 50px;
}

.passport-left-block-span {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
  color: #3a3d43;
  line-height: 30px;
}

.buy-now-btn {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  background-color: var(--brown);
  width: 170px;
  height: 60px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 63px;
}

.buy-now-btn-mob {
  display: none;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  background-color: var(--brown);
  width: 170px;
  height: 60px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 63px;
}

/* right block style */
.passport-right-block {
  background-color: #3a3c42;
  padding: 61px 67px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-left: 120px;
}

.passport-right-block-title {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 32px;
  color: var(--white);
  margin-bottom: 40px;
}

.passport-right-block-subtitle {
  font-family: "Montserrat";
  font-size: 30px;
  color: var(--white);
  opacity: 0.5;
  margin-bottom: 74px;
}

.prioritywine-wrapper {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
}

.prioritywine-wrapper:nth-child(:last-child) {
  margin-bottom: 0;
}

.prioritywine-img {
  margin-right: 10px;
}

.priority-winepass-title {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: var(--white);
}

.priority-wine-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 72px;
}
.span-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.span-wrapper {
  color: var(--white);
  opacity: 0.5;
  font-family: "Poppins";
  font-size: 16px;
}

.span-value {
  font-family: "Poppins";
  font-size: 16px;
  color: var(--white);
  margin-top: 15px;
}

@media (max-width: 1024px) {
  .passport-container {
    margin: 0 150px 213px 150px;
  }
  .passport-wrapper {
    flex-direction: column;
  }
  .passport-right-block {
    margin-left: 0;
    margin-top: 50px;
  }
  .passport-left-block {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .passport-container {
    margin: 0;
    background-color: var(--white);
  }
  .passport-left-block {
    width: 100%;
    padding: 24px;
    background-color: #f1f1f3;
  }
  .passport-right-block {
    margin-top: 0;
  }
  .passport-wrapper {
    align-items: baseline;
    padding: 33px 10px 40px 10px;
  }
  .passport-right-block-subtitle {
    font-size: 28px;
  }
  .buy-now-btn {
    display: none;
  }
  .buy-now-btn-mob {
    display: block;
    margin-top: 30px;
    margin-bottom: 70px;
  }
}

.benefit-container {
  margin-right: 10.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 236px;
}

.benefit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 50%;
}
.benefit-wrapper:nth-child(1) {
  margin-right: 140px;
}

.image-radius {
  width: 70px;
  height: 70px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 29px;
  -webkit-user-select: none;
          user-select: none;
}

.benefit-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 22px;
  color: var(--dark);
  margin-bottom: 19px;
}

.benefit-info {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  color: var(--dark);
}

.benefit-list1,
.benefit-list2 {
  display: flex;
}

.benefit-list1 {
  margin-bottom: 61px;
}
.benefit-list2 {
  margin-top: 52px;
}

.wine-image-bg {
  width: 659px;
  height: 580px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* mix-blend-mode: multiply; */
  filter: grayscale(70%);
  margin-right: 130px;
}
.wine-image-bg-mob {
  display: none;
}
/* .wine-image-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(58, 60, 66, 0.5);
} */

@media (max-width: 1024px) {
  .benefit-container {
    margin-right: 0;
    margin-bottom: 70px;
    flex-direction: column;
  }
  .wine-image-bg {
    margin-right: 0;
  }
  .benefit-wrapper {
    padding: 15px 35px;
  }
}

@media (max-width: 640px) {
  .benefit-wrapper:nth-child(1) {
    margin-right: 0;
  }
  .benefit-container {
    flex-direction: column;
    align-items: baseline;
  }
  .wine-image-bg {
    display: none;
  }
  .wine-image-bg-mob {
    display: block;
    width: 100%;
    filter: grayscale(70%);
    margin-top: 24px;
    padding: 0 16px;
  }
  .benefit-wrapper {
    padding: 0 20px;
    align-items: center;
    margin-top: 60px;
    width: 100%;
  }
  .benefit-list1,
  .benefit-list2 {
    flex-direction: column;
  }
  .benefit-wrapper p {
    text-align: center;
  }
}

/* package.css */

.package-container {
  display: flex;
  justify-content: space-between;
  padding: 0 10.5%;
  border-radius: 4px;
  margin-bottom: 200px;
}

.package-cont {
  border: 1px solid #d3d5e0;

  width: 32%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d3d5e0;
  margin-right: 70px;
  position: relative;
  box-shadow: 0 4px 11px 3px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.5s ease-in-out; /* Hover transition */
}

.package-cont:nth-child(3) {
  margin-right: 0;
}
.package-wrapper {
  padding: 33px 33px 0 33px;
  overflow: hidden;
  max-height: 80vh;
}

.package-benefit-wrapper {
  display: flex;
  align-items: baseline;
  margin-bottom: 12px; /* Adjust as needed */
}

.package-benefit-wrapper img {
  margin-right: 10px; /* Adjust as needed */
  max-width: 20px;
}

.package-benefit-wrapper p {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  color: #393b40;
}

.price-wrapper {
  display: flex;
  align-items: center;
  margin-top: 72px;
}

.package-wrapper-title {
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 16px;
  color: #393b40;
  margin-bottom: 39px;
}

.price-wrapper p {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 16px;
  margin-right: 10px;
  color: #393b40;
}

.price-wrapper span {
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: 700;
  color: #393b40;
}

.package-btn {
  width: 100%;
  height: 80px;
  border: none;
  cursor: pointer;
  font-family: "Open Sans";
  color: #393b40;
  background-color: #f1f1f3;
  font-weight: 700;
  font-size: 18px;
  transition: background-color 0.3s ease; /* Button hover transition */
  margin-top: 49px;
}

.center-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mark {
  position: absolute;
  top: 3%;
  right: -3.5%;
  width: 120px;
  height: 40px;
  background-color: #b44d2d;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.triangle-topright {
  position: absolute;
  top: 11%;
  right: -3.5%;
  border-style: solid;
  border-width: 25px 12px 0 0;
  border-color: #b44d2d transparent transparent transparent;
  transform: rotate(0);
}

.wine-pass-see-details {
  padding-left: 33px;
  font-family: "Poppins";
  font-weight: 600;
  color: #3a3d43;
  line-height: 30px;
  font-size: 16px;
  text-decoration: underline;
}
.price-wrapper {
  padding-left: 33px;
  margin-bottom: 33px;
}

.wine-pass-coming {
  font-size: 14px;
  margin-top: 10px;
  color: rgba(57, 59, 64, 0.5);
}

.package-cont:hover {
  transform: scale(1.05); /* Scale the card on hover */
}

.package-cont:hover .package-wrapper-title {
  color: #b44d2d; /* Title color on hover */
}

.package-cont:hover .package-btn {
  background-color: #393b40; /* Button background color on hover */
  color: #fff; /* Button text color on hover */
}

.package-cont:hover .package-btn p {
  color: rgba(255, 255, 255, 0.5);
}

.modal-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  border: none;
  background-color: white;
  width: 1200px;
}

.wine-pass-popup-left-container {
  padding: 47px;
  background-color: #f2f2f2;
  width: 50%;
}

.wine-left-popup-head-container {
  display: flex;
  margin-bottom: 40px;
}

.wine-left-popup-head-container img {
  margin-right: 34px;
}

.wine-left-popup-head-container h2 {
  font-family: "Poppins";
  font-weight: 600;
  line-height: 30px;
  font-size: 20px;
  color: #3a3d43;
  margin-bottom: 14px;
}

.wine-left-popup-head-container h4 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  margin-bottom: 17px;
}
.wine-left-popup-head-container span {
  font-family: "Poppins";
  font-weight: 400;
  color: #3a3d43;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
}

.count-btns-container {
  padding: 17px 24px;
  border: 1px solid rgba(41, 44, 49, 0.24);
  border-radius: 4px;
}

.count-btns-container button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.decr-btn,
.incr-btn,
.quantity {
  font-size: 20px;
}

.quantity {
  margin: 0 27px;
  color: #24262d;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.quantity-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #24262d;
}
.quantities-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.wine-pass-left-line {
  width: 100%;
  height: 1px;
  background-color: #c0c0c0;
}

.wine-pass-left-price {
  margin-top: 30px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.wine-pass-name-and-price {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.priority-wine-passport {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #3a3d43;
}

.x-quantity {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
  color: #3a3d43;
}

.span-count {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #b44d2d;
}
.total-wine-pass-price-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
}

.each-wine-pass-price {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #3a3d43;
}
.total-price-span {
  font-family: "Montserrat";
  font-weight: bold;
  color: #3a3d43;
  font-size: 20px;
  line-height: 24px;
}

.total-price-sec-span {
  font-family: "Montserrat";
  font-weight: 600;
  color: #3a3d43;
  font-size: 20px;
  line-height: 24px;
}

.wine-pass-popup-right-container {
  padding: 47px 56px;
  width: 50%;
  position: relative;
}

.pay-close-btn {
  position: absolute;
  right: 4%;
  cursor: pointer;
  top: 4%;
}
.wine-pass-popup-right-container h2 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 30px;
}

.pay-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.pay-card-container input {
  margin-right: 8px;
}

.pay-card-container span {
  font-family: "Lato";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3a3d43;
}

.terms-wrapper {
  display: flex;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 50px;
}

.terms-wrapper input {
  margin-right: 12px;
}

.terms-wrapper p {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.8px;
  color: #222e2e;
}
.terms-wrapper span {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #b44d2d;
}

.pay-btn {
  padding: 20px 147px;
  background-color: #b44d2d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pay-popup-checkbox {
  width: 24px;
  height: 24px;
}

.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
  left: 57%;
  top: 24%;
  bottom: 0;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  animation-delay: 0s;
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  animation-delay: 0.083s;
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  animation-delay: 0.166s;
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  animation-delay: 0.249s;
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  animation-delay: 0.332s;
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  animation-delay: 0.415s;
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  animation-delay: 0.498s;
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  animation-delay: 0.581s;
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  animation-delay: 0.664s;
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  animation-delay: 0.747s;
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  animation-delay: 0.83s;
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  animation-delay: 0.913s;
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: white;
  }

  100% {
    background-color: transparent;
  }
}

.pay-check-span {
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .package-container {
    flex-direction: column;
    padding: 0 10px;
  }
  .package-cont {
    margin-right: 0;
    margin-top: 20px;
    width: 100%;
  }
  .modal-container {
    flex-direction: column;
    overflow-y: auto;
    max-height: 80vh;
    width: 100%;
    top: 60%;
  }
  .wine-pass-popup-left-container {
    width: 100%;
    padding: 27px;
  }
  .wine-pass-popup-right-container {
    width: 100%;
    padding: 27px;
  }
  .pay-close-btn {
    right: 5%;
    top: -121%;
  }
  .terms-wrapper {
    padding-left: 17px;
  }
}

.notFound-background-img {
  background: url("/static/media/traditional-winemaking-and-wine-tasting-2021-08-26-22-39-51-utc 1.21be43bf.png");
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.notFound-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.notFound-container img {
  margin-bottom: 20px;
}

.notFound-container h1 {
  color: var(--brown);
  margin-bottom: 30px;
}

.notFound-container p {
  color: var(--dark);
  margin-right: 5px;
  font-weight: 500;
}

.notFound-wrapper {
  display: flex;
  align-items: center;
}

.notFound-wrapper button {
  width: 170px;
  height: 60px;
  background-color: var(--brown);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: var(--white);
  font-weight: bold;
  font-size: 18px;
}

.notFound-wrapper a {
  text-decoration: none;
  color: var(--white);
}

.sort-tab-container {
  display: flex;
  align-items: center;
  margin-right: 56px;
}

.tab-span {
  margin-right: 23px;
  font-family: "Poppins";
  color: #201e1e;
  font-size: 18px;
  white-space: nowrap;
}

.winery-list-container {
  border: 1px solid var(--listCardBorder);
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-Container {
  justify-content: space-between;
}
.winery-img-zoom-cont {
  height: 241px;
  overflow: hidden;
}

.wineryList-img {
  object-fit: cover;
  width: 100%;
  border-radius: 4px 4px 0 0;
  -webkit-user-select: none;
          user-select: none;
  transition: all 1.1s;
  height: 100%;
}

.winery-list-container:hover .wineryList-img {
  transform: scale(1.2);
}
.winery-info-container {
  padding: 21px 30px;
}
.winery-info-container h4 {
  color: #393b40;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 20px;
}

.winery-info-container p {
  font-family: "Poppins";
  color: #515459;
  margin-top: 17px;
  margin-bottom: 33px;
}

.winery-region-and-rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.winery-rating-wrapper {
  display: flex;
  align-items: center;
}
.winery-rating-wrapper img {
  margin-left: 10px;
}

.winery-wrapper {
  display: flex;
  align-items: center;
}
.winery-wrapper span {
  color: #3a3d43;
  font-family: "Poppins";
}

.learn-more-btn {
  /* float: right; */
  margin: 20px 0;
  width: 168px;
  height: 56px;
  border: none;
  border: 1px solid #2f3238;
  color: #3a3d43;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
}

.winery-list-container-mob-tab {
  display: none;
}

@media (max-width: 1020px) {
  .winery-list-container {
    display: none;
  }
  .winery-list-container-mob-tab {
    display: block;
    /* width: 350px; */
    border: 1px solid var(--listCardBorder);
    border-radius: 4px 4px 0 0;
  }
}

.region-sort-absolute {
  position: absolute;
  top: 28px;
  left: 0;
  z-index: 1000;
  background-color: var(--white);
  padding: 47px 20px 30px 30px;
  border: 1px solid rgba(26, 26, 26, 0.16);
}

.winery-titles {
  margin-top: 120px;
}

.Wine-type-sort-absolute {
  position: absolute;
  top: 28px;
  left: 150px;
  z-index: 1000;
  background-color: var(--white);
  padding: 47px 20px 30px 30px;
  border: 1px solid rgba(26, 26, 26, 0.16);
}

.Teritories-sort-absolute {
  position: absolute;
  top: 28px;
  left: 338px;
  z-index: 1000;
  background-color: var(--white);
  padding: 47px 20px 30px 30px;
  border: 1px solid rgba(26, 26, 26, 0.16);
}
.label-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 44px;
}

.label-left-block,
.label-right-block {
  margin-right: 20px;
}

.label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.label-wrapper label {
  margin-left: 17px;
  width: 200px;
}

.label-wrapper input {
  width: 25px;
  height: 25px;
  accent-color: #707070;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}

.clear-all-btn {
  color: #3a3d43;
  border: none;
  font-family: "Poppins";
  background-color: transparent;
  cursor: pointer;
}

.btn-submit {
  width: 177px;
  height: 56px;
  background-color: #3a3d43;
  border: none;
  border-radius: 8px;
  font-family: "Montserrat";
  font-size: 18px;
  color: var(--white);
  cursor: pointer;
}

.input-sort-container {
  display: flex;
  align-items: center;
}
.winery-input {
  padding: 14px 18px;
  border: 1px solid #d6d6d6;
  border-radius: 1px;
  outline: none;
  color: red;
  width: 330px;
}

.searchs-icon {
  position: absolute;
  right: 5%;
  top: 25%;
  width: 24px;
  height: 24px;
}

.input-search-img-mob {
  display: none;
}
.winerySelect {
  -webkit-appearance: none;
  appearance: none;
  /* border: none; */
  border: 1px solid #d6d6d6;
  outline: none;
  padding-right: 15px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAJCAYAAAALpr0TAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgBhY7dCcJAEIRn9/TdChS7iAT/SCHRDixBO4idWIEeCPdsBWIkBaSC22QhecjBJfM0++3ADG22xz0JHTAmks+MwBaECVHNBCmmYvB48Nz4W2t/sYzoT+TK1tpawKdoKfzFuWdp9Kj+33K5Wi9anAxDUrj3666eexhO0MqOYRAMJxihs7LYJKS7LE/SLA95A9YFMrOIn/tZAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 10px;
  padding: 15px 20px;
  width: 204px;
}

.wineriPageSelect {
  -webkit-appearance: none;
  appearance: none;
  /* border: none; */
  border: 1px solid #d6d6d6;
  outline: none;
  padding-right: 15px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAJCAYAAAALpr0TAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgBhY7dCcJAEIRn9/TdChS7iAT/SCHRDixBO4idWIEeCPdsBWIkBaSC22QhecjBJfM0++3ADG22xz0JHTAmks+MwBaECVHNBCmmYvB48Nz4W2t/sYzoT+TK1tpawKdoKfzFuWdp9Kj+33K5Wi9anAxDUrj3666eexhO0MqOYRAMJxihs7LYJKS7LE/SLA95A9YFMrOIn/tZAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: calc(100% - 6px) center;
  background-size: 10px;
  padding: 10px 20px 10px 5px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 138px 0;
}

.pagination-prev {
  margin-right: 4px;
}

.pagination-next {
  margin-left: 4px;
}

.pagination-container a {
  padding: 22px;
  color: #201e1e;
  cursor: pointer;
}

.pagination-active {
  border-bottom: 2px solid #201e1e;
}

.pagination-disable {
  color: rgba(32, 30, 30, 0.2) !important;
}

.grsort {
  display: none;
}
.list-container-mob-tab {
  display: none;
}

.select-container {
  display: flex;
  align-items: center;
  margin-right: 45px;
}
.list-Container {
  display: grid;
}
.winery-cont {
  padding: 0 10.5%;
}

.error-message {
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  float: left;
  padding: 20px 30px;
  color: red;
}

.wineries-bg {
  width: 100%;
  height: 290px;
  background: url(/static/media/bg.b7bdb5d3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 151px;
}

.section-one-bg-mob-winery {
  display: none;
}
.wineries-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sorts-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  /* min-width: 1300px; */
}
.sort-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.right-sort-container {
  display: flex;
  align-items: center;
}

.sort-wrapper {
  display: flex;
  align-items: center;
}

.text-container-winery-title {
  font-size: 16px;
  margin-bottom: 20px;
}

.boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxes img {
  margin-right: 17px;
  cursor: pointer;
}

.list-Container {
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-gap: 52px;
}

.text-container-small-text {
  font-family: "Poppins";
  color: var(--white);
  font-size: 16px;
}

/* @media (max-width: 1080px) {
  .sorts-cont {
    min-width: 0;
  }
} */

/* loader */
.loader {
  --dim: 3rem;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
}

.loarder-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
}

.loader .circle {
  --color: #333;
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%,
  25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%,
  50% {
    transform: scale(1) rotate(180deg);
  }

  70%,
  75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.sorts-cont .TextField__label {
  color: black;
}
.sorts-cont .MuiInputBase-input,
.sorts-cont .MuiButtonBase-root {
  color: black;
}

.winery-input-width {
  width: 333px;
  position: relative;
  margin-right: 41px;
}
.sorts-cont .css-1h51icj-MuiAutocomplete-root {
  width: 330px;
}
@media (max-width: 1024px) {
  .grsort {
    display: block;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #201e1e;
  }
  .sort-container {
    display: none;
  }
  .boxes {
    display: none;
  }
  .list-Container {
    display: none;
  }
  .list-container-mob-tab {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 52px;
  }
  .select-container {
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
    justify-content: space-between;
  }
  .input-sort-container {
    display: none;
  }
  .input-search-img-mob {
    display: block;
    margin-right: 0;
    position: relative;
    margin-bottom: 21px;
  }
  .winery-input-mob {
    width: 100%;
    padding: 14px 18px;
    border: 1px solid #d6d6d6;
    border-radius: 1px;
    outline: none;
  }

  .sort-containers {
    position: absolute;
    z-index: 1000;
    background-color: var(--white);
    border: 1px solid rgba(26, 26, 26, 0.16);
  }
  .label-options {
    flex-direction: column;
  }

  .Wine-type-sort-absolute {
    top: 55px;
    left: -3px;
  }

  .Teritories-sort-absolute {
    top: 82px;
    left: 0;
  }
  .sorts-cont {
    min-width: 752px;
  }
  .winery-cont {
    padding: 45px 3%;
  }
}

@media (max-width: 640px) {
  .list-container-mob-tab {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 52px;
  }
  .sorts-cont {
    min-width: 350px;
  }
  .input-sort-container {
    display: none;
  }
  .text-container {
    text-align: center;
  }
  .text-container-winery-title {
    margin-bottom: 0;
  }
  .wineries-bg {
    display: none;
  }
  .section-one-bg-mob-winery {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/winery-bg-img-mob.bcd6cfd4.png);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .text-container h2 {
    font-size: 28px;
  }
  .input-search-img-mob {
    display: block;
    margin-right: 0;
    position: relative;
    margin-bottom: 21px;
  }
  .winery-input-mob {
    width: 100%;
    padding: 14px 18px;
    border: 1px solid #d6d6d6;
    border-radius: 1px;
    outline: none;
  }
  .winery-titles {
    display: none;
  }
  .sorts-cont .css-1h51icj-MuiAutocomplete-root {
    width: 100%;
  }
}

.cart-item-wrapper {
  padding: 10px;
  display: flex;
  margin-top: 5px;
}

.cart-item-image {
  width: 65px;
  height: 80px;
  object-fit: contain;
  -webkit-user-select: none;
          user-select: none;
}

.cart-item-details {
  margin-left: 10px;
  width: 100%;
  /* background-color: chartreuse; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-item-characteristics {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.characteristics-name {
  color: #102a42;
  font-weight: 500;
}

.characteristics-price {
  color: gray;
}

.characteristics-remove-btn {
  font-size: 14px;
  border: none;
  color: #236eb5;
  background-color: transparent;
  cursor: pointer;
}

.cart-item-count {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart-item-counter-arrow {
  font-size: 20px;
  color: #236eb5;
  cursor: pointer;
}

.cart-container {
  margin-top: 60px;
  /* background-color: lightblue; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-items-container {
  width: 100%;
  /* background-color: lightcoral; */
  margin-top: 30px;
}

.line {
  width: 100%;
  border: 1px solid #102a42;
}

.main-container-cart {
  min-width: 800px;
  margin: 20px 0;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-cost {
  width: 100%;
  color: #102a42;
  font-weight: 500;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.clear-btn {
  cursor: pointer;
  color: white;
  background-color: #c75758;
  border-radius: 5px;
  font-weight: 700;
  padding: 5px 10px;
  margin-top: 5px;
  border: 1px solid #c75758;
  transition: all 0.5s ease-in;
}

.clear-btn:hover {
  color: #c75758;
  background-color: white;
}
.cart-fav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .main-container-cart {
    min-width: 350px;
  }
}

@media (max-width: 640px) {
  .heart,
  .favorite-img {
    filter: brightness(0) saturate(100%);
    width: 26px;
    height: 26px;
    margin-top: 25px;
  }

  .heart {
    margin-right: 26px;
  }
}

.favorite-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 60px;
}

.clear-Favorite-Btn {
  width: 125px;
  height: 50px;
  border: none;
  background-color: var(--brown);
  color: white;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article-section-forOverflow {
  overflow-x: hidden;
}

.article-bg {
  width: 100%;
  height: 290px;
  background: url(/static/media/bg.5131b558.png);
  background-repeat: no-repeat;
  margin-bottom: 130px;
  background-size: cover;
  background-position: center;
}

.article-card-title {
  color: #de6d4a;
  opacity: 0.6;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 23px;
  padding-top: 20px;
}
/* .section-one-bg-mob-articles {
  display: none;
} */
.bg-mob-article {
  display: none;
}
.articlePage-title {
  text-align: center;
  margin-bottom: 19.4px;
  color: #3a3d43;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 32px;
}

.article-list-container {
  display: flex;
  align-items: center;
  padding: 0 10.5%;
}
.main-article-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
  text-decoration: none;
}

.main-article-date {
  color: #de6d4a;
  opacity: 0.6;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 23px;
}

.main-article-title {
  font-family: "Montserrat";
  font-weight: bold;
  color: #3a3d43;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;
}

.main-article-desc {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Poppins";
  font-weight: 400;
  line-height: 28px;
  font-size: 18px;
  margin-bottom: 80px;
}

.author-container,
.author-cont {
  display: flex;
  align-items: center;
}

.author-cont {
  margin-top: auto;
}
.author-container img {
  width: 50px;
  height: 50px;
  margin-right: 16px;
  -webkit-user-select: none;
          user-select: none;
}

.author-desc-container {
  display: flex;
  flex-direction: column;
}

.author-name {
  color: rgba(58, 61, 67, 1);
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.author-position {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  opacity: 0.6;
  color: #3a3d43;
}

.article-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 42px;
}

.home-article-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 42px;
  padding: 0 10.5%;
}

.article-wrapper-img {
  object-fit: cover;
  width: 100%;
  border-radius: 4px 4px 0 0;
  -webkit-user-select: none;
          user-select: none;
  transition: all 1.1s;
  user-select: none;
  height: 100%;
}

.article-wrapper {
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: space-between;
}

.article-img-zoom-cont {
  width: 100%;
  height: 213px;
  overflow: hidden;
  margin-bottom: 21px;
}
.article-wrapper:hover .article-wrapper-img {
  transform: scale(1.2);
}

.article-wrapper h4 {
  color: #3a3d43;
  font-size: 24px;
  font-weight: bold;
  font-family: "Montserrat";
  line-height: 38px;
  margin-bottom: 10px;
  margin-top: 11px;
}

.article-wrapper p {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Poppins";
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 20px;
}

.author-cont img {
  height: 50px;
  margin-right: 20px;
  border-radius: 50%;
}

.author-wrap {
  display: flex;
  flex-direction: column;
}

.article-author {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 28px;
  color: #3a3d43;
  font-weight: 400;
}

.article-position {
  font-family: "Patrick Hand";
  font-weight: 400;
  color: #3a3d43;
  font-size: 14px;
  line-height: 28px;
  opacity: 0.6;
}

.articlePage-width {
  width: 100%;
}
@media (max-width: 640px) {
  .bg-mob-article {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/mobile-bg.38c373a3.png);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .article-bg {
    display: none;
  }
}
@media (max-width: 1240px) {
  .mob-article-padding {
    padding: 0 10px;
  }
  .article-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .home-article-list {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 20px;
  }
  .articlePage-title {
    margin-top: 20px;
    font-size: 20px;
  }
  .article-list-container {
    justify-content: center;
    padding: 0;
  }
  .article-wrapper {
    padding-bottom: 10px;
  }
  .article-bg {
    display: none;
  }
  .articlePage-width {
    width: 90%;
  }
  .article-home-page-width {
    width: 100%;
  }
  .section-one-bg-mob-articles {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/mobile-bg.38c373a3.png);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
}

@media (max-width: 640px) {
  .section-one-bg-mob-articles {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/mobile-bg.38c373a3.png);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
}

.article-detail-title {
  text-align: center;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 32px;
  color: #3a3d43;
  line-height: 39px;
  padding: 0 300px;
  margin-bottom: 20px;
}

.article-detail-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 56px;
  padding: 0 10.5%;
}

.article-detail-container-p {
  column-count: 2;
  grid-column-gap: 20px;
  column-gap: 20px;
  margin-bottom: 56px;
}

.article-detail-container-p {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Poppins";
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.article-detail-btn {
  background-color: transparent;
  border: none;
  font-family: "Inter";
  margin-top: 14px;
  font-size: 14px;
  color: #3a3d43;
  cursor: pointer;
}

.detail-author-cont {
  display: flex;
  align-items: center;
  margin-bottom: 180px;
  margin-top: 56px;
}

.detail-author-cont img {
  margin-right: 16px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.detail-author-name {
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #3a3d43;
}

.detail-author-position {
  font-family: "Patrick Hand";
  color: #3a3d43;
  opacity: 0.6;
  font-size: 14px;
  line-height: 28px;
}

.article-detail-similar-article-title {
  text-align: center;
  margin-bottom: 17px;
}

.articles-detail-width {
  width: 100%;
}

@media (max-width: 1240px) {
  .article-detail-title {
    padding: 0 20px;
    font-size: 18px;
  }
  .article-detail-container-p {
    column-count: 1;
  }
  .article-detail-container {
    justify-content: center;
    padding: 0;
  }
  .articles-detail-width {
    width: 90%;
  }
}

.event-bg {
  width: 100vw;
  height: 290px;
  background: url(/static/media/mainBg.a7213c8f.png);
  background-repeat: no-repeat;
  margin-bottom: 130px;
  background-size: cover;
  background-position: center;
}

.section-one-bg-mob-events {
  display: none;
}

@media (max-width: 640px) {
  .section-one-bg-mob-events {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/mobBG.6d8c8184.jpg);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .event-bg {
    display: none;
  }
}

.event-main {
  overflow-x: hidden;
}
.event-main-title {
  font-family: "Montserrat";
  font-size: 32px;
  line-height: 39px;
  color: #3a3d43;
  text-align: center;
  margin-top: 130px;
  margin-bottom: 19px;
  text-transform: uppercase;
}

.no-more-event-p {
  text-align: center;
  color: #e79a74;
  font-size: 24px;
  font-family: "Poppins";
  margin-bottom: 132px;
}

.event-section-container {
  display: flex;
  /* justify-content: center; */
  padding: 0 10.5%;
}

.event-info-container {
  width: 100%;
}

.event-sort-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.event-cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.event-card-container {
  width: 79%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  margin-bottom: 32px;
  overflow-x: hidden;
}

.img-and-date-wrapper {
  position: relative;
  margin-right: 88px;
  width: 100%;
  height: 300px;
}

.date-wrapper {
  position: absolute;
  top: 20%;
  left: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 210px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: var(--white);
}

.date-wrapper-mob {
  position: absolute;
  bottom: -10%;
  left: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: var(--white);
}

.event-day {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 64px;
  line-height: 96px;
  color: #b44d2d;
}

.event-day-mob {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 30px;
  color: #b44d2d;
}
.event-month {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #b44d2d;
}

.event-month-mob {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 18px;
  color: #b44d2d;
}

.event-year {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.3);
}

.event-year-mob {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

.event-title {
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #393b40;
  margin-bottom: 9px;
}

.event-company {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #b44d2d;
  margin-bottom: 14px;
}

.event-desc {
  font-family: "Poppins";
  font-family: 400;
  font-size: 16px;
  line-height: 24px;
  color: #515459;
  margin-bottom: 25px;
  padding-right: 35%;
}

.event-duration {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #515459;
}

.event-location-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.event-location-wrapper img {
  margin-right: 11px;
}

.event-location-wrapper span {
  font-family: "Poppins";
  color: #727477;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.loc-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  margin-top: 25px;
}

.loc-btn-wrapper button {
  width: 169px;
  height: 58px;
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 22px;
  color: #727272;
  border: 1px solid #969696;
  border-radius: 5px;
  cursor: pointer;
}

.eventcard-img {
  width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
}

@media (max-width: 1020px) {
  .event-card-container {
    flex-direction: column;
    width: 90vw;
  }
  .img-and-date-wrapper {
    margin-right: 0;
    height: 150px;
  }
  .eventcard-img {
    width: 90vw;
  }
  .event-desc {
    padding-right: 0;
  }
  .event-info-container {
    padding: 20px;
  }
  .loc-btn-wrapper {
    margin-top: 20px;
    padding-right: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .event-section-container {
    padding: 0 20px;
  }

  .loc-btn-wrapper button {
    width: 170px;
    height: 58px;
  }
  .event-location-wrapper {
    margin-top: 0;
    margin-bottom: 31px;
  }
  .event-sort-container {
    flex-direction: column;
    align-items: baseline;
  }
}

.eventDetail-bg {
  width: 100%;
  height: 290px;
  background: url(/static/media/mainBg.a7213c8f.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
}
.event-duration {
  cursor: pointer;
}

.text-container-event {
  width: 30%;
  height: 100%;
  background-color: #24262c;
  opacity: 0.8;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.event-subTitle {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 24px;
  line-height: 32ox;
  color: #b44d2d;
  margin-top: 32px;
}

.eventDetail-section {
  padding: 0 10.5%;
}

.date-wrapper-DetailPage {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 210px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: var(--white);
  margin-top: -40px;
}

.event-firstsection {
  display: flex;
  align-items: flex-end;
  margin-bottom: 90px;
}

.clock-and-loc-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
}

.clock-wrapper {
  display: flex;
  align-items: center;
}

.clock-wrapper img {
  margin-right: 15px;
}

.detailInfo-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}
.detailInfo-wrapper h4,
.participantsList {
  font-family: "Montserrat";
  color: #3a3d43;
  font-weight: bold;
  line-height: 39px;
  margin-bottom: 20px;
}

.detailInfo-wrapper p {
  font-family: "Lato";
  color: #3a3d43;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.participant-container {
  column-count: 5;
  margin-bottom: 36px;
}

.participant-company-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.participant-company {
  margin-left: 12px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
}

.participant-see-allBtn {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  font-family: "Poppins";
  font-family: 600;
  font-size: 16px;
  line-height: 30px;
  color: #3a3d43;
}

.participants-wrapper {
  margin-bottom: 60px;
}

.event-location-container {
  margin-bottom: 171px;
}

.clockIcon {
  margin-left: 31px;
}
.event-location-container h4 {
  margin-bottom: 26px;
}

@media (max-width: 1020px) {
  .section-one-bg-mob-events {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/mobBG.6d8c8184.jpg);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .eventDetail-bg {
    display: none;
  }
  .participant-container {
    column-count: 2;
  }
  .event-firstsection {
    align-items: center;
    flex-direction: column;
  }
  .date-wrapper-DetailPage {
    margin-top: 0;
    flex-direction: row;
  }
  .clock-and-loc-container {
    margin-top: 20px;
    align-items: baseline;
  }
  .clock-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .clockIcon {
    margin-left: 0;
  }
}

@media (max-width: 640px) {
  .participant-container {
    column-count: 1;
  }
  .eventDetail-section {
    padding: 0 20px;
  }
}

.product-main-container {
  overflow-x: hidden;
}

.product-first-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 171px;
}

.product {
  width: 35%;
  display: flex;
  padding-left: 10.5%;
  max-height: 60vh;
}

.product-page-product-image {
  max-width: 20vw;
  object-fit: contain;
}

.product-rigth-block {
  width: 65%;
  background-color: #f2f2f2;
  min-height: calc(100vh - 100px);
  padding: 80px 122px;
}

.product-title {
  margin-bottom: 48px;
  font-family: "Montserrat";
  color: #3a3d43;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  display: inline-block;
  margin-bottom: 25px;
}

.title-product-line {
  display: flex;
  border: 0.2px solid rgba(222, 222, 222, 1);
  width: 60%;
  height: 1px;
}

.title-product-line2 {
  border: 0.2px solid #de6d4a;
}
.line-product {
  display: flex;
}

.product-desk {
  color: #b44d2d;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 41px;
}
.wines-icons-wrapper img {
  margin-right: 10px;
}

.prod-desk-icons-container {
  display: flex;
  margin-top: 21px;
  margin-bottom: 23px;
}

@media (max-width: 640px) {
  .title-line {
    width: 223px;
  }
  .title-line2 {
    width: 128px;
  }
}

.product-info-container {
  max-width: 385px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.product-info-container:nth-child(:last-child) {
  margin-bottom: 0;
}

.product-info-container span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
}

.product-info-container h5 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
}

.product-value {
  width: 142px;
  display: flex;
  align-items: flex-start;
}

.productpage-description {
  margin-top: 84px;
  border-left: 9px solid #b54d2e;
  max-width: 630px;
  padding: 0 17px;
}

.productpage-description p {
  margin-bottom: 23px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #3a3d43;
  line-height: 24px;
}

.productpage-description button {
  text-decoration: underline;
  background-color: transparent;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.8);
  line-height: 17px;
  border: none;
  cursor: pointer;
}

.second-section-first-title {
  text-align: center;
  margin-bottom: 14px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #24262d;
}

.product-second-section {
  padding-bottom: 226px;
}

@media (max-width: 640px) {
  .product-first-section {
    background-color: #f2f2f2;
    flex-direction: column;
    padding: 30px 0;
  }
  .product {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .product img {
    width: 100px;
  }
  .product-rigth-block {
    width: 100%;
    padding: 0 20px;
    min-height: 0;
  }
  .product-title {
    font-size: 24px;
    text-align: center;
  }
  .product {
    padding-left: 0;
  }
}

.profile-page-main-titles {
  padding-top: 159px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-page {
  background-color: #f2f2f2;
  padding: 0 10.5%;
  padding-bottom: 235px;
}

.profile-title {
  padding: 0 65px 21px 65px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  cursor: pointer;
}

.wine-pass-title {
  padding: 0 65px 21px 65px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  cursor: pointer;
}

.profile-page-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 70px;
}

.personal-info-container {
  padding: 34px 41px 55px 56px;
  width: 48%;
  background-color: var(--white);
  border-radius: 8px;
}

.bonus-point-container {
  width: 48%;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
}

.change-pass-container {
  width: 48%;
  background-color: var(--white);
  border-radius: 8px;
  padding: 35px 96px 32px 58px;
  display: flex;
  flex-direction: column;
}
.change-pass-container {
  margin-top: 30px;
}

.personal-info-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.pers-info-wrapper {
  display: flex;
  align-items: center;
}

.pers-info-wrapper img {
  margin-right: 11px;
}
.pers-info-wrapper h5 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
}

.personal-info-prop-value {
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.personal-info-prop-container {
  display: flex;
  flex-direction: column;
  margin-right: 125px;
}

.personal-info-value-container {
  display: flex;
  flex-direction: column;
}

.personal-info-prop-container span {
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  font-weight: 400;
}

.personal-info-value-container span {
  margin-bottom: 20px;
  font-family: "Poppins";
  color: #3a3d43;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.wine-pass-access {
  font-family: "Poppins";
  color: #3a3d43;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.prof-info-prop {
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  font-weight: 400;
}

.wine-info-props {
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  font-weight: 400;
  margin-right: 179px;
  white-space: nowrap;
}
.prof-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prof-info-value {
  display: flex;
  align-items: flex-start;
  width: 300px;
  margin-bottom: 20px;
  font-family: "Poppins";
  color: #3a3d43;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.bonus-container {
  padding: 34px 55px 0 0;
  width: 100%;
}

.bonus-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 29px;
}

.bonus-wrap img {
  margin-right: 12px;
}

.bonus-wrap span {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
}

.bonus-text {
  display: flex;
  align-items: baseline;
  margin-bottom: 14px;
}
.bonus-text p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: rgba(41, 44, 49, 0.8);
}

.bonus-img {
  -webkit-user-select: none;
          user-select: none;
}
.bonus-text span {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #b44d2d;
  margin-left: 16px;
  margin-right: 10px;
}

.bonus-text h4 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
  color: #3a3d43;
  font-size: 15px;
  line-height: 27px;
}

.useBonusPoints {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #b44d2d;
}

.change-pass-container span {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
  margin-bottom: 9px;
}

.change-pass-container p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(41, 44, 49, 0.8);
  margin-bottom: 10px;
}

.change-pass-container input {
  border: 1px solid rgba(41, 44, 49, 0.4);
  color: rgba(41, 44, 49, 0.4);
  outline: none;
  height: 60px;
  padding: 19px 24px;
  border-radius: 4px;
  margin-bottom: 9px;
}

.edit-info-data {
  border: 1px solid rgba(41, 44, 49, 0.4);
  color: rgba(41, 44, 49, 1);
  outline: none;
  height: 30px;
  padding: 19px 10px;
  border-radius: 4px;
  margin-bottom: 9px;
}

.change-pass-container button,
.edit-btn {
  width: 170px;
  height: 62px;
  border-radius: 4px;
  background-color: #b44d2d;
  color: var(--white);
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: none;
  cursor: pointer;
}

.delete-account-container {
  margin-top: 44px;
}

.delete-account-container h5 {
  color: #3a3d43;
  font-family: "Lato";
  font-size: 16px;
  line-height: 19.2px;
  margin-bottom: 11px;
  font-weight: 600;
}

.delete-account-container p {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(41, 44, 49, 0.8);
}

.delete-account-container span {
  color: #b44d2d;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.delete-account-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  border: none;
  background-color: white;
  padding: 100px 85px 64px;
}

.delete-account-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.delete-acc-closeBtn {
  position: absolute;
  top: -31%;
  right: -15%;
  cursor: pointer;
}
.delete-account-modal-container h4 {
  color: #3a3d43;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 17px;
}
.delete-account-modal-container p {
  font-family: "Lato";
  font-weight: 400;
  color: #222e2e;
  line-height: 25px;
  font-size: 16px;
  text-align: center;
  width: 350px;
  margin-bottom: 45px;
}

.delete-account-btns button {
  padding: 20px 29px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-acc-deleteBtn {
  background-color: #b44d2d;
  font-family: "Montserrat";
  margin-right: 26px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
}

.delete-acc-cancelBtn {
  color: #272a31;
  background-color: #f2f2f2;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
}
/* wine pass */

.profile-wine-pass-left-block {
  width: 80%;
}

.profile-wine-pass-rigth-block {
  width: 20%;
  margin-left: 4.5%;
}

.wine-pass-detail-info {
  display: flex;
  flex-direction: column;
}

.profile-wine-pass-main-container {
  margin-top: 57px;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
}

.profile-wine-pass-container {
  padding: 43px 92px 46px 37px;
  background-color: var(--white);
  display: flex;
  /* align-items: baseline; */
  margin-bottom: 30px;
  border-radius: 8px;
}

.current-wine-pass-title {
  padding-left: 40px;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #3a3d43;
  margin-bottom: 21px;
}

.winepass-profile-info {
  padding-left: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.winepass-owner-info {
  display: flex;
  align-items: center;
}

.winepass-img {
  margin-right: 5%;
}

.qr-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  margin-bottom: 20px;
}

.qr-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 40px 0;
}

/*
Winery styles
*/

.visitor-point-container {
  padding: 33px;
  width: 48%;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.visitor-line {
  width: 50%;
  height: 1px;
  background-color: rgba(41, 44, 49, 0.2);
  margin-bottom: 25px;
}

.visitorIcon-Text {
  display: flex;
  margin-bottom: 21px;
  align-items: center;
}

.visitorIcon-Text img {
  margin-right: 7px;
}

.visitorIcon-Text span {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
}

.visitor-this-year {
  font-family: "Lato";
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 49, 0.8);
}
.visitor-this-year span {
  color: #b44d2d;
  font-size: 40px;
  line-height: 48px;
  margin: 0 8px;
}

.total-visitors-span {
  margin-top: 30px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(41, 44, 49, 0.8);
}

.scan-qr-code-text {
  margin-bottom: 21px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
}

.qr-text-for-app {
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 49, 0.7);
}

.winery-profile-wine-pass-main-container {
  margin-top: 57px;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 70vh;
  padding: 22px 37px;
  border-radius: 8px;
}
.winery-visitors-headers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  border: none;
  font-family: "Montserrat";
}

td {
  text-align: left;
  padding: 19px;
  border: none;
  font-family: "Poppins";
  font-size: 16px;
  color: #3a3d43;
  line-height: 24px;
  font-weight: 600;
}

th {
  text-align: left;
  padding: 19px;
  color: #3a3d43;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
}

@media (max-width: 1024px) {
  .profile-title {
    font-size: 24px;
  }
  .wine-pass-title {
    font-size: 24px;
  }
  .profile-page-container {
    flex-direction: column;
  }
  .personal-info-container {
    width: 100%;
    margin-bottom: 30px;
  }
  .bonus-point-container {
    width: 100%;
  }
  .change-pass-container {
    width: 100%;
  }

  /* wine pass  */

  .profile-wine-pass-left-block {
    width: 100%;
  }
  .profile-wine-pass-rigth-block {
    width: 100%;
    margin-left: 0;
  }
  .profile-wine-pass-container {
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  .current-wine-pass-title {
    text-align: center;
    padding-left: 0;
  }
  .winepass-img {
    margin-right: 0;
    width: 100%;
  }
  .winepass-profile-info {
    padding-left: 0;
  }
  .qr-title {
    text-align: center;
    margin-top: 20px;
  }
  .wine-info-props {
    margin-right: 15px;
  }
  .wine-pass-detail-info {
    width: 100%;
    justify-content: space-around;
    margin: 20px 0;
  }
  .profile-page {
    padding: 0 5%;
  }
  .prof-info-wrapper {
    padding-left: 20px;
  }
}

@media (max-width: 640px) {
  .profile-page-main-titles {
    padding-top: 30px;
  }
  .profile-title {
    font-size: 18px;
    padding: 0;
  }
  .wine-pass-title {
    font-size: 18px;
    padding: 0;
    padding-left: 20px;
  }
  .pers-info-wrapper h5 {
    font-size: 21px;
  }
  .prof-info-wrapper {
    flex-direction: column;
  }
  .prof-info-value {
    width: auto;
    display: block;
  }
  .personal-info-container {
    padding: 20px;
  }
  .bonus-point-container {
    flex-direction: column;
  }
  .bonus-img {
    width: 100%;
  }
  .bonus-container {
    padding: 20px;
  }
  .change-pass-container {
    padding: 20px;
  }
  .profile-page {
    padding: 0 4%;
    padding-bottom: 30px;
  }
  .winepass-profile-info {
    flex-direction: column;
  }
  .delete-account-modal-container {
    padding: 68px 22px 54px;
  }
  .delete-acc-closeBtn {
    top: -21%;
    right: 1%;
  }
}

.catalog-bg {
  width: 100%;
  height: 290px;
  background: url("/static/media/Path 10372.8cc0fcb6.png");
  background-repeat: no-repeat;
  margin-bottom: 130px;
  background-size: cover;
  background-position: center;
}

.catalog-main-title {
  text-align: center;
  font-family: "Montserrat";
  font-size: 28px;
  line-height: 34px;
  color: #24262d;
  margin-bottom: 14px;
}

.section-one-bg-mob-catalog {
  display: none;
}
.catalog-filter-cont-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-top: 19px;
}

.catalog-filter-container {
  width: 19%;
  margin-right: 44px;
}

.catalog-section {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 10.5%;
}

.catalog-wines {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  /* justify-content: space-between; */
}

.filt-title {
  font-family: "Lato";
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 29px;
}

.title-img-cont {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.title-img-cont h5 {
  font-family: "Lato";
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 15px;
}

.section-one-bg-mob-articles {
  display: none;
}
.filter-option-cont {
  display: flex;
  flex-direction: column;
}

.filter-option-cont span {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  cursor: pointer;
}

.title-img-cont h5 {
  font-family: "Lato";
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 15px;
}

.label-column {
  display: flex;
  flex-direction: column;
}

.clicked-span {
  color: var(--brown) !important;
}
.input-options {
  display: flex;
  align-items: center;
}

.input-options input {
  margin-right: 10px;
}

.input-options label {
  font-family: "Lato";
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
}
.filter-for-mobTab {
  display: none;
}
.filtermobBtn {
  display: none;
}

@media (max-width: 1020px) {
  .catalog-filter-container {
    display: none;
  }
  .filter-for-mobTab {
    display: block;
    font-size: 40px;
    margin-right: 10px;
  }
  .catalog-section {
    flex-direction: column;
  }
  .catalog-filter-containers {
    margin-top: 20px;
  }
  .catalog-bg {
    display: none;
  }
  .filtermobBtn {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #e5e5ea;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3a3a3c;
    margin-bottom: 30px;
  }
  .section-one-bg-mob-catalog {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/wine-bottles-and-grapes-2021-08-26-15-48-05-utc.9aa0e572.jpg);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
}

.about-main {
  overflow-x: hidden;
}

.aboutUs-bg {
  width: 100%;
  height: 290px;
  background: url("/static/media/Path 10372.5231e8ef.png");
  background-repeat: no-repeat;
  margin-bottom: 130px;
  background-size: cover;
  background-position: center;
}

.section-one-bg-mob-about-us {
  display: none;
}

.about-us-container {
  display: flex;
  padding: 0 10.5%;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 190px;
}

.about-us-container img {
  width: 48%;
  border-radius: 4px;
  /* height: 389px; */
}
.about-us-container div {
  width: 48%;
}

.about-us-container p {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Lato";
  font-size: 18px;
  line-height: 28px;
}

.about-us-new-container {
  padding: 0 10.5%;
}

.about-us-new-container p {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Lato";
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
}
.about-us-container p:nth-child(1) {
  margin-bottom: 24px;
}
@media (max-width: 640px) {
  .section-one-bg-mob-about-us {
    display: flex;
    align-items: flex-end;
    background: url(/static/media/aboutMob.e5bed20c.png);
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .aboutUs-bg {
    display: none;
  }
  .about-us-container {
    padding: 0 16px;
    flex-direction: column;
    margin-bottom: 90px;
  }
  .about-us-container img,
  .about-us-container div {
    width: 100%;
  }
  .about-us-container img {
    margin-bottom: 18px;
  }
  .event-main-title {
    margin-top: 0;
  }
  .event-main-title {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

.mobileApp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
}

.mob-app-logo {
  margin-bottom: 129px;
}

.mobileApp-container h1 {
  padding-bottom: 36px;
  border-bottom: 1px solid #b54d2e;
  color: #b54d2e;
  font-size: 48px;
  line-height: 58px;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 70px;
}
.mobileApp-container p {
  font-family: "Montserrat";
  font-size: 32px;
  line-height: 39px;
  color: #333333;
  margin-bottom: 47px;
}

.wine-pass-detail-main {
  background-color: #f2f2f2;
  overflow-x: hidden;
  padding: 120px 10.5%;
}

.wine-pass-detail-main-title {
  text-align: center;
  padding: 20px;
  font-size: 28px;
  color: rgba(58, 61, 67, 1);
}

.winePassDetail-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 50px 80px 120px 80px;
}

.winePassInfo-container {
  display: flex;
  flex-direction: column;
  margin: 86px 0 60px 0;
}

.winePassInfo-container:nth-child(1) {
  margin: 0 0 60px 0;
}
.winePassInfo-container:nth-last-child(1) {
  margin: 86px 0 0 0;
}
.winePassInfo-container h2 {
  color: #000000;
  font-family: "Montserrat";
  font-size: 32px;
  line-height: 39px;
  padding-bottom: 23px;
  border-bottom: 0.25px solid rgba(51, 51, 51, 0.7);
}
.passInfo-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.winepass-image {
  margin-left: 127px;
}
.winePassInfo-container h4 {
  font-family: "Montserrat";
  color: #b54d2e;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 16px;
}

.pass-info-wrap {
  margin-top: 25px;
  min-height: 150px;
  border-right: 0.25px solid rgba(51, 51, 51, 0.6);
  padding-left: 50px;
  padding-right: 2%;
  width: 100%;
}

.show-email-phone-btn {
  background-color: #393b40;
  width: 50px;
  height: 50px;
  color: white;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 10px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.show-passInfo-mob-email {
  display: flex;
  align-items: center;
}

.winePassDetail-btn {
  background-color: #393b40;
  width: 358px;
  height: 80px;
  color: white;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  border: none;
  cursor: pointer;
}

.pass-info-wrap:nth-last-child(1) {
  padding-right: 0;
}
.pass-info-wrap:nth-child(1) {
  padding-left: 0;
}

.pass-info-wrap:nth-child(4) {
  border-right: none;
}

.passInfo-icons-wrap {
  display: flex;
  margin-bottom: 20px;
}
.phoneIcon-Phone {
  display: flex;
  width: 200px;
}

.passInfo-icons-wrapShowPass {
  display: flex;
  /* align-items: baseline; */
  width: 250px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.passInfo-icons-wrap img,
.passInfo-icons-wrapShowPass img {
  margin-right: 11px;
  cursor: pointer;
}

.show-text {
  position: absolute;
  top: -20px;
  left: -3px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: #b44d2d;
}

.winePassInfo-container p {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 19.5px;
  color: #333333;
}

@media (max-width: 1024px) {
  .wine-pass-detail-main {
    padding: 50px 20px;
  }
  .winePassDetail-container {
    padding: 20px;
  }
  .winePassInfo-container h2 {
    font-size: 24px;
  }
  .winePassInfo-container {
    border: 0.25px solid rgba(51, 51, 51, 0.6);
    padding: 20px;
    border-radius: 4px;
  }

  .winepass-image {
    margin-left: 0;
    width: 100%;
  }
  .passInfo-wrapper {
    flex-wrap: wrap;
  }
  .pass-info-wrap {
    border-right: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.faq-main-title {
  text-align: center;
  padding-top: 167px;
  margin-bottom: 23px;
}

.faqs-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10.5% 160px 10.5%;
}

.faq-img-quest {
  display: flex;
  align-items: baseline;
}
.faq-img-quest img {
  margin-right: 10px;
}

.faq-img-quest p {
  font-family: "Lato";
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1c1c1e;
}

.faq-answ {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(58, 61, 67, 0.6);
}
.faq-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 23px 21px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  margin-right: 41px;
}

.faq-img-question-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
}

@media (max-width: 1024px) {
  .faq-container {
    width: 100%;
    margin-right: 0;
  }
  .faq-main-title {
    padding-top: 45px;
  }
  .faqs-container {
    padding: 0 10px 160px 10px;
  }
}

.verification-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 65px;
  flex-direction: column;
}

.verification-container h5 {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #3a3d43;
  margin: 30px 0;
}

.verification-message {
  font-family: "Lato";
  color: #b44d2d;
  font-weight: 500;
  font-size: 24px;
}

.verification-button {
  width: 176px;
  height: 60px;
  margin-top: 30px;
  border: none;
  background-color: #b44d2d;
  color: var(--white);
  border-radius: 4px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 27px;
  cursor: pointer;
}

.successPay-main-container,
.failedPay-main-container {
  height: calc(100vh - 100px);
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.successPay-container,
.failedPay-container {
  background-color: white;
  padding: 6% 19.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.successPay-container img {
  margin-bottom: 16px;
}
.failedPay-container img {
  margin-bottom: 30px;
}

.successPay-container h2 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #1e923e;
  margin-bottom: 30px;
}
.failedPay-container h2 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #b44d2d;
  margin-bottom: 30px;
}

.successPay-container p {
  font-family: "Lato";
  color: #1c1c1e;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 49px;
}

.failed-p {
  font-family: "Lato";
  color: #1c1c1e;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.failed-sec-p {
  font-family: "Lato";
  color: #1c1c1e;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  max-width: 450px;
  margin-bottom: 49px;
}

.successPay-container button,
.failedPay-container button {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 19.5px;
  color: #ffffff;
  padding: 20px 48px;
  background-color: #b44d2d;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
}

.term-title {
  padding: 120px 0;
  font-family: "Montserrat";
  font-weight: bold;
  color: #3a3c42;
  font-size: 36px;
  line-height: 44px;
}

.title-cont {
  background-color: #f1f1f3;
  padding: 0 10.5%;
}

.terms-main-container {
  padding: 116px 10.5%;
}

.terms-main-container a {
  font-family: "Lato";
  font-weight: 900;
  color: #3a3a3c;
  font-size: 16px;
  line-height: 64px;
  text-decoration: underline;
}

.terms-main-container p {
  font-family: "Lato";
  font-weight: 500;
  color: #3a3a3c;
  font-size: 16px;
  line-height: 64px;
}

.terms-main-container h4 {
  text-align: center;
}

@media (max-width: 1024px) {
  .title-cont {
    padding: 0 4%;
  }
  .term-title {
    padding: 31px 0;
  }
  .terms-main-container {
    padding: 15px 4%;
  }
  .terms-main-container p {
    margin-bottom: 20px;
  }
  .terms-main-container p,
  .terms-main-container a {
    font-size: 14px;
    line-height: 26px;
  }
}

