.star-input {
  display: none;
}
.stars {
  cursor: pointer;
}

.star-container {
  display: flex;
}

.showreview-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 75px 64px 66px 65px;
  border-radius: 4px;
  z-index: 1000000;
  border: 1px solid rgba(41, 44, 49, 0.4);
}

.star-cont {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.stars-rating-cont {
  display: flex;
  align-items: center;
}

.stars-rating-cont div {
  margin-right: 20px;
}

.stars-rating-cont p {
  color: #3a3d43;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.review-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #3a3d43;
}

.review-label input {
  margin-top: 5px;
  height: 60px;
  width: 589px;
  border-radius: 10px;
  border: 1px solid rgba(41, 44, 49, 0.4);
  outline: none;
  padding-left: 16px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 49, 0.5);
}

.close-Btn {
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
  font-size: 24px;
  color: #222e2e;
}
.review-texarea-label {
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #3a3d43;
}

.review-texarea-label textarea {
  height: 179px;
  resize: none;
  outline: none;
  border: 1px solid rgba(41, 44, 49, 0.4);
  padding: 16px;
  border-radius: 10px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 49, 0.5);
  width: 350px;
  margin-top: 5px;
  width: 100%;
}
.showreview-container form {
  display: flex;
  flex-direction: column;
}

.showreview-container button {
  width: 170px;
  height: 62px;
  border-radius: 4px;
  background-color: #b44d2d;
  color: var(--white);
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .showreview-container {
    width: 70%;
  }
  .review-label input {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .showreview-container {
    width: 90%;
    padding: 40px;
  }
  .review-label input {
    width: 100%;
  }
}
