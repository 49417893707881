.footer-container {
  padding: 90px 230px 0 230px;
}

.footer-desk {
  background-color: #24262c;
}

.footer-mob {
  display: none;
}
.line {
  border: 1px solid #4b4b4b;
  width: 100%;
  height: 1px;
  margin-top: 117px;
  display: flex;
  justify-content: center;
}

.fourth-block p {
  width: 400px;
}

/* block 1 style  */

.first-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.phone {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.phone:nth-child(1) {
  margin-top: 0;
}

.footer-first-row {
  display: flex;
}

.phone img {
  margin-right: 11px;
}

.phone p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
}
.footer-logo {
  width: 120px;
  height: 65px;
  margin-bottom: 60px;
  margin-right: 106px;
}

/* block 2 */

.sec-block,
.third-block {
  display: flex;
  flex-direction: column;
}

footer ul {
  list-style: none;
}
.third-block-span {
  margin-bottom: 40px;
}
.sec-block span,
.third-block span,
.fourth-block span {
  color: #de7453;
  font-size: 22px;
  opacity: 90%;
  margin-bottom: 24px;
  font-family: "Popins", sans-serif;
}

.sec-block li,
.third-block li {
  margin-bottom: 10px;
}
.sec-block li a,
.third-block li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.fourth-block p {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 23px;
  font-size: 14px;
  padding-right: 100px;
}

/* Section style */

.second-section ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 97px;
}

.second-section ul li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  margin-right: 70px;
}

.second-section p {
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 38px;
  font-size: 12px;
  margin-bottom: 12px;
}

.footer-bg {
  width: 100%;
  height: 35px;
  border: none;
  border-top-left-radius: 100%;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #24262c;
  flex-direction: column;
  padding-bottom: 41px;
}

.footer-socials {
  display: flex;
  align-items: center;
  margin-top: 58px;
  justify-content: space-between;
  width: 186px;
}
.footer-socials p {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #f1f1f1;
}

.footer-socials a {
  width: 34px;
  height: 34px;
  background-color: rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.footer-logo {
  width: 104px;
  height: 52px;
  user-select: none;
}

.footer-first-section {
  display: flex;
  border-bottom: 1px solid #4b4b4b;
  justify-content: space-between;
  padding-bottom: 100px;
  width: 100%;
  /* padding: 0 10.5%; */
  padding: 100px 10.5%;
}

/* .logo-wrapper {
  margin-right: 100px;
} */

.footer-second-row span {
  color: rgba(255, 255, 255, 0.8);
  font-size: 22px;
  margin-bottom: 24px;
  font-family: "Popins", sans-serif;
  margin-bottom: 50px;
}

.footer-second-row a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 400;
}

/* .footer-second-row {
  margin-right: 200px;
} */

.footer-second-row::nth-child(:last-child) {
  margin-right: 0;
}

.links-cont {
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.footer-second-row p {
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin-bottom: 12px;
}

.rights-p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin-top: 40px;
  font-family: "Montserrat";
  font-weight: 300;
}

.links-row {
  display: flex;
}

.second-section-ul {
  display: flex;
  text-decoration: none;
  margin-top: 60px;
}

.second-section-ul a,
.footer-ul a,
.footer-first-row p {
  font-family: "Montserrat";
  font-weight: 300;
}

.footer-ul li {
  margin-bottom: 15px;
}
.second-section-ul li {
  margin-right: 60px;
}

.second-section-ul li:nth-child(:last-child) {
  margin-right: 0;
}

.footer-first-section ul {
  margin-top: 17px;
}

.second-section-ul a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}

.about-me-p {
  font-family: "Montserrat";
  font-weight: 300;
  width: 400px;
}

@media (max-width: 1024px) {
  .footer-first-section {
    flex-direction: column;
  }
  .logo-wrapper {
    margin-bottom: 50px;
  }

  .footer-second-row {
    margin-right: 25px;
  }
  .about-me-p {
    width: 200px;
  }
  .footer-logo {
    margin-right: 0;
  }
}

@media (max-width: 640px) {
  .links-cont {
    flex-direction: column;
  }
  .footer-second-row {
    margin-top: 30px;
  }
  .second-section-ul li {
    margin-right: 10px;
  }
  .second-section-ul {
    padding-left: 30px;
  }
  .second-section-ul a {
    font-size: 14px;
  }
  footer {
    color: rgba(255, 255, 255, 0.8);
  }
  .footer-desk {
    display: none;
  }
  .footer-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    padding: 57px 47px 28px 47px;
  }
  .footer-mob-img-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
  .footer-mob-img-wrapper span {
    color: #70707047;
    margin-left: 14px;
    font-family: "Montserrat";
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  .footer-mob-img-wrapper img {
    width: 16px;
    height: 16px;
  }
  .about-us-title {
    margin-top: 78px;
    color: rgba(255, 255, 255, 0.6);
    font-family: "Helvetica";
    font-size: 16px;
  }
  .about-me-p-mob {
    text-align: center;
    font-family: "Helvetica Neue";
    line-height: 24px;
    font-size: 12px;
    margin-bottom: 68px;
    color: rgba(255, 255, 255, 0.8);
  }
  .footer-mob a {
    text-decoration: none;
    color: rgba(112, 112, 112, 0.28);
    cursor: pointer;
    margin-bottom: 22px;
    line-height: 17px;
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 300;
  }
  .rights-p-mob {
    margin-top: 98px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 10px;
    text-align: center;
    line-height: 12.19px;
  }
}
