.infoWindow-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  max-width: 300px;
}

.infoWindow-img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 7px;
  margin-right: 14px;
}

.infoWindow-content {
  display: flex;
  flex-direction: column;
}

.infoWindow-title {
  font-family: "Lato";
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
  line-height: 19px;
}

.exact-Address {
  font-family: "Lato";
  font-weight: 400;
  color: #272a31;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
  width: 98px;
  border: none;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 7px;
  cursor: pointer;
}

.exact-Address img {
  margin-right: 6px;
}
.infoWindow-region {
  font-family: "Lato";
  font-size: 14px;
  margin-bottom: 15px;
  color: #6f6a68;
  line-height: 16px;
}

.infoWindow-street {
  font-family: "Lato";
  font-size: 14px;
}

@media (max-width: 640px) {
  .infoWindow-container {
    flex-direction: column;
    text-align: center;
  }
  .infoWindow-img {
    margin-right: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}
