.about-main {
  overflow-x: hidden;
}

.aboutUs-bg {
  width: 100%;
  height: 290px;
  background: url("../../images/aboutpage/Path\ 10372.png");
  background-repeat: no-repeat;
  margin-bottom: 130px;
  background-size: cover;
  background-position: center;
}

.section-one-bg-mob-about-us {
  display: none;
}

.about-us-container {
  display: flex;
  padding: 0 10.5%;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 190px;
}

.about-us-container img {
  width: 48%;
  border-radius: 4px;
  /* height: 389px; */
}
.about-us-container div {
  width: 48%;
}

.about-us-container p {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Lato";
  font-size: 18px;
  line-height: 28px;
}

.about-us-new-container {
  padding: 0 10.5%;
}

.about-us-new-container p {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Lato";
  font-size: 18px;
  line-height: 28px;
  margin-top: 10px;
}
.about-us-container p:nth-child(1) {
  margin-bottom: 24px;
}
@media (max-width: 640px) {
  .section-one-bg-mob-about-us {
    display: flex;
    align-items: flex-end;
    background: url("../../images/aboutpage/aboutMob.png");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .aboutUs-bg {
    display: none;
  }
  .about-us-container {
    padding: 0 16px;
    flex-direction: column;
    margin-bottom: 90px;
  }
  .about-us-container img,
  .about-us-container div {
    width: 100%;
  }
  .about-us-container img {
    margin-bottom: 18px;
  }
  .event-main-title {
    margin-top: 0;
  }
  .event-main-title {
    font-size: 20px;
    margin-bottom: 16px;
  }
}
