.cart-item-wrapper {
  padding: 10px;
  display: flex;
  margin-top: 5px;
}

.cart-item-image {
  width: 65px;
  height: 80px;
  object-fit: contain;
  user-select: none;
}

.cart-item-details {
  margin-left: 10px;
  width: 100%;
  /* background-color: chartreuse; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-item-characteristics {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.characteristics-name {
  color: #102a42;
  font-weight: 500;
}

.characteristics-price {
  color: gray;
}

.characteristics-remove-btn {
  font-size: 14px;
  border: none;
  color: #236eb5;
  background-color: transparent;
  cursor: pointer;
}

.cart-item-count {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart-item-counter-arrow {
  font-size: 20px;
  color: #236eb5;
  cursor: pointer;
}

.cart-container {
  margin-top: 60px;
  /* background-color: lightblue; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-items-container {
  width: 100%;
  /* background-color: lightcoral; */
  margin-top: 30px;
}

.line {
  width: 100%;
  border: 1px solid #102a42;
}

.main-container-cart {
  min-width: 800px;
  margin: 20px 0;
}

.bottom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-cost {
  width: 100%;
  color: #102a42;
  font-weight: 500;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.clear-btn {
  cursor: pointer;
  color: white;
  background-color: #c75758;
  border-radius: 5px;
  font-weight: 700;
  padding: 5px 10px;
  margin-top: 5px;
  border: 1px solid #c75758;
  transition: all 0.5s ease-in;
}

.clear-btn:hover {
  color: #c75758;
  background-color: white;
}
.cart-fav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .main-container-cart {
    min-width: 350px;
  }
}

@media (max-width: 640px) {
  .heart,
  .favorite-img {
    filter: brightness(0) saturate(100%);
    width: 26px;
    height: 26px;
    margin-top: 25px;
  }

  .heart {
    margin-right: 26px;
  }
}
