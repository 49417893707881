.winery-list-container {
  border: 1px solid var(--listCardBorder);
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-Container {
  justify-content: space-between;
}
.winery-img-zoom-cont {
  height: 241px;
  overflow: hidden;
}

.wineryList-img {
  object-fit: cover;
  width: 100%;
  border-radius: 4px 4px 0 0;
  user-select: none;
  transition: all 1.1s;
  height: 100%;
}

.winery-list-container:hover .wineryList-img {
  transform: scale(1.2);
}
.winery-info-container {
  padding: 21px 30px;
}
.winery-info-container h4 {
  color: #393b40;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 20px;
}

.winery-info-container p {
  font-family: "Poppins";
  color: #515459;
  margin-top: 17px;
  margin-bottom: 33px;
}

.winery-region-and-rating-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.winery-rating-wrapper {
  display: flex;
  align-items: center;
}
.winery-rating-wrapper img {
  margin-left: 10px;
}

.winery-wrapper {
  display: flex;
  align-items: center;
}
.winery-wrapper span {
  color: #3a3d43;
  font-family: "Poppins";
}

.learn-more-btn {
  /* float: right; */
  margin: 20px 0;
  width: 168px;
  height: 56px;
  border: none;
  border: 1px solid #2f3238;
  color: #3a3d43;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: transparent;
}

.winery-list-container-mob-tab {
  display: none;
}

@media (max-width: 1020px) {
  .winery-list-container {
    display: none;
  }
  .winery-list-container-mob-tab {
    display: block;
    /* width: 350px; */
    border: 1px solid var(--listCardBorder);
    border-radius: 4px 4px 0 0;
  }
}
