.wine-pass-detail-main {
  background-color: #f2f2f2;
  overflow-x: hidden;
  padding: 120px 10.5%;
}

.wine-pass-detail-main-title {
  text-align: center;
  padding: 20px;
  font-size: 28px;
  color: rgba(58, 61, 67, 1);
}

.winePassDetail-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 50px 80px 120px 80px;
}

.winePassInfo-container {
  display: flex;
  flex-direction: column;
  margin: 86px 0 60px 0;
}

.winePassInfo-container:nth-child(1) {
  margin: 0 0 60px 0;
}
.winePassInfo-container:nth-last-child(1) {
  margin: 86px 0 0 0;
}
.winePassInfo-container h2 {
  color: #000000;
  font-family: "Montserrat";
  font-size: 32px;
  line-height: 39px;
  padding-bottom: 23px;
  border-bottom: 0.25px solid rgba(51, 51, 51, 0.7);
}
.passInfo-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.winepass-image {
  margin-left: 127px;
}
.winePassInfo-container h4 {
  font-family: "Montserrat";
  color: #b54d2e;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 16px;
}

.pass-info-wrap {
  margin-top: 25px;
  min-height: 150px;
  border-right: 0.25px solid rgba(51, 51, 51, 0.6);
  padding-left: 50px;
  padding-right: 2%;
  width: 100%;
}

.show-email-phone-btn {
  background-color: #393b40;
  width: 50px;
  height: 50px;
  color: white;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 10px;
  line-height: 22px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.show-passInfo-mob-email {
  display: flex;
  align-items: center;
}

.winePassDetail-btn {
  background-color: #393b40;
  width: 358px;
  height: 80px;
  color: white;
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  border: none;
  cursor: pointer;
}

.pass-info-wrap:nth-last-child(1) {
  padding-right: 0;
}
.pass-info-wrap:nth-child(1) {
  padding-left: 0;
}

.pass-info-wrap:nth-child(4) {
  border-right: none;
}

.passInfo-icons-wrap {
  display: flex;
  margin-bottom: 20px;
}
.phoneIcon-Phone {
  display: flex;
  width: 200px;
}

.passInfo-icons-wrapShowPass {
  display: flex;
  /* align-items: baseline; */
  width: 250px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.passInfo-icons-wrap img,
.passInfo-icons-wrapShowPass img {
  margin-right: 11px;
  cursor: pointer;
}

.show-text {
  position: absolute;
  top: -20px;
  left: -3px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: #b44d2d;
}

.winePassInfo-container p {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  line-height: 19.5px;
  color: #333333;
}

@media (max-width: 1024px) {
  .wine-pass-detail-main {
    padding: 50px 20px;
  }
  .winePassDetail-container {
    padding: 20px;
  }
  .winePassInfo-container h2 {
    font-size: 24px;
  }
  .winePassInfo-container {
    border: 0.25px solid rgba(51, 51, 51, 0.6);
    padding: 20px;
    border-radius: 4px;
  }

  .winepass-image {
    margin-left: 0;
    width: 100%;
  }
  .passInfo-wrapper {
    flex-wrap: wrap;
  }
  .pass-info-wrap {
    border-right: none;
    padding-left: 0;
    padding-right: 0;
  }
}
