.notFound-background-img {
  background: url("../../images/traditional-winemaking-and-wine-tasting-2021-08-26-22-39-51-utc\ 1.png");
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.notFound-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.notFound-container img {
  margin-bottom: 20px;
}

.notFound-container h1 {
  color: var(--brown);
  margin-bottom: 30px;
}

.notFound-container p {
  color: var(--dark);
  margin-right: 5px;
  font-weight: 500;
}

.notFound-wrapper {
  display: flex;
  align-items: center;
}

.notFound-wrapper button {
  width: 170px;
  height: 60px;
  background-color: var(--brown);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: var(--white);
  font-weight: bold;
  font-size: 18px;
}

.notFound-wrapper a {
  text-decoration: none;
  color: var(--white);
}
