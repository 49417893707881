.bg-image {
  display: flex;
  height: calc(100vh - 100px);
}
.header-overlay {
  width: 35%;
  background-color: #2f3238;
  /* height: 80vh; */
  /* height: calc(100vh - 100px); */
  display: flex;
  /* justify-content: center; */
  padding-left: 10.5%;
  padding-bottom: 5%;
  padding-top: 5%;
}
.text-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-left: 100px; */
  justify-content: center;
}

.text-block h4 {
  /* font-family: "Montserrat"; */
  font-size: 16px;
  font-weight: 400;
  color: white;
  letter-spacing: 3px;
  margin-bottom: 25px;
  line-height: 19.5px;
}

.mob-home-page-image {
  display: none;
}
.text-block p {
  font-family: "Montserrat";
  color: white;
  margin-bottom: 24px;
  font-size: 36px;
  font-weight: bold;
}

.text-block span {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 36px;
  line-height: 43.88px;
  color: var(--brown);
  margin-bottom: 15px;
}
.follow {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 19%;
  bottom: 36%;
  align-items: center;
}
.follow p {
  writing-mode: vertical-rl;
  font-size: 24px;
  font-weight: bold;
  color: var(--white);
  margin-bottom: 20px;
  opacity: 0.7;
}
.social-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  color: #f8f8f88a;
  opacity: 0.7;
}

.bg-line {
  border-left: 1px solid #f8f8f88a;
  height: 248px;
  margin-bottom: 58px;
  opacity: 0.7;
}

.image-and-social {
  position: relative;
  width: 65%;
}

.main-bg-wine {
  width: 100%;
  height: calc(100vh - 100px);
  object-fit: cover;
}

.buy-now-btn1 {
  width: 177px;
  height: 60px;
  background-color: transparent;
  border: 1px solid #9b9b9b;
  border-radius: 8px;
  margin-top: 15px;
}

.tab-and-mob-follow-cont {
  display: none;
}

@media (max-width: 1024px) {
  .bg-image {
    flex-direction: column;
    margin-bottom: 100px;
    height: 100vh;
  }
  .text-block {
    padding-left: 0;
  }
  .follow {
    right: 5%;
    bottom: 2%;
  }
  .mob-home-page-image {
    display: block;
    position: relative;
  }

  .bg-line {
    height: 50px;
  }
  .header-overlay {
    width: 100%;
    height: 100vh;
    padding-left: 25px;
  }
  .image-and-social {
    display: none;
  }
  .tab-and-mob-follow-cont {
    display: block;
    margin-top: 50px;
  }
  .social-wrapper {
    width: 207px;
    display: flex;
    justify-content: center;
  }
  .social-wrapper a {
    margin-right: 10px;
  }
  .text-block h4 {
    margin-bottom: 20px;
  }
  /* .text-block p {
    margin-bottom: 20px;
  } */
}
@media (max-width: 640px) {
  .text-block p {
    margin-bottom: 0;
  }
  .bg-image {
    margin-bottom: 0;
  }
  .text-block h4 {
    margin-bottom: 0;
    font-size: 14px;
  }
  .text-block p {
    font-size: 32px;
  }
  .text-block span {
    font-size: 32px;
  }
  .text-block {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
}
