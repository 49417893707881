.term-title {
  padding: 120px 0;
  font-family: "Montserrat";
  font-weight: bold;
  color: #3a3c42;
  font-size: 36px;
  line-height: 44px;
}

.title-cont {
  background-color: #f1f1f3;
  padding: 0 10.5%;
}

.terms-main-container {
  padding: 116px 10.5%;
}

.terms-main-container a {
  font-family: "Lato";
  font-weight: 900;
  color: #3a3a3c;
  font-size: 16px;
  line-height: 64px;
  text-decoration: underline;
}

.terms-main-container p {
  font-family: "Lato";
  font-weight: 500;
  color: #3a3a3c;
  font-size: 16px;
  line-height: 64px;
}

.terms-main-container h4 {
  text-align: center;
}

@media (max-width: 1024px) {
  .title-cont {
    padding: 0 4%;
  }
  .term-title {
    padding: 31px 0;
  }
  .terms-main-container {
    padding: 15px 4%;
  }
  .terms-main-container p {
    margin-bottom: 20px;
  }
  .terms-main-container p,
  .terms-main-container a {
    font-size: 14px;
    line-height: 26px;
  }
}
