.sort-tab-container {
  display: flex;
  align-items: center;
  margin-right: 56px;
}

.tab-span {
  margin-right: 23px;
  font-family: "Poppins";
  color: #201e1e;
  font-size: 18px;
  white-space: nowrap;
}
