.event-bg {
  width: 100vw;
  height: 290px;
  background: url("../../images/event-page/mainBg.png");
  background-repeat: no-repeat;
  margin-bottom: 130px;
  background-size: cover;
  background-position: center;
}

.section-one-bg-mob-events {
  display: none;
}

@media (max-width: 640px) {
  .section-one-bg-mob-events {
    display: flex;
    align-items: flex-end;
    background: url("../../images/event-page/mobBG.jpg");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .event-bg {
    display: none;
  }
}
