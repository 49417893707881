.gallery-title {
  text-align: center;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #3a3d43;
  margin-top: 80px;
  margin-bottom: 20px;
}

.gellery-item {
  width: 100%;
  height: 241px;
  user-select: none;
  cursor: pointer;
}

.gellery-item img {
  object-fit: cover;
}

.gallery-grid-container {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 40px;
  justify-content: space-between;
  padding: 0 10.5%;
}

.contain-object-fit img {
  object-fit: contain;
}

@media (max-width: 1020px) {
  .gallery-grid-container {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
    padding: 0 5%;
  }
}
