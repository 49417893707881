.article-section-forOverflow {
  overflow-x: hidden;
}

.article-bg {
  width: 100%;
  height: 290px;
  background: url("../../images/articlePage/bg.png");
  background-repeat: no-repeat;
  margin-bottom: 130px;
  background-size: cover;
  background-position: center;
}

.article-card-title {
  color: #de6d4a;
  opacity: 0.6;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 23px;
  padding-top: 20px;
}
/* .section-one-bg-mob-articles {
  display: none;
} */
.bg-mob-article {
  display: none;
}
.articlePage-title {
  text-align: center;
  margin-bottom: 19.4px;
  color: #3a3d43;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 32px;
}

.article-list-container {
  display: flex;
  align-items: center;
  padding: 0 10.5%;
}
.main-article-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
  text-decoration: none;
}

.main-article-date {
  color: #de6d4a;
  opacity: 0.6;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 23px;
}

.main-article-title {
  font-family: "Montserrat";
  font-weight: bold;
  color: #3a3d43;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 20px;
}

.main-article-desc {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Poppins";
  font-weight: 400;
  line-height: 28px;
  font-size: 18px;
  margin-bottom: 80px;
}

.author-container,
.author-cont {
  display: flex;
  align-items: center;
}

.author-cont {
  margin-top: auto;
}
.author-container img {
  width: 50px;
  height: 50px;
  margin-right: 16px;
  user-select: none;
}

.author-desc-container {
  display: flex;
  flex-direction: column;
}

.author-name {
  color: rgba(58, 61, 67, 1);
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.author-position {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  opacity: 0.6;
  color: #3a3d43;
}

.article-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 42px;
}

.home-article-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 42px;
  padding: 0 10.5%;
}

.article-wrapper-img {
  object-fit: cover;
  width: 100%;
  border-radius: 4px 4px 0 0;
  user-select: none;
  transition: all 1.1s;
  user-select: none;
  height: 100%;
}

.article-wrapper {
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: space-between;
}

.article-img-zoom-cont {
  width: 100%;
  height: 213px;
  overflow: hidden;
  margin-bottom: 21px;
}
.article-wrapper:hover .article-wrapper-img {
  transform: scale(1.2);
}

.article-wrapper h4 {
  color: #3a3d43;
  font-size: 24px;
  font-weight: bold;
  font-family: "Montserrat";
  line-height: 38px;
  margin-bottom: 10px;
  margin-top: 11px;
}

.article-wrapper p {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Poppins";
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 20px;
}

.author-cont img {
  height: 50px;
  margin-right: 20px;
  border-radius: 50%;
}

.author-wrap {
  display: flex;
  flex-direction: column;
}

.article-author {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 28px;
  color: #3a3d43;
  font-weight: 400;
}

.article-position {
  font-family: "Patrick Hand";
  font-weight: 400;
  color: #3a3d43;
  font-size: 14px;
  line-height: 28px;
  opacity: 0.6;
}

.articlePage-width {
  width: 100%;
}
@media (max-width: 640px) {
  .bg-mob-article {
    display: flex;
    align-items: flex-end;
    background: url("../../images/articlePage/mobile-bg.png");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .article-bg {
    display: none;
  }
}
@media (max-width: 1240px) {
  .mob-article-padding {
    padding: 0 10px;
  }
  .article-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .home-article-list {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 20px;
  }
  .articlePage-title {
    margin-top: 20px;
    font-size: 20px;
  }
  .article-list-container {
    justify-content: center;
    padding: 0;
  }
  .article-wrapper {
    padding-bottom: 10px;
  }
  .article-bg {
    display: none;
  }
  .articlePage-width {
    width: 90%;
  }
  .article-home-page-width {
    width: 100%;
  }
  .section-one-bg-mob-articles {
    display: flex;
    align-items: flex-end;
    background: url("../../images/articlePage/mobile-bg.png");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
}

@media (max-width: 640px) {
  .section-one-bg-mob-articles {
    display: flex;
    align-items: flex-end;
    background: url("../../images/articlePage/mobile-bg.png");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
}
