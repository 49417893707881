.form-overlay {
  position: fixed;
  top: 150px;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
}

.btn {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  border: none;
  width: 100px;
  height: 50px;
  color: #292b31;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 1px solid #2e3137;
  cursor: pointer;
}

.registration-form {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 80px 167px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 70vh;
  overflow-y: auto;
  position: relative;
  border-radius: 4px;
}

.registration-form h2 {
  text-align: center;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 24px;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 525px;
}

.pass-input,
.email-input,
.country-select {
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
  outline: none;
  border: none;
  border: 1px solid #d9d9d9;
  padding-left: 16px;
  border-radius: 4px;
  opacity: 0.7;
  color: rgba(41, 44, 49);
}
.country-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* border: none; */
  margin-bottom: 24px;
  outline: none;
  border: none;
  border: 1px solid #d9d9d9;
  padding-left: 16px;
  border-radius: 4px;
  color: rgba(41, 44, 49, 0.5);
  background-image: url("../../images/wineries/arrowDowns.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 10px;
  padding: 15px 20px;
  width: 100%;
  height: 48px;
}

.input-error {
  width: 100%;
  height: 48px;
  margin-bottom: 24px;
  outline: none;
  border: none;
  padding-left: 16px;
  border-radius: 4px;
  border: 1px solid #fe4567;
  opacity: 0.7;
}

.input-error-message {
  color: #fe4567;
  opacity: 0.7;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 0px 0px 5px 10px;
}
.pass-input::placeholder,
.email-input::placeholder {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
}

.close-btn {
  position: absolute;
  right: 26px;
  top: 30px;
  font-size: 30px;
  cursor: pointer;
}

.btn-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.checkbox-input {
  width: 24px;
  height: 24px;
  border: none;
  border: 1px solid #d9d9d9;
  margin-right: 8px;
}

.checkbox-label {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: #222e2e;
  margin-left: 6px;
}

.pass-recover {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Inter";
  color: #20302d;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}
.submit-button {
  width: 176px;
  height: 60px;
  margin-top: 30px;
  border: none;
  background-color: #b44d2d;
  color: var(--white);
  border-radius: 4px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 27px;
  cursor: pointer;
  transition: all 1s ease-in-out;
}

.submit-button:hover {
  color: #b44d2d;
  background-color: var(--white);
  border: 1px solid #d9d9d9;
}
button:disabled {
  opacity: 0.7;
}

.reg-btn {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.registration-with-social {
  font-family: "Lexend";
  color: #101828b2;
  font-size: 14px;
}

.social-icons {
  width: 25px;
  height: 25px;
}

.social-bg {
  width: 70px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 12px;
}

.social-title {
  font-family: "Hanken Grotesk";
  color: #101828cc;
}

.registration-sign-in-btn {
  background-color: transparent;
  border: none;
  color: #000000;
  font-family: "Montserrat";
  font-weight: 800;
  cursor: pointer;
  font-size: 18px;
  margin-left: 5px;
  text-decoration: none;
}

.reg-container {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.container {
  display: block;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;
  position: relative;
}

.container input {
  position: absolute;
  transform: scale(0);
}

.container input:checked ~ .checkmark {
  transform: rotate(40deg);
  height: 1em;
  width: 0.7em;
  border-color: #222e2e;
  border-top-color: transparent;
  border-left-color: transparent;
  border-radius: 0;
}

.container .checkmark {
  display: block;
  width: inherit;
  height: inherit;
  border: 2px solid #222e2e;
  border-radius: 4px;
  transition: all 0.3s;
}

.reg-title {
  color: #000000;
  font-family: "Montserrat";
  font-weight: 800;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
}
@media (max-width: 1024px) {
  .registration-form {
    padding: 80px 110px;
  }
}

@media (max-width: 640px) {
  .registration-form {
    padding: 30px;
  }
  .login-form {
    width: 300px;
  }
  .form-overlay {
    position: fixed;
    top: 100px;
    left: 0;
  }
}
