.event-down {
  width: 366px;
  height: 124px;
  background: rgba(62, 62, 62, 0.59);
  padding: 25px;
  border: none;
}

.event-down.active {
  background: rgba(255, 255, 255, 0.59);
}

.event-down span {
  color: var(--white);
}

.future-event {
  display: flex;
  align-items: center;
}

.future-event.active span {
  color: #3a3d43;
}
.future-event.active img {
  filter: brightness(0) saturate(100%) invert(20%) sepia(16%) saturate(304%)
    hue-rotate(182deg) brightness(97%) contrast(88%);
}

.future-event span {
  margin-left: 12px;
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 600;
}

.event-info.active span {
  color: #3a3d43;
}

.event-down-line {
  width: 100%;
  border: 1px solid #767676;
  margin: 15px 0;
}

.event-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-info span {
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 400;
}

.triagle {
  width: 0;
  height: 0;

  border-bottom: 29px solid rgba(62, 62, 62, 0.59);
  opacity: 1;
  border-right: 29px solid transparent;
}

.triagle.triagleActive {
  width: 0;
  height: 0;

  border-bottom: 29px solid rgba(255, 255, 255, 0.59);

  opacity: 1;
  border-right: 29px solid transparent;
}

.event-container {
  position: absolute;
  top: 60px;
  left: 32px;
  z-index: 99;
  animation: event-down 0.8s ease-in-out;
}

@keyframes event-down {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.event-loader {
  --dim: 2px;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
  margin-left: 50%;
  margin-top: 14px;
}
.event-loader .circle {
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.event-loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.event-loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.event-loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.event-loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%,
  25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%,
  50% {
    transform: scale(1) rotate(180deg);
  }

  70%,
  75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .event-container {
    top: 54%;
    left: 20%;
  }
  .event-down {
    width: 290px;
  }
}
