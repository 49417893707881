.favorite-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 60px;
}

.clear-Favorite-Btn {
  width: 125px;
  height: 50px;
  border: none;
  background-color: var(--brown);
  color: white;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
