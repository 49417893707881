.cards {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.new-items-titles {
  font-size: 28px;
  color: rgba(58, 61, 67, 1);
}

.mobile-cart-fav {
  display: none;
}

/* .card {
  display: flex;
  margin-bottom: 20px;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.card img {
  width: 100px;
  height: 300px;
} */

.popular-items {
  margin-top: 180px;
}

.right-block {
  margin-left: 26px;
  margin-top: 10px;
}
.sale {
  width: 83px;
  height: 40px;
  background-color: #742233;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Lato";
  font-size: 20px;
  margin-bottom: 26px;
}

.item-title {
  font-size: 18px;
  color: var(--brown);
  margin-bottom: 12px;
}

.wine-color,
.region {
  margin-bottom: 11px;
  color: #6f6a68;
  font-size: 14px;
  font-weight: bold;
}

.item-grape {
  margin-bottom: 7px;
  color: #939673;
  font-size: 14px;
  font-weight: 400;
}

.item-characteristic {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
}

.item-final-price {
  margin: 16px 0 5px 0;
  font-family: "Roboto";
  font-size: 18px;
  color: #27242c;
  font-weight: bold;
}

.product-left-block {
  width: 40%;
  height: 300px;
  margin-right: 27px;
}

.product-item-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.card-wine-type {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 17px;
  color: #939673;
  font-weight: 400;
}

.item-first-price {
  color: #cccccc;
  font-family: "Roboto";
  font-weight: bold;
  text-decoration: line-through;
}

.padd {
  padding: 0 10.5%;
}
.notsale {
  background-color: white;
  width: 83px;
  height: 40px;
  margin-bottom: 26px;
}

.view-all,
.view-all-deskt {
  display: flex;
  justify-content: flex-end;
  padding-right: 10.5%;
  text-decoration: none;
}

.view-all-mob {
  display: none;
}
.view-all-btn {
  border: none;
  cursor: pointer;
  background-color: white;
  color: #72665a;
  font-size: 16px;
  border-bottom: 1px solid #8a8177;
  text-decoration: none;
}
.our-wine-cards {
  position: relative;
  transition: transform 0.2s;
  display: flex;
}

.our-wine-cards:hover {
  cursor: pointer;
  transform: scale(1.09);
}
/* .our-wine-card {
  margin-bottom: 25px;
} */
.our-wine-cards:hover .overlay {
  opacity: 1;
}

.overlay {
  width: 180px;
  height: 80px;
  background-color: rgba(39, 36, 44, 0.58);
  opacity: 0;
  position: absolute;
  top: 53%;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
}

.cart-icon-cont {
  position: relative;
  margin-right: 10px;
  text-decoration: none;
}

.cart-icon-cont:nth-child(2) {
  margin-right: 0;
}

.cart-number {
  position: absolute;
  top: -11px;
  right: -6px;
  color: var(--white);
  background-color: var(--brown);
  border-radius: 50%;
  padding: 3px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.cart-icon {
  font-size: 30px;
  color: black;
}
/* .swiper-scrollbar-drag {
  width: 251px !important;
} */

@media (max-width: 1024px) {
  .padd {
    padding: 0 20px;
  }
  .our-wine-cards:hover {
    cursor: pointer;
    transform: scale(1);
  }
  .view-all {
    padding-right: 15px;
  }
  .view-all-deskt {
    padding-right: 15px;
  }
}

@media (max-width: 640px) {
  .overlay {
    display: none;
  }
  .view-all-deskt {
    display: none;
  }
  .mobile-cart-fav {
    display: flex;
  }
  .price-cont {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }
  .item-final-price {
    margin: 0 8px 0 0;
  }
  .view-all-mob {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  .new-items-titles {
    margin-top: 80px;
    font-size: 20px;
  }
}
