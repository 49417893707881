/* .card-container {
  padding: 0 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.div1 {
  grid-area: 1 / 1 / 3 / 3;
}
.div2 {
  grid-area: 1 / 3 / 3 / 5;
}
.div3 {
  grid-area: 1 / 5 / 3 / 7;
}
.div4 {
  grid-area: 3 / 1 / 5 / 4;
}
.div5 {
  grid-area: 3 / 4 / 5 / 7;
}

.div1,
.div2,
.div3,
.div4,
.div5 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.images1 {
  width: 100%;
  height: 100%;
  user-select: none;
  transition: all 1.1s;
}

.images2 {
  width: 100%;
  height: 100%;
  user-select: none;
  transition: all 1.1s;
}

.div4,
.div5 {
  height: 291px;
}

.div1:hover .images1,
.div2:hover .images1,
.div3:hover .images1,
.div4:hover .images2,
.div5:hover .images2 {
  transform: scale(1.2);
}


.article-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 319px;
}

.article-title2 {
  width: 319px;
}

.artilcle-title {
  background: rgba(18, 13, 13, 0.25);
  padding: 11px 20px;
  color: white;
  font-size: 16px;
}



.image,
.image2 {
  width: 100%;
  position: relative;
}
.three-cards-row {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.two-cards-row {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 27px;
  margin-bottom: 160px;
}

.article-title2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
  .card-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    padding: 0;
    margin-bottom: 50px;
  }

  .div1 {
    grid-area: 1 / 2 / 3 / 6;
  }
  .div2 {
    grid-area: 3 / 2 / 5 / 6;
  }
  .div3 {
    grid-area: 5 / 2 / 7 / 6;
  }
  .div4 {
    grid-area: 7 / 2 / 9 / 6;
  }
  .div5 {
    grid-area: 9 / 2 / 11 / 6;
  }

  .div1,
  .div2,
  .div3,
  .div4,
  .div5 {
    min-width: 250px;
    height: auto;
  }

  .images2 {
    height: 100%;
  }
  .artilcle-title {
    font-size: 9px;
  }
}
@media (max-width: 1024px) {
  h3 {
    margin-top: 80px;
  }
  .card-container {
    margin-top: 24px;
  }
  .article-title2 {
    top: 70%;
  }
} */

h3 {
  display: flex;
  justify-content: center;
  margin-top: 179px;
  margin-bottom: 14px;
  color: rgba(58, 61, 67, 1);
}

.article-home-page-width {
  width: 100%;
}
@media (max-width: 1240px) {
  .article-home-page-width {
    width: 90%;
  }
}
