.winery-title {
  margin-top: 25px;
  color: rgba(58, 61, 67, 1);
  text-align: center;
  font-size: 32px;
  font-family: "Montserrat";
  font-weight: bold;
  margin-bottom: 21px;
}

.google-map-section {
  padding: 144px 70px 80px;
  background-color: #f1f1f1;
}

.winery-map-mob-tab {
  display: none;
}

@media (max-width: 640px) {
  .google-map-section {
    padding: 20px 0;
    background-color: var(--white);
  }
  .winery-title {
    font-size: 20px;
  }
}

@media (max-width: 1080px) {
  .google-map-section {
    padding: 50px 0;
  }
  .winery-map-mob-tab {
    display: block;
  }
  .winery-map-desktop {
    display: none;
  }
}
