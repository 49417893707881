.product-main-container {
  overflow-x: hidden;
}

.product-first-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 171px;
}

.product {
  width: 35%;
  display: flex;
  padding-left: 10.5%;
  max-height: 60vh;
}

.product-page-product-image {
  max-width: 20vw;
  object-fit: contain;
}

.product-rigth-block {
  width: 65%;
  background-color: #f2f2f2;
  min-height: calc(100vh - 100px);
  padding: 80px 122px;
}

.product-title {
  margin-bottom: 48px;
  font-family: "Montserrat";
  color: #3a3d43;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  display: inline-block;
  margin-bottom: 25px;
}

.title-product-line {
  display: flex;
  border: 0.2px solid rgba(222, 222, 222, 1);
  width: 60%;
  height: 1px;
}

.title-product-line2 {
  border: 0.2px solid #de6d4a;
}
.line-product {
  display: flex;
}

.product-desk {
  color: #b44d2d;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 41px;
}
.wines-icons-wrapper img {
  margin-right: 10px;
}

.prod-desk-icons-container {
  display: flex;
  margin-top: 21px;
  margin-bottom: 23px;
}

@media (max-width: 640px) {
  .title-line {
    width: 223px;
  }
  .title-line2 {
    width: 128px;
  }
}

.product-info-container {
  max-width: 385px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.product-info-container:nth-child(:last-child) {
  margin-bottom: 0;
}

.product-info-container span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
}

.product-info-container h5 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
}

.product-value {
  width: 142px;
  display: flex;
  align-items: flex-start;
}

.productpage-description {
  margin-top: 84px;
  border-left: 9px solid #b54d2e;
  max-width: 630px;
  padding: 0 17px;
}

.productpage-description p {
  margin-bottom: 23px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #3a3d43;
  line-height: 24px;
}

.productpage-description button {
  text-decoration: underline;
  background-color: transparent;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.8);
  line-height: 17px;
  border: none;
  cursor: pointer;
}

.second-section-first-title {
  text-align: center;
  margin-bottom: 14px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #24262d;
}

.product-second-section {
  padding-bottom: 226px;
}

@media (max-width: 640px) {
  .product-first-section {
    background-color: #f2f2f2;
    flex-direction: column;
    padding: 30px 0;
  }
  .product {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .product img {
    width: 100px;
  }
  .product-rigth-block {
    width: 100%;
    padding: 0 20px;
    min-height: 0;
  }
  .product-title {
    font-size: 24px;
    text-align: center;
  }
  .product {
    padding-left: 0;
  }
}
