.catalog-bg {
  width: 100%;
  height: 290px;
  background: url("../../images/catalogPage/Path\ 10372.png");
  background-repeat: no-repeat;
  margin-bottom: 130px;
  background-size: cover;
  background-position: center;
}

.catalog-main-title {
  text-align: center;
  font-family: "Montserrat";
  font-size: 28px;
  line-height: 34px;
  color: #24262d;
  margin-bottom: 14px;
}

.section-one-bg-mob-catalog {
  display: none;
}
.catalog-filter-cont-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-top: 19px;
}

.catalog-filter-container {
  width: 19%;
  margin-right: 44px;
}

.catalog-section {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 10.5%;
}

.catalog-wines {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  /* justify-content: space-between; */
}

.filt-title {
  font-family: "Lato";
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 29px;
}

.title-img-cont {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.title-img-cont h5 {
  font-family: "Lato";
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 15px;
}

.section-one-bg-mob-articles {
  display: none;
}
.filter-option-cont {
  display: flex;
  flex-direction: column;
}

.filter-option-cont span {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 15px;
  cursor: pointer;
}

.title-img-cont h5 {
  font-family: "Lato";
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 15px;
}

.label-column {
  display: flex;
  flex-direction: column;
}

.clicked-span {
  color: var(--brown) !important;
}
.input-options {
  display: flex;
  align-items: center;
}

.input-options input {
  margin-right: 10px;
}

.input-options label {
  font-family: "Lato";
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
}
.filter-for-mobTab {
  display: none;
}
.filtermobBtn {
  display: none;
}

@media (max-width: 1020px) {
  .catalog-filter-container {
    display: none;
  }
  .filter-for-mobTab {
    display: block;
    font-size: 40px;
    margin-right: 10px;
  }
  .catalog-section {
    flex-direction: column;
  }
  .catalog-filter-containers {
    margin-top: 20px;
  }
  .catalog-bg {
    display: none;
  }
  .filtermobBtn {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #e5e5ea;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3a3a3c;
    margin-bottom: 30px;
  }
  .section-one-bg-mob-catalog {
    display: flex;
    align-items: flex-end;
    background: url("../../images/catalogPage/wine-bottles-and-grapes-2021-08-26-15-48-05-utc.jpg");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
}
