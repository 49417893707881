.view-all-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 200px;
  flex-wrap: wrap;
  gap: 100px;
}

@media (max-width: 1024px) {
  .view-all-container {
    padding: 0 20px;
  }
}
