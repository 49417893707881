.contact-main {
  overflow-x: hidden;
}
.contact-bg {
  width: 100%;
  height: 290px;
  background: url("../../images/contactPage/Path\ 10372.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 130px;
}

.contact-section1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 41px;
}

.rigth-bg-contact {
  width: 40%;
  height: 80vh;
  /* background-image: url("../../images/contactPage/map.png");
  background-repeat: no-repeat; */
}
.contact-left-block {
  width: 30%;
  margin-right: 168px;
}

.contact-main-title {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #24262d;
  text-align: center;
  margin-top: 128px;
  margin-bottom: 24px;
}

.contact-desc {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8e8e93;
  margin-bottom: 50px;
}

.contact-contact-detail {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.contact-contact-detail:nth-last-child() {
  margin-bottom: 0;
}

.detail-column {
  display: flex;
  flex-direction: column;
}

.contact-contact-detail img {
  user-select: none;
  margin-right: 20px;
}

.adress-title {
  font-family: "Lato";
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #8e8e93;
}

.contact-detail-info {
  font-family: "Lato";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3a3a3c;
}

.contact-section2 {
  /* width: 100vw; */
  /* height: 100vh; */
  background-color: #f1f1f3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 169px;
  padding-bottom: 220px;
}

.contact-form-container {
  padding: 30px;
  background-color: var(--white);
  box-shadow: 0px 16px 40px 0px rgba(66, 71, 76, 0.1);
  border-radius: 10px;
}

.contact-title-desc {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.message-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 29px;
  background-color: #b44d2d;
  color: var(--white);
  border: none;
  border-radius: 10px;
  margin-top: 41px;
  cursor: pointer;
  margin-bottom: 10px;
}

.message-btn img {
  user-select: none;
  margin-right: 10px;
}

.contact-title {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #24262d;
  text-align: center;
  margin-bottom: 10px;
}

.contact-desc {
  font-family: "Lato";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #8e8e93;
  margin-bottom: 80pxs;
}

.contact-inputs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}
.contact-label-input {
  display: flex;
  flex-direction: column;
}

.contact-label-input:nth-child(1) {
  margin-right: 22px;
}

.contact-texarea {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  margin-top: 10px;
}

.contact-texarea textarea {
  height: 140px;
  resize: none;
  outline: none;
  border: 1px solid #e5e5ea;
  padding: 16px;
  border-radius: 10px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3a3a3c;
}
.contact-label-input label,
.contact-texarea label {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #1c1c1e;
  margin-bottom: 10px;
}

.contact-label-input input {
  height: 55px;
  border-radius: 10px;
  width: 28vw;
  border: 1px solid #e5e5ea;
  outline: none;
  padding-left: 16px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #3a3a3c;
}

.section-one-bg-mob-contact {
  display: none;
}

.name-email-error {
  color: red;
  font-family: "Lato";
  font-weight: 700;
  margin-bottom: 20px;
}

@media (max-width: 1020px) {
  .contact-left-block {
    width: 100%;
    margin-right: 0;
    padding: 20px;
  }
  .rigth-bg-contact {
    display: none;
  }
  .contact-bg {
    display: none;
  }
  .section-one-bg-mob-contact {
    display: flex;
    align-items: flex-end;
    background: url("../../images/contactPage/bgMob.jpg");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
}

@media (max-width: 640px) {
  .contact-section1 {
    margin-top: 0;
  }
  .contact-main-title {
    margin-top: 64px;
  }
  .contact-inputs-container {
    flex-direction: column;
  }
  .contact-section2 {
    padding-top: 30px;
    padding-bottom: 110px;
  }
  .contact-label-input {
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-label-input:nth-child(1) {
    margin-right: 0;
  }
  .contact-label-input:nth-child(2) {
    margin-bottom: 0;
  }
  .contact-bg {
    display: none;
  }
  .contact-label-input input,
  .contact-texarea textarea {
    width: 100%;
  }
  .contact-form-container {
    margin: 50px 0;
  }
}
