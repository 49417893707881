.successPay-main-container,
.failedPay-main-container {
  height: calc(100vh - 100px);
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.successPay-container,
.failedPay-container {
  background-color: white;
  padding: 6% 19.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.successPay-container img {
  margin-bottom: 16px;
}
.failedPay-container img {
  margin-bottom: 30px;
}

.successPay-container h2 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #1e923e;
  margin-bottom: 30px;
}
.failedPay-container h2 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #b44d2d;
  margin-bottom: 30px;
}

.successPay-container p {
  font-family: "Lato";
  color: #1c1c1e;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 49px;
}

.failed-p {
  font-family: "Lato";
  color: #1c1c1e;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.failed-sec-p {
  font-family: "Lato";
  color: #1c1c1e;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  max-width: 450px;
  margin-bottom: 49px;
}

.successPay-container button,
.failedPay-container button {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 19.5px;
  color: #ffffff;
  padding: 20px 48px;
  background-color: #b44d2d;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
}
