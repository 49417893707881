.verification-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 65px;
  flex-direction: column;
}

.verification-container h5 {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #3a3d43;
  margin: 30px 0;
}

.verification-message {
  font-family: "Lato";
  color: #b44d2d;
  font-weight: 500;
  font-size: 24px;
}

.verification-button {
  width: 176px;
  height: 60px;
  margin-top: 30px;
  border: none;
  background-color: #b44d2d;
  color: var(--white);
  border-radius: 4px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 27px;
  cursor: pointer;
}
