.profile-page-main-titles {
  padding-top: 159px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-page {
  background-color: #f2f2f2;
  padding: 0 10.5%;
  padding-bottom: 235px;
}

.profile-title {
  padding: 0 65px 21px 65px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  cursor: pointer;
}

.wine-pass-title {
  padding: 0 65px 21px 65px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  cursor: pointer;
}

.profile-page-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 70px;
}

.personal-info-container {
  padding: 34px 41px 55px 56px;
  width: 48%;
  background-color: var(--white);
  border-radius: 8px;
}

.bonus-point-container {
  width: 48%;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
}

.change-pass-container {
  width: 48%;
  background-color: var(--white);
  border-radius: 8px;
  padding: 35px 96px 32px 58px;
  display: flex;
  flex-direction: column;
}
.change-pass-container {
  margin-top: 30px;
}

.personal-info-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}

.pers-info-wrapper {
  display: flex;
  align-items: center;
}

.pers-info-wrapper img {
  margin-right: 11px;
}
.pers-info-wrapper h5 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
}

.personal-info-prop-value {
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.personal-info-prop-container {
  display: flex;
  flex-direction: column;
  margin-right: 125px;
}

.personal-info-value-container {
  display: flex;
  flex-direction: column;
}

.personal-info-prop-container span {
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  font-weight: 400;
}

.personal-info-value-container span {
  margin-bottom: 20px;
  font-family: "Poppins";
  color: #3a3d43;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.wine-pass-access {
  font-family: "Poppins";
  color: #3a3d43;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.prof-info-prop {
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  font-weight: 400;
}

.wine-info-props {
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  font-weight: 400;
  margin-right: 179px;
  white-space: nowrap;
}
.prof-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prof-info-value {
  display: flex;
  align-items: flex-start;
  width: 300px;
  margin-bottom: 20px;
  font-family: "Poppins";
  color: #3a3d43;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.bonus-container {
  padding: 34px 55px 0 0;
  width: 100%;
}

.bonus-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 29px;
}

.bonus-wrap img {
  margin-right: 12px;
}

.bonus-wrap span {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
}

.bonus-text {
  display: flex;
  align-items: baseline;
  margin-bottom: 14px;
}
.bonus-text p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: rgba(41, 44, 49, 0.8);
}

.bonus-img {
  user-select: none;
}
.bonus-text span {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #b44d2d;
  margin-left: 16px;
  margin-right: 10px;
}

.bonus-text h4 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
  color: #3a3d43;
  font-size: 15px;
  line-height: 27px;
}

.useBonusPoints {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #b44d2d;
}

.change-pass-container span {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
  margin-bottom: 9px;
}

.change-pass-container p {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba(41, 44, 49, 0.8);
  margin-bottom: 10px;
}

.change-pass-container input {
  border: 1px solid rgba(41, 44, 49, 0.4);
  color: rgba(41, 44, 49, 0.4);
  outline: none;
  height: 60px;
  padding: 19px 24px;
  border-radius: 4px;
  margin-bottom: 9px;
}

.edit-info-data {
  border: 1px solid rgba(41, 44, 49, 0.4);
  color: rgba(41, 44, 49, 1);
  outline: none;
  height: 30px;
  padding: 19px 10px;
  border-radius: 4px;
  margin-bottom: 9px;
}

.change-pass-container button,
.edit-btn {
  width: 170px;
  height: 62px;
  border-radius: 4px;
  background-color: #b44d2d;
  color: var(--white);
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border: none;
  cursor: pointer;
}

.delete-account-container {
  margin-top: 44px;
}

.delete-account-container h5 {
  color: #3a3d43;
  font-family: "Lato";
  font-size: 16px;
  line-height: 19.2px;
  margin-bottom: 11px;
  font-weight: 600;
}

.delete-account-container p {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(41, 44, 49, 0.8);
}

.delete-account-container span {
  color: #b44d2d;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.delete-account-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  border: none;
  background-color: white;
  padding: 100px 85px 64px;
}

.delete-account-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.delete-acc-closeBtn {
  position: absolute;
  top: -31%;
  right: -15%;
  cursor: pointer;
}
.delete-account-modal-container h4 {
  color: #3a3d43;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 17px;
}
.delete-account-modal-container p {
  font-family: "Lato";
  font-weight: 400;
  color: #222e2e;
  line-height: 25px;
  font-size: 16px;
  text-align: center;
  width: 350px;
  margin-bottom: 45px;
}

.delete-account-btns button {
  padding: 20px 29px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-acc-deleteBtn {
  background-color: #b44d2d;
  font-family: "Montserrat";
  margin-right: 26px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
}

.delete-acc-cancelBtn {
  color: #272a31;
  background-color: #f2f2f2;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
}
/* wine pass */

.profile-wine-pass-left-block {
  width: 80%;
}

.profile-wine-pass-rigth-block {
  width: 20%;
  margin-left: 4.5%;
}

.wine-pass-detail-info {
  display: flex;
  flex-direction: column;
}

.profile-wine-pass-main-container {
  margin-top: 57px;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
}

.profile-wine-pass-container {
  padding: 43px 92px 46px 37px;
  background-color: var(--white);
  display: flex;
  /* align-items: baseline; */
  margin-bottom: 30px;
  border-radius: 8px;
}

.current-wine-pass-title {
  padding-left: 40px;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #3a3d43;
  margin-bottom: 21px;
}

.winepass-profile-info {
  padding-left: 40px;
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.winepass-owner-info {
  display: flex;
  align-items: center;
}

.winepass-img {
  margin-right: 5%;
}

.qr-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  margin-bottom: 20px;
}

.qr-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  padding: 40px 0;
}

/*
Winery styles
*/

.visitor-point-container {
  padding: 33px;
  width: 48%;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.visitor-line {
  width: 50%;
  height: 1px;
  background-color: rgba(41, 44, 49, 0.2);
  margin-bottom: 25px;
}

.visitorIcon-Text {
  display: flex;
  margin-bottom: 21px;
  align-items: center;
}

.visitorIcon-Text img {
  margin-right: 7px;
}

.visitorIcon-Text span {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
}

.visitor-this-year {
  font-family: "Lato";
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 49, 0.8);
}
.visitor-this-year span {
  color: #b44d2d;
  font-size: 40px;
  line-height: 48px;
  margin: 0 8px;
}

.total-visitors-span {
  margin-top: 30px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(41, 44, 49, 0.8);
}

.scan-qr-code-text {
  margin-bottom: 21px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #3a3d43;
}

.qr-text-for-app {
  font-family: "Lato";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(41, 44, 49, 0.7);
}

.winery-profile-wine-pass-main-container {
  margin-top: 57px;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 70vh;
  padding: 22px 37px;
  border-radius: 8px;
}
.winery-visitors-headers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
  border: none;
  font-family: "Montserrat";
}

td {
  text-align: left;
  padding: 19px;
  border: none;
  font-family: "Poppins";
  font-size: 16px;
  color: #3a3d43;
  line-height: 24px;
  font-weight: 600;
}

th {
  text-align: left;
  padding: 19px;
  color: #3a3d43;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
}

@media (max-width: 1024px) {
  .profile-title {
    font-size: 24px;
  }
  .wine-pass-title {
    font-size: 24px;
  }
  .profile-page-container {
    flex-direction: column;
  }
  .personal-info-container {
    width: 100%;
    margin-bottom: 30px;
  }
  .bonus-point-container {
    width: 100%;
  }
  .change-pass-container {
    width: 100%;
  }

  /* wine pass  */

  .profile-wine-pass-left-block {
    width: 100%;
  }
  .profile-wine-pass-rigth-block {
    width: 100%;
    margin-left: 0;
  }
  .profile-wine-pass-container {
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  .current-wine-pass-title {
    text-align: center;
    padding-left: 0;
  }
  .winepass-img {
    margin-right: 0;
    width: 100%;
  }
  .winepass-profile-info {
    padding-left: 0;
  }
  .qr-title {
    text-align: center;
    margin-top: 20px;
  }
  .wine-info-props {
    margin-right: 15px;
  }
  .wine-pass-detail-info {
    width: 100%;
    justify-content: space-around;
    margin: 20px 0;
  }
  .profile-page {
    padding: 0 5%;
  }
  .prof-info-wrapper {
    padding-left: 20px;
  }
}

@media (max-width: 640px) {
  .profile-page-main-titles {
    padding-top: 30px;
  }
  .profile-title {
    font-size: 18px;
    padding: 0;
  }
  .wine-pass-title {
    font-size: 18px;
    padding: 0;
    padding-left: 20px;
  }
  .pers-info-wrapper h5 {
    font-size: 21px;
  }
  .prof-info-wrapper {
    flex-direction: column;
  }
  .prof-info-value {
    width: auto;
    display: block;
  }
  .personal-info-container {
    padding: 20px;
  }
  .bonus-point-container {
    flex-direction: column;
  }
  .bonus-img {
    width: 100%;
  }
  .bonus-container {
    padding: 20px;
  }
  .change-pass-container {
    padding: 20px;
  }
  .profile-page {
    padding: 0 4%;
    padding-bottom: 30px;
  }
  .winepass-profile-info {
    flex-direction: column;
  }
  .delete-account-modal-container {
    padding: 68px 22px 54px;
  }
  .delete-acc-closeBtn {
    top: -21%;
    right: 1%;
  }
}
