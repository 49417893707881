/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&family=Lato:wght@100;300;400;500;700&family=Montserrat:wght@300;500;600;700;900&family=Open+Sans:wght@300;400;500;600;700&family=Roboto:wght@100;300;400;500;700;900&family=Inter:wght@300;400;500;600;700&family=Lexend&family=Hanken+Grotesk&family=Patrick+Hand&family=Mulish:wght@300;400;500;600;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://free.bboxtype.com/embedfonts/?family=FiraGO:100,200,400,500,600,700,800");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.home-main {
  overflow-x: hidden;
}
img {
  user-select: none;
}

body {
  overflow-x: hidden;
}
.no-scroll {
  overflow-y: hidden;
}

/* Stop auto zoom when input focused */
@media (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
  .pswp__button--close {
    margin-right: 10px !important;
  }
}
:root {
  --white: #ffffff;
  --brown: #b44d2d;
  --bg-color: #f1f1f3;
  --text-black: #3a3d43;
  --dark: #393b40;
  --listCardBorder: #d6d6d6;
}

.gm-bundled-control-on-bottom {
  top: 300px;
}

.gm-fullscreen-control {
  top: 200px !important;
}

a {
  text-decoration: none;
}

.error-handler-contianer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
