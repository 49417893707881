.event-main {
  overflow-x: hidden;
}
.event-main-title {
  font-family: "Montserrat";
  font-size: 32px;
  line-height: 39px;
  color: #3a3d43;
  text-align: center;
  margin-top: 130px;
  margin-bottom: 19px;
  text-transform: uppercase;
}

.no-more-event-p {
  text-align: center;
  color: #e79a74;
  font-size: 24px;
  font-family: "Poppins";
  margin-bottom: 132px;
}

.event-section-container {
  display: flex;
  /* justify-content: center; */
  padding: 0 10.5%;
}

.event-info-container {
  width: 100%;
}

.event-sort-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.event-cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.event-card-container {
  width: 79%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  margin-bottom: 32px;
  overflow-x: hidden;
}

.img-and-date-wrapper {
  position: relative;
  margin-right: 88px;
  width: 100%;
  height: 300px;
}

.date-wrapper {
  position: absolute;
  top: 20%;
  left: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 210px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: var(--white);
}

.date-wrapper-mob {
  position: absolute;
  bottom: -10%;
  left: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: var(--white);
}

.event-day {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 64px;
  line-height: 96px;
  color: #b44d2d;
}

.event-day-mob {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 30px;
  color: #b44d2d;
}
.event-month {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #b44d2d;
}

.event-month-mob {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 18px;
  color: #b44d2d;
}

.event-year {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.3);
}

.event-year-mob {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
}

.event-title {
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #393b40;
  margin-bottom: 9px;
}

.event-company {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #b44d2d;
  margin-bottom: 14px;
}

.event-desc {
  font-family: "Poppins";
  font-family: 400;
  font-size: 16px;
  line-height: 24px;
  color: #515459;
  margin-bottom: 25px;
  padding-right: 35%;
}

.event-duration {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #515459;
}

.event-location-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.event-location-wrapper img {
  margin-right: 11px;
}

.event-location-wrapper span {
  font-family: "Poppins";
  color: #727477;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.loc-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  margin-top: 25px;
}

.loc-btn-wrapper button {
  width: 169px;
  height: 58px;
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 22px;
  color: #727272;
  border: 1px solid #969696;
  border-radius: 5px;
  cursor: pointer;
}

.eventcard-img {
  width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
}

@media (max-width: 1020px) {
  .event-card-container {
    flex-direction: column;
    width: 90vw;
  }
  .img-and-date-wrapper {
    margin-right: 0;
    height: 150px;
  }
  .eventcard-img {
    width: 90vw;
  }
  .event-desc {
    padding-right: 0;
  }
  .event-info-container {
    padding: 20px;
  }
  .loc-btn-wrapper {
    margin-top: 20px;
    padding-right: 0;
    flex-direction: column;
    align-items: flex-start;
  }
  .event-section-container {
    padding: 0 20px;
  }

  .loc-btn-wrapper button {
    width: 170px;
    height: 58px;
  }
  .event-location-wrapper {
    margin-top: 0;
    margin-bottom: 31px;
  }
  .event-sort-container {
    flex-direction: column;
    align-items: baseline;
  }
}
