.article-detail-title {
  text-align: center;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 32px;
  color: #3a3d43;
  line-height: 39px;
  padding: 0 300px;
  margin-bottom: 20px;
}

.article-detail-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 56px;
  padding: 0 10.5%;
}

.article-detail-container-p {
  column-count: 2;
  column-gap: 20px;
  margin-bottom: 56px;
}

.article-detail-container-p {
  color: rgba(58, 61, 67, 0.6);
  font-family: "Poppins";
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.article-detail-btn {
  background-color: transparent;
  border: none;
  font-family: "Inter";
  margin-top: 14px;
  font-size: 14px;
  color: #3a3d43;
  cursor: pointer;
}

.detail-author-cont {
  display: flex;
  align-items: center;
  margin-bottom: 180px;
  margin-top: 56px;
}

.detail-author-cont img {
  margin-right: 16px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.detail-author-name {
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #3a3d43;
}

.detail-author-position {
  font-family: "Patrick Hand";
  color: #3a3d43;
  opacity: 0.6;
  font-size: 14px;
  line-height: 28px;
}

.article-detail-similar-article-title {
  text-align: center;
  margin-bottom: 17px;
}

.articles-detail-width {
  width: 100%;
}

@media (max-width: 1240px) {
  .article-detail-title {
    padding: 0 20px;
    font-size: 18px;
  }
  .article-detail-container-p {
    column-count: 1;
  }
  .article-detail-container {
    justify-content: center;
    padding: 0;
  }
  .articles-detail-width {
    width: 90%;
  }
}
