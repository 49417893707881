.title-line {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.2px solid #dedede;
  width: 289px;
  height: 1px;
  margin-bottom: 60px;
}

.title-line2 {
  border: 0.2px solid #de6d4a;
  width: 166px;
}
.line-center {
  display: flex;
  justify-content: center;
}

@media (max-width: 640px) {
  .title-line {
    width: 223px;
    margin-bottom: 20px;
  }
  .title-line2 {
    width: 128px;
  }
}
