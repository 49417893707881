.wineries-detail-bg {
  width: 100%;
  height: 290px;
  background: url("../../images/winepassport/wineriesBg.png");
  background-repeat: no-repeat;
  margin-bottom: 151px;
  background-size: cover;
  background-position: center;
}
.wineries-detail-bg-mob {
  display: none;
}

@media (max-width: 640px) {
  .wineries-detail-bg {
    display: none;
  }
  .wineries-detail-bg-mob {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 290px;
    background: url("../../images/detail-bg-mob.png");
    background-repeat: no-repeat;
    margin-bottom: 19px;
    background-size: cover;
    background-position: center center;
  }
}
