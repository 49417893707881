.carousel-map-container {
  border-radius: 10px;
  height: 100vh;
  position: relative;
}
.google-map-search-container {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 99;
  padding: 0 16px 0 20px;
  background-color: #292c31;
  width: 25%;
  top: 2.5%;
  left: 2.5%;
  min-height: 77.5vh;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}
.location-slider-thumbs {
  position: absolute;
  width: 90%;
  bottom: 0;
  left: 2%;
}

/* .input-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.input-wrapper input {
  padding-left: 4%;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  min-height: 6vh;
  color: var(--white);
} */

select {
  -webkit-appearance: none;
  appearance: none;
}
.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "▼";
  font-size: 0.7rem;
  top: 24%;
  right: 8%;

  position: absolute;
  color: white;
}
.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 4%;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  color: var(--white);
}

.google-map-search .MuiInputBase-input {
  height: 4vh;
}
.google-map-search .TextField__label {
  color: white;
}
.google-map-search .MuiInputBase-input,
.google-map-search .MuiButtonBase-root,
.google-map-search .MuiFormLabel-root,
.google-map-search .MuiFormLabel-root .Mui-focused {
  color: white;
}
.google-map-search .css-p0rm37 {
  color: white;
}

.google-map-search .MuiButtonBase-root {
  font-size: 0.7rem;
  padding-right: 18.5px;
}

.google-map-search .MuiAutocomplete-input:focus {
  outline: none;
}
/* .google-map-search .MuiAutocomplete-root {
  border: 1px solid red;
} */

.search-icon2 {
  color: var(--white);
  font-size: 24px;
  position: absolute;
  margin-right: 20px;
}
.input-wrapper input::placeholder {
  color: var(--white);
  opacity: 1;
}

.google-map-search {
  width: 100%;
}

.searchIcon {
  position: absolute;
  width: 24px;
  height: 24px;
}

.search-title {
  color: var(--white);
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 5%;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin: 6% 0;
}
.checkbox-wrapper input {
  margin-right: 11px;
  width: 27px;
  height: 27px;
  accent-color: #707070;
}

.checkbox-wrapper label {
  color: var(--white);
  font-family: "Poppins";
  font-size: 16px;
}

.mob {
  display: none;
}

.search-input {
  width: 100%;
  height: 30px;
}

.select-cont {
  display: flex;
  flex-direction: column;
}

.select-label {
  font-family: "Poppins";
  font-size: 16px;
  color: var(--white);
  font-weight: 400;
  margin-bottom: 3%;
}

.select-box {
  margin-bottom: 8%;
  height: 6vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  padding-left: 10px;
  border-radius: 5px;
  border: none;
  color: var(--white);
}

.option {
  color: var(--white);
  height: 6vh;
  width: 100%;
  background-color: rgba(41, 44, 49, 0.8);
  border: none;
  outline: none;
}
.input-wrapper:focus {
  border: 3px solid yellow !important;
}
.search-toggle {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  margin-right: -44px;
  background-color: #292c31;
}
.search-toggle-false {
  width: 57px;
  height: 57px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  margin-right: -44px;
  background-color: #292c31;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: absolute;
  z-index: 2;
  top: 37%;
}

.search-btn {
  width: 144px;
  height: 6vh;
  border: none;
  background-color: var(--brown);
  color: var(--white);
  cursor: pointer;
  border-radius: 5px;
}

.arrow {
  color: var(--white);
  font-size: 35px;
}
/* 
.hidden {
  transform: translateX(-100%);
  opacity: 0;
} */

@media (max-width: 1080px) {
  .google-map-search-container {
    width: 55%;
  }
}
@media (max-width: 640px) {
  .google-map-search-container {
    padding: 25px;
    width: 68%;
  }
  .search-toggle {
    margin-right: -50px;
  }
  .carousel-map-container {
    margin-top: 21px;
  }
  .search-toggle-false {
    width: 65px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    background-color: #292c31;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    z-index: 2;
    top: 10%;
    left: 0%;
    padding: 15px;
  }
  .search-toggle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    margin-right: 0;
    background-color: none;
    position: absolute;
    top: 2%;
    right: 3%;
  }
  .google-map-search-container {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 99;
    padding: 29px 16px 35px 20px;
    background-color: #292c31;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 75vh;
  }
}
