nav {
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 100%;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: background-color 0.8s ease-in-out;
}

.navbar-logo-mob {
  display: none;
}

.header.active {
  background-color: #272a31;
}

.logo {
  width: 120px;
  height: 50px;
  user-select: none;
}

.navbar-logo {
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  background-color: #272a31;
  opacity: 0.9; */
  display: flex;
  align-items: center;
  width: 35%;
  background-color: #272a31;
  opacity: 0.9;
  padding-left: 10.5%;
}

.navbar-logo.active {
  background-color: var(--white);
  transition: background-color 0.8s ease-out;
}

.navbar-ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10.5% 0 5%;
  list-style: none;
  width: 65%;
  background-color: var(--white);
  transition: background-color 0.8s ease-out;
  /* position: relative; */
  user-select: none;
}

.wine-catalog-menu {
  display: inline-block;
  background-color: rgba(62, 62, 62, 0.59);
  padding: 19px 0 22px 25px;
  font-family: "Lato";
  font-weight: bold;
  color: white;
  position: relative;
}

.profile-menu {
  display: inline-block;
  background-color: rgba(62, 62, 62, 0.59);
  /* padding: 10px; */
  font-family: "Lato";
  font-weight: bold;
  color: white;
}
.catalog-Dropdown {
  position: absolute;
  left: 0%;
  top: 100%;
  display: flex;
  align-items: flex-start;
  font-family: "Lato";
  font-weight: bold;
  color: white;
  cursor: pointer;
  animation: catalog-slide-downs 0.8s ease-in-out;
}

@keyframes catalog-slide-downs {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.wine-catalog-link {
  min-width: 200px;
}
.article-Dropdown {
  position: absolute;
  left: 0%;
  top: 100%;
  display: flex;
  align-items: flex-start;
  font-family: "Lato";
  font-weight: bold;
  color: #272a31;
  animation: article-slide-downs 0.8s ease-in-out;
}

@keyframes article-slide-downs {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.profile-Dropdown {
  position: absolute;
  left: -150%;
  top: 89%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-family: "Lato";
  font-weight: bold;
  color: #272a31;
  width: 130px;
  animation: profile-slide-down 0.8s ease-in-out;
}

.log-out-btn-navbar {
  color: white;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-left: 10px;
}
@keyframes profile-slide-down {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.profile-sub-menu {
  display: flex;
  flex-direction: column;
  padding: 22px 15px;
}
.profile-Dropdown button {
  width: 100px;
  height: 50px;
  color: #272a31;
  background-color: var(--white);
  margin: 10px 0;
  font-family: "Lato";
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.catalog-sub-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 200px;
}

.catalog-sub-menu a {
  margin-top: 10px;
}

.catalog-sub-menu a:hover {
  color: #e79a74;
}
.wine-catalog-lists {
  display: flex;
  flex-direction: column;
  background-color: rgba(62, 62, 62, 0.59);
  padding: 19px 100px 22px 25px;
  animation: slide-down-wine-catalog 0.8s ease-in-out;
  position: absolute;
  top: -14%;
  left: 100%;
}

@keyframes slide-down-wine-catalog {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.wine-catalog-link {
  font-family: "Lato";
  font-weight: bold;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid white;
}

.wine-catalog-link:nth-child(1) {
  padding-top: 0;
  margin-top: 0;
}

.wines-link {
  color: white;
}

.wine-catalog-link:hover {
  color: #e79a74;
}

.wine-catalog-lists-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #272a31;
  padding: 20px 0;
}
.wine-catalog-link-mob {
  display: block;
  border-bottom: 1px solid #e79a74;
  padding: 10px;
}

.wine-catalog-lists-mob a {
  color: white;
}
.navbar-ul.active {
  background-color: #272a31;
  color: var(--white);
}

.navlink {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* margin-right: 65px; */
  color: #262a2e;
  font-size: 14px;
  font-weight: bold;
  height: 10vh;
  transition: background-color 0.8s ease-out;
}

.navlink.navactive {
  color: var(--white);
}

.nav-item::after,
.nav-item-event::after {
  content: "";
  display: block;
  height: 4px;
  /* width: 100%; */
  background-color: #e79a74;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  /* transform-origin: left; */
}
.nav-item:hover::after,
.nav-item-event:hover::after {
  transform: scaleX(1);
}

.nav-item-event {
  position: relative;
}

.nav-item {
  position: relative;
}

.active {
  color: #e79a74;
}

.wineri-dropMenu {
  position: absolute;
  top: 50px;
  width: 100%;
  background: rgba(62, 62, 62, 0.59);
  text-align: start;
  list-style: none;
  padding-bottom: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 10000;
}

.wineri-dropMenu a {
  text-decoration: none;
  color: var(--white);
}

.wineri-dropMenu li {
  margin-top: 20px;
}

.burger-menu-tab-mob {
  display: none;
}

.profile-ul-tab {
  display: none;
}
@media (max-width: 640px) {
  .navbar-ul {
    display: none;
  }
  .navbar-logo-mob {
    display: block;
    margin-left: 25px;
  }
  .navbar-logo {
    display: none;
  }

  .burger-menu {
    color: white;
  }
  .burger-menu.active {
    color: white;
  }
  .header {
    background-color: #2f3238;
    width: 100%;
  }
  .navbar-ul-tab {
    list-style: none;
  }
  .navbar-logo {
    background-color: #2f3238;
    opacity: 1;
  }
  .header.active {
    background-color: #2f3238;
  }
  .navbar-ul.active {
    background-color: white;
    color: black;
  }
}

@media (max-width: 1024px) {
  .burger-menu-tab-mob {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .profile-ul-tab {
    display: block;
    width: 100%;
    animation: burger-down 0.8s ease-in-out;
  }

  @keyframes burger-down {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .nav-item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    background-color: #2f3238;
  }
  .mob-log-out-btn {
    background-color: #e79a74;
    color: var(--white);
    padding: 10px 5px;
    border: none;
    cursor: pointer;
  }

  .navbar-logo-mob {
    display: block;
    margin-left: 25px;
  }
  .navbar-logo {
    display: none;
  }

  .burger-menu {
    color: white;
  }
  .burger-menu.active {
    color: white;
  }
  .header {
    background-color: #2f3238;
    width: 100%;
  }
  .navbar-ul-tab {
    list-style: none;
  }
  .navbar-logo {
    background-color: #2f3238;
    opacity: 1;
  }
  .navlink {
    border-bottom: 1px solid rgba(216, 217, 218, 0.2);
  }
  .header.active {
    background-color: #2f3238;
  }
  .navbar-ul.active {
    background-color: white;
    color: black;
  }
  .navbar-ul {
    display: none;
  }
  .container {
    display: flex;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-logo {
    width: 50%;
    height: 100px;
  }
  .burger-menu {
    margin-right: 10px;
  }

  .burger-menu.active {
    color: white;
  }
  .lang-reg {
    padding-right: 20px;
  }
  .navlink {
    color: var(--white);
    background-color: #2f3238;
    margin: 0 12%;
  }
  .navbar-ul-tab {
    animation: burger-down 0.8s ease-in-out;
    max-height: 100vh;
    overflow-y: auto;
  }

  @keyframes burger-down {
    from {
      transform: translateY(-10px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
