.benefit-container {
  margin-right: 10.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 236px;
}

.benefit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 50%;
}
.benefit-wrapper:nth-child(1) {
  margin-right: 140px;
}

.image-radius {
  width: 70px;
  height: 70px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 29px;
  user-select: none;
}

.benefit-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 22px;
  color: var(--dark);
  margin-bottom: 19px;
}

.benefit-info {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  color: var(--dark);
}

.benefit-list1,
.benefit-list2 {
  display: flex;
}

.benefit-list1 {
  margin-bottom: 61px;
}
.benefit-list2 {
  margin-top: 52px;
}

.wine-image-bg {
  width: 659px;
  height: 580px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* mix-blend-mode: multiply; */
  filter: grayscale(70%);
  margin-right: 130px;
}
.wine-image-bg-mob {
  display: none;
}
/* .wine-image-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(58, 60, 66, 0.5);
} */

@media (max-width: 1024px) {
  .benefit-container {
    margin-right: 0;
    margin-bottom: 70px;
    flex-direction: column;
  }
  .wine-image-bg {
    margin-right: 0;
  }
  .benefit-wrapper {
    padding: 15px 35px;
  }
}

@media (max-width: 640px) {
  .benefit-wrapper:nth-child(1) {
    margin-right: 0;
  }
  .benefit-container {
    flex-direction: column;
    align-items: baseline;
  }
  .wine-image-bg {
    display: none;
  }
  .wine-image-bg-mob {
    display: block;
    width: 100%;
    filter: grayscale(70%);
    margin-top: 24px;
    padding: 0 16px;
  }
  .benefit-wrapper {
    padding: 0 20px;
    align-items: center;
    margin-top: 60px;
    width: 100%;
  }
  .benefit-list1,
  .benefit-list2 {
    flex-direction: column;
  }
  .benefit-wrapper p {
    text-align: center;
  }
}
