.faq-main-title {
  text-align: center;
  padding-top: 167px;
  margin-bottom: 23px;
}

.faqs-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10.5% 160px 10.5%;
}

.faq-img-quest {
  display: flex;
  align-items: baseline;
}
.faq-img-quest img {
  margin-right: 10px;
}

.faq-img-quest p {
  font-family: "Lato";
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1c1c1e;
}

.faq-answ {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(58, 61, 67, 0.6);
}
.faq-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 23px 21px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  margin-right: 41px;
}

.faq-img-question-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
}

@media (max-width: 1024px) {
  .faq-container {
    width: 100%;
    margin-right: 0;
  }
  .faq-main-title {
    padding-top: 45px;
  }
  .faqs-container {
    padding: 0 10px 160px 10px;
  }
}
