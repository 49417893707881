.eventDetail-bg {
  width: 100%;
  height: 290px;
  background: url("../../images/event-page/mainBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
}
.event-duration {
  cursor: pointer;
}

.text-container-event {
  width: 30%;
  height: 100%;
  background-color: #24262c;
  opacity: 0.8;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.event-subTitle {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 24px;
  line-height: 32ox;
  color: #b44d2d;
  margin-top: 32px;
}

.eventDetail-section {
  padding: 0 10.5%;
}

.date-wrapper-DetailPage {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 246px;
  height: 210px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: var(--white);
  margin-top: -40px;
}

.event-firstsection {
  display: flex;
  align-items: flex-end;
  margin-bottom: 90px;
}

.clock-and-loc-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
}

.clock-wrapper {
  display: flex;
  align-items: center;
}

.clock-wrapper img {
  margin-right: 15px;
}

.detailInfo-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}
.detailInfo-wrapper h4,
.participantsList {
  font-family: "Montserrat";
  color: #3a3d43;
  font-weight: bold;
  line-height: 39px;
  margin-bottom: 20px;
}

.detailInfo-wrapper p {
  font-family: "Lato";
  color: #3a3d43;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.participant-container {
  column-count: 5;
  margin-bottom: 36px;
}

.participant-company-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.participant-company {
  margin-left: 12px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
}

.participant-see-allBtn {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  font-family: "Poppins";
  font-family: 600;
  font-size: 16px;
  line-height: 30px;
  color: #3a3d43;
}

.participants-wrapper {
  margin-bottom: 60px;
}

.event-location-container {
  margin-bottom: 171px;
}

.clockIcon {
  margin-left: 31px;
}
.event-location-container h4 {
  margin-bottom: 26px;
}

@media (max-width: 1020px) {
  .section-one-bg-mob-events {
    display: flex;
    align-items: flex-end;
    background: url("../../images/event-page/mobBG.jpg");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .eventDetail-bg {
    display: none;
  }
  .participant-container {
    column-count: 2;
  }
  .event-firstsection {
    align-items: center;
    flex-direction: column;
  }
  .date-wrapper-DetailPage {
    margin-top: 0;
    flex-direction: row;
  }
  .clock-and-loc-container {
    margin-top: 20px;
    align-items: baseline;
  }
  .clock-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .clockIcon {
    margin-left: 0;
  }
}

@media (max-width: 640px) {
  .participant-container {
    column-count: 1;
  }
  .eventDetail-section {
    padding: 0 20px;
  }
}
