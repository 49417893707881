.winery-section {
  padding: 0 10.5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.winery-experience {
  padding: 0 20px;
}
.border-for-winery {
  border: 1px solid #dedada;
  margin-bottom: 20px;
  width: 350px;
}
.winery-right-block {
  margin-left: 68px;
  width: 350px;
}

.wine-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
  color: #3a3d43;
  font-family: "Poppins";
}

.right-block-title {
  font-size: 22px;
  font-weight: bold;
  color: #3a3d43;
  margin-bottom: 37px;
}

.wineyh5 {
  margin-top: 40px;
  margin-bottom: 15px;
  color: #3a3d43;
  font-size: 22px;
  font-weight: bold;
}

.winery-left-block {
  min-width: 70%;
}
.left-block-aboutwine {
  font-family: "Poppins";
  color: #3a3d43;
  font-size: 16px;
  margin-bottom: 45px;
}
.right-block-img {
  width: 100%;
}
.awards {
  color: #3a3d43;
  font-size: 22px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.awards-list {
  margin-bottom: 20px;
  color: #3a3d43;
  font-family: "Poppins";
  font-size: 16px;
}

.our-wines {
  display: flex;
  justify-content: space-between;
  margin-top: 42px;
  margin-bottom: 48px;
}

.our-wines-title {
  color: #3a3d43;
  font-size: 22px;
  font-weight: bold;
}
.duration {
  display: flex;
}

.keyCname {
  width: 155px;
  margin-top: 20px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: var(--text-black);
}
.valueclassName {
  margin-top: 20px;
  font-family: "Poppins";
  font-weight: 400;
  color: var(--text-black);
  opacity: 0.7;
}

.about-wine {
  padding: 32px 0 16px 0;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  color: var(--text-black);
}

.spans {
  display: flex;
  width: 240px;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 34px;
}

.spans .price {
  font-size: 24px;
  font-weight: 500;
  font-family: "Poppins";
  color: var(--dark);
}
.spans .person {
  font-size: 16px;
  font-family: "Poppins";
  color: var(--text-black);
  font-weight: 400;
  opacity: 0.7;
}
.from {
  font-size: 16px;
  color: #404348;
  padding: 20px 0;
  font-weight: 400;
}

.advantages {
  display: flex;
  margin-top: 20px;
}

.advantages img {
  margin-right: 10px;
}
.view-detail {
  border: none;
  background-color: var(--white);
  cursor: pointer;
  border-bottom: 1px solid var(--text-black);
  color: var(--text-black);
  font-size: 14px;
  margin-top: 39px;
}

.input-submit {
  width: 100%;
  height: 57px;
  border: none;
  background-color: var(--brown);
  color: var(--white);
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}
.reverve-btn {
  width: 100%;
  margin-top: 31px;
  height: 57px;
  border: none;
  background-color: var(--brown);
  color: var(--white);
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
.client-review {
  font-size: 22px;
  color: var(--text-black);
  margin-top: 60px;
  margin-bottom: 40px;
}
.message-title {
  font-size: 22px;
  color: var(--text-black);
  margin-top: 105px;
  margin-bottom: 12px;
}
.message-inputs {
  background-color: #dedada;
  padding: 22px;
  border-radius: 5px;
  margin-bottom: 144px;
}

.text-input {
  width: 100%;
  height: 55px;
  border: none;
  border-radius: 5px;
  padding: 14px 15px;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 100;
  color: #807c7c;
  margin-bottom: 12px;
  outline: none;
}

.message-textarea {
  width: 100%;
  height: 177px;
  resize: none;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 14px 15px;
}

.tab-lists {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding-left: 10.5%;
  margin-top: 50px;
  margin-bottom: 75px;
}

.tab-Head {
  cursor: pointer;
  padding: 16px;
}

.active-tab {
  border-bottom: 3px solid #e79a74;
}

@media (max-width: 1080px) {
  .winery-section {
    flex-direction: column;
    align-items: center;
  }
  .right-block-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .winery-left-block {
    width: 100%;
  }
  .winery-right-block {
    margin-left: 0;
  }
}

@media (max-width: 640px) {
  .winery-section {
    padding: 0 20px;
  }
  .tab-lists {
    display: none;
  }
  .wine-title {
    display: none;
  }
  .tab-Head {
    padding: 8px;
  }
  .tab-lists {
    padding: 0;
  }
  .winery-right-block {
    padding: 0;
    margin-left: 0;
  }
  .winery-left-block {
    width: 100%;
  }
  .winery-right-block {
    display: none;
  }
}
