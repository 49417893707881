.region-sort-absolute {
  position: absolute;
  top: 28px;
  left: 0;
  z-index: 1000;
  background-color: var(--white);
  padding: 47px 20px 30px 30px;
  border: 1px solid rgba(26, 26, 26, 0.16);
}

.winery-titles {
  margin-top: 120px;
}

.Wine-type-sort-absolute {
  position: absolute;
  top: 28px;
  left: 150px;
  z-index: 1000;
  background-color: var(--white);
  padding: 47px 20px 30px 30px;
  border: 1px solid rgba(26, 26, 26, 0.16);
}

.Teritories-sort-absolute {
  position: absolute;
  top: 28px;
  left: 338px;
  z-index: 1000;
  background-color: var(--white);
  padding: 47px 20px 30px 30px;
  border: 1px solid rgba(26, 26, 26, 0.16);
}
.label-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 44px;
}

.label-left-block,
.label-right-block {
  margin-right: 20px;
}

.label-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.label-wrapper label {
  margin-left: 17px;
  width: 200px;
}

.label-wrapper input {
  width: 25px;
  height: 25px;
  accent-color: #707070;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}

.clear-all-btn {
  color: #3a3d43;
  border: none;
  font-family: "Poppins";
  background-color: transparent;
  cursor: pointer;
}

.btn-submit {
  width: 177px;
  height: 56px;
  background-color: #3a3d43;
  border: none;
  border-radius: 8px;
  font-family: "Montserrat";
  font-size: 18px;
  color: var(--white);
  cursor: pointer;
}

.input-sort-container {
  display: flex;
  align-items: center;
}
.winery-input {
  padding: 14px 18px;
  border: 1px solid #d6d6d6;
  border-radius: 1px;
  outline: none;
  color: red;
  width: 330px;
}

.searchs-icon {
  position: absolute;
  right: 5%;
  top: 25%;
  width: 24px;
  height: 24px;
}

.input-search-img-mob {
  display: none;
}
.winerySelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* border: none; */
  border: 1px solid #d6d6d6;
  outline: none;
  padding-right: 15px;
  background-image: url("../../images/wineries/arrowDowns.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 10px;
  padding: 15px 20px;
  width: 204px;
}

.wineriPageSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* border: none; */
  border: 1px solid #d6d6d6;
  outline: none;
  padding-right: 15px;
  background-image: url("../../images/wineries/arrowDowns.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 6px) center;
  background-size: 10px;
  padding: 10px 20px 10px 5px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 138px 0;
}

.pagination-prev {
  margin-right: 4px;
}

.pagination-next {
  margin-left: 4px;
}

.pagination-container a {
  padding: 22px;
  color: #201e1e;
  cursor: pointer;
}

.pagination-active {
  border-bottom: 2px solid #201e1e;
}

.pagination-disable {
  color: rgba(32, 30, 30, 0.2) !important;
}

.grsort {
  display: none;
}
.list-container-mob-tab {
  display: none;
}

.select-container {
  display: flex;
  align-items: center;
  margin-right: 45px;
}
.list-Container {
  display: grid;
}
.winery-cont {
  padding: 0 10.5%;
}

.error-message {
  background-color: #fce4e4;
  border: 1px solid #fcc2c3;
  float: left;
  padding: 20px 30px;
  color: red;
}

.wineries-bg {
  width: 100%;
  height: 290px;
  background: url("../../images/wineries/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 151px;
}

.section-one-bg-mob-winery {
  display: none;
}
.wineries-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sorts-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  /* min-width: 1300px; */
}
.sort-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.right-sort-container {
  display: flex;
  align-items: center;
}

.sort-wrapper {
  display: flex;
  align-items: center;
}

.text-container-winery-title {
  font-size: 16px;
  margin-bottom: 20px;
}

.boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxes img {
  margin-right: 17px;
  cursor: pointer;
}

.list-Container {
  display: grid;
  grid-template-columns: repeat(3, 400px);
  grid-gap: 52px;
}

.text-container-small-text {
  font-family: "Poppins";
  color: var(--white);
  font-size: 16px;
}

/* @media (max-width: 1080px) {
  .sorts-cont {
    min-width: 0;
  }
} */

/* loader */
.loader {
  --dim: 3rem;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
}

.loarder-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
}

.loader .circle {
  --color: #333;
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%,
  25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%,
  50% {
    transform: scale(1) rotate(180deg);
  }

  70%,
  75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.sorts-cont .TextField__label {
  color: black;
}
.sorts-cont .MuiInputBase-input,
.sorts-cont .MuiButtonBase-root {
  color: black;
}

.winery-input-width {
  width: 333px;
  position: relative;
  margin-right: 41px;
}
.sorts-cont .css-1h51icj-MuiAutocomplete-root {
  width: 330px;
}
@media (max-width: 1024px) {
  .grsort {
    display: block;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #201e1e;
  }
  .sort-container {
    display: none;
  }
  .boxes {
    display: none;
  }
  .list-Container {
    display: none;
  }
  .list-container-mob-tab {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 52px;
  }
  .select-container {
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
    justify-content: space-between;
  }
  .input-sort-container {
    display: none;
  }
  .input-search-img-mob {
    display: block;
    margin-right: 0;
    position: relative;
    margin-bottom: 21px;
  }
  .winery-input-mob {
    width: 100%;
    padding: 14px 18px;
    border: 1px solid #d6d6d6;
    border-radius: 1px;
    outline: none;
  }

  .sort-containers {
    position: absolute;
    z-index: 1000;
    background-color: var(--white);
    border: 1px solid rgba(26, 26, 26, 0.16);
  }
  .label-options {
    flex-direction: column;
  }

  .Wine-type-sort-absolute {
    top: 55px;
    left: -3px;
  }

  .Teritories-sort-absolute {
    top: 82px;
    left: 0;
  }
  .sorts-cont {
    min-width: 752px;
  }
  .winery-cont {
    padding: 45px 3%;
  }
}

@media (max-width: 640px) {
  .list-container-mob-tab {
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-gap: 52px;
  }
  .sorts-cont {
    min-width: 350px;
  }
  .input-sort-container {
    display: none;
  }
  .text-container {
    text-align: center;
  }
  .text-container-winery-title {
    margin-bottom: 0;
  }
  .wineries-bg {
    display: none;
  }
  .section-one-bg-mob-winery {
    display: flex;
    align-items: flex-end;
    background: url("../../images/winery-bg-img-mob.png");
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-size: cover;
    background-position: center center;
  }
  .text-container h2 {
    font-size: 28px;
  }
  .input-search-img-mob {
    display: block;
    margin-right: 0;
    position: relative;
    margin-bottom: 21px;
  }
  .winery-input-mob {
    width: 100%;
    padding: 14px 18px;
    border: 1px solid #d6d6d6;
    border-radius: 1px;
    outline: none;
  }
  .winery-titles {
    display: none;
  }
  .sorts-cont .css-1h51icj-MuiAutocomplete-root {
    width: 100%;
  }
}
