.mobileApp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
}

.mob-app-logo {
  margin-bottom: 129px;
}

.mobileApp-container h1 {
  padding-bottom: 36px;
  border-bottom: 1px solid #b54d2e;
  color: #b54d2e;
  font-size: 48px;
  line-height: 58px;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 70px;
}
.mobileApp-container p {
  font-family: "Montserrat";
  font-size: 32px;
  line-height: 39px;
  color: #333333;
  margin-bottom: 47px;
}
