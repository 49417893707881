/* package.css */

.package-container {
  display: flex;
  justify-content: space-between;
  padding: 0 10.5%;
  border-radius: 4px;
  margin-bottom: 200px;
}

.package-cont {
  border: 1px solid #d3d5e0;

  width: 32%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d3d5e0;
  margin-right: 70px;
  position: relative;
  box-shadow: 0 4px 11px 3px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.5s ease-in-out; /* Hover transition */
}

.package-cont:nth-child(3) {
  margin-right: 0;
}
.package-wrapper {
  padding: 33px 33px 0 33px;
  overflow: hidden;
  max-height: 80vh;
}

.package-benefit-wrapper {
  display: flex;
  align-items: baseline;
  margin-bottom: 12px; /* Adjust as needed */
}

.package-benefit-wrapper img {
  margin-right: 10px; /* Adjust as needed */
  max-width: 20px;
}

.package-benefit-wrapper p {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  color: #393b40;
}

.price-wrapper {
  display: flex;
  align-items: center;
  margin-top: 72px;
}

.package-wrapper-title {
  text-transform: uppercase;
  font-family: "Open Sans";
  font-size: 16px;
  color: #393b40;
  margin-bottom: 39px;
}

.price-wrapper p {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 16px;
  margin-right: 10px;
  color: #393b40;
}

.price-wrapper span {
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: 700;
  color: #393b40;
}

.package-btn {
  width: 100%;
  height: 80px;
  border: none;
  cursor: pointer;
  font-family: "Open Sans";
  color: #393b40;
  background-color: #f1f1f3;
  font-weight: 700;
  font-size: 18px;
  transition: background-color 0.3s ease; /* Button hover transition */
  margin-top: 49px;
}

.center-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mark {
  position: absolute;
  top: 3%;
  right: -3.5%;
  width: 120px;
  height: 40px;
  background-color: #b44d2d;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.triangle-topright {
  position: absolute;
  top: 11%;
  right: -3.5%;
  border-style: solid;
  border-width: 25px 12px 0 0;
  border-color: #b44d2d transparent transparent transparent;
  transform: rotate(0);
}

.wine-pass-see-details {
  padding-left: 33px;
  font-family: "Poppins";
  font-weight: 600;
  color: #3a3d43;
  line-height: 30px;
  font-size: 16px;
  text-decoration: underline;
}
.price-wrapper {
  padding-left: 33px;
  margin-bottom: 33px;
}

.wine-pass-coming {
  font-size: 14px;
  margin-top: 10px;
  color: rgba(57, 59, 64, 0.5);
}

.package-cont:hover {
  transform: scale(1.05); /* Scale the card on hover */
}

.package-cont:hover .package-wrapper-title {
  color: #b44d2d; /* Title color on hover */
}

.package-cont:hover .package-btn {
  background-color: #393b40; /* Button background color on hover */
  color: #fff; /* Button text color on hover */
}

.package-cont:hover .package-btn p {
  color: rgba(255, 255, 255, 0.5);
}

.modal-container {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  border: none;
  background-color: white;
  width: 1200px;
}

.wine-pass-popup-left-container {
  padding: 47px;
  background-color: #f2f2f2;
  width: 50%;
}

.wine-left-popup-head-container {
  display: flex;
  margin-bottom: 40px;
}

.wine-left-popup-head-container img {
  margin-right: 34px;
}

.wine-left-popup-head-container h2 {
  font-family: "Poppins";
  font-weight: 600;
  line-height: 30px;
  font-size: 20px;
  color: #3a3d43;
  margin-bottom: 14px;
}

.wine-left-popup-head-container h4 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3a3d43;
  margin-bottom: 17px;
}
.wine-left-popup-head-container span {
  font-family: "Poppins";
  font-weight: 400;
  color: #3a3d43;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
}

.count-btns-container {
  padding: 17px 24px;
  border: 1px solid rgba(41, 44, 49, 0.24);
  border-radius: 4px;
}

.count-btns-container button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.decr-btn,
.incr-btn,
.quantity {
  font-size: 20px;
}

.quantity {
  margin: 0 27px;
  color: #24262d;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.quantity-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #24262d;
}
.quantities-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.wine-pass-left-line {
  width: 100%;
  height: 1px;
  background-color: #c0c0c0;
}

.wine-pass-left-price {
  margin-top: 30px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.wine-pass-name-and-price {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.priority-wine-passport {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #3a3d43;
}

.x-quantity {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
  color: #3a3d43;
}

.span-count {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #b44d2d;
}
.total-wine-pass-price-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
}

.each-wine-pass-price {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 19.5px;
  color: #3a3d43;
}
.total-price-span {
  font-family: "Montserrat";
  font-weight: bold;
  color: #3a3d43;
  font-size: 20px;
  line-height: 24px;
}

.total-price-sec-span {
  font-family: "Montserrat";
  font-weight: 600;
  color: #3a3d43;
  font-size: 20px;
  line-height: 24px;
}

.wine-pass-popup-right-container {
  padding: 47px 56px;
  width: 50%;
  position: relative;
}

.pay-close-btn {
  position: absolute;
  right: 4%;
  cursor: pointer;
  top: 4%;
}
.wine-pass-popup-right-container h2 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 30px;
}

.pay-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.pay-card-container input {
  margin-right: 8px;
}

.pay-card-container span {
  font-family: "Lato";
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3a3d43;
}

.terms-wrapper {
  display: flex;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 50px;
}

.terms-wrapper input {
  margin-right: 12px;
}

.terms-wrapper p {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.8px;
  color: #222e2e;
}
.terms-wrapper span {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #b44d2d;
}

.pay-btn {
  padding: 20px 147px;
  background-color: #b44d2d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pay-popup-checkbox {
  width: 24px;
  height: 24px;
}

.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
  left: 57%;
  top: 24%;
  bottom: 0;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: white;
  }

  100% {
    background-color: transparent;
  }
}

.pay-check-span {
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .package-container {
    flex-direction: column;
    padding: 0 10px;
  }
  .package-cont {
    margin-right: 0;
    margin-top: 20px;
    width: 100%;
  }
  .modal-container {
    flex-direction: column;
    overflow-y: auto;
    max-height: 80vh;
    width: 100%;
    top: 60%;
  }
  .wine-pass-popup-left-container {
    width: 100%;
    padding: 27px;
  }
  .wine-pass-popup-right-container {
    width: 100%;
    padding: 27px;
  }
  .pay-close-btn {
    right: 5%;
    top: -121%;
  }
  .terms-wrapper {
    padding-left: 17px;
  }
}
