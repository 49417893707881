.similar-wine-section {
  padding: 0 10.5%;
  margin-bottom: 180px;
}

.similar-wine-content {
  border: 0.5px solid #d6d6d6;
}

.similar-wine-img {
  width: 100%;
  height: 241px;
  object-fit: cover;
}

.similar-wine-text-content {
  padding: 20px;
}

.similar-wine-title {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: bold;
  color: var(--dark);
  margin-bottom: 10px;
}

.similar-wine-p {
  font-family: "Poppins";
  overflow: hidden;
  height: 40px;
  font-size: 16px;
  color: rgba(81, 84, 89, 0.94);
  margin-bottom: 27px;
}

.similar-wine-location-rating {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
}

.similar-wine-location,
.similar-wine-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.similar-wine-rating-p {
  margin-right: 8px;
  font-size: 18px;
  color: rgba(58, 61, 67, 0.77);
}
.similar-wine-location-p {
  margin-left: 11px;
  font-family: "Poppins";
  color: rgba(58, 61, 67, 0.64);
  font-size: 16px;
}
.similar-wine-main-title {
  font-size: 22px;
  margin-top: 145px;
  margin-bottom: 35px;
}

.similar-wine-btn {
  width: 150px;
  height: 48px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  border: 0.5px solid #969696;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 500;
}

.wine-btn-flex {
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;
  text-decoration: none;
}

.onlyOne-wineryRegion {
  font-weight: bold;
  font-size: 20px;
  color: #b44d2d;
}

@media (max-width: 640px) {
  .similar-wine-section {
    padding: 0 20px;
  }
}
