.location-slider .swiper-slide {
  padding-top: 70%;
  position: relative;
  overflow: hidden;
}

.location-slider img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.swiper-button-prev {
  left: 30;
  color: black;
}

.swiper-button-next {
  right: 30;
  color: rgb(255, 255, 255);
}

.location-slider-thumbs {
  margin-top: 20px;
}

.region-small-img {
  /* margin-bottom: 42px; */
  height: 70px;
  width: 80px;
  user-select: none;
}

.locationss {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loc {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
  justify-content: space-around;
  /* padding: 0; */
  width: 162px;
  border-radius: 7px;
  height: 15vh;
  cursor: pointer;
  margin-left: 5px;
}
.loc span {
  user-select: none;
}

.loc-span {
  color: #2a2d33;
  font-family: "Open Sans";
  font-weight: 600;
}

.swiper {
  margin-bottom: 1% !important;
}
