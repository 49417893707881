.winePass-cont-forOverflow {
  overflow-x: hidden;
}

.section-one-bg {
  width: 100%;
  height: 290px;
  background: url("../../images/winepassport/Path\ 10372.png");
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 151px;
  background-size: cover;
  background-position: center;
}
.section-one-bg-mob {
  display: none;
}

.text-container {
  width: 35%;
  height: 100%;
  background-color: #24262c;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  justify-content: center;
  padding-left: 10.5%;
}

.text-container h2 {
  color: white;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 36px;
}

.section-one-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 32px;
  color: #3a3d43;
}

@media (max-width: 1024px) {
  .text-container {
    width: 50%;
  }
}

@media (max-width: 640px) {
  .section-one-title {
    font-size: 15px;
  }

  .section-one-bg {
    display: none;
  }

  .section-one-bg-mob {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 290px;
    background: url("../../images/mob-wine-passimg.png");
    background-repeat: no-repeat;
    position: relative;
    margin-bottom: 70px;
    background-size: cover;
    background-position: center center;
  }
  .text-container {
    width: 100%;
    height: 15vh;
    padding-left: 16px;
    align-items: flex-start;
  }
}
