.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10.5%;
}

.review-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 170px;
  color: #3a3d43;
}

.review-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  border: 1px solid #dedada;
  padding: 22px 91px;
  min-height: 300px;
}

.review-quote {
  font-family: "Poppins";
  font-size: 16;
  font-weight: 400;
  margin-top: 51px;
  margin-bottom: 15px;
  background-color: #efefef;
  padding: 20px;
  opacity: 0.36;
}

.review-author {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
}

.swiper-pagination-bullet-active {
  background-color: #393b40 !important;
}

@media (max-width: 1080px) {
  .navigation {
    padding: 0 20px;
  }
}
