.our-wine {
  /* width: 900px; */
  height: 300px;
  margin-bottom: 100px;
}

.our-wine-card {
  display: flex;
  align-items: center;
  width: 300px;
  height: 300px;
  user-select: none;
}
.view-all-our-wines {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.our-wine-cards {
  margin-bottom: 58px;
}

.swiper {
  margin-bottom: 50px;
}

@media (max-width: 1020px) {
  .our-wine-cards {
    margin-right: 10px;
  }
  .our-wine-card {
    width: 290px;
  }
}
