.passport-container {
  display: flex;
  background-color: var(--bg-color);
  margin: 0 10.5%;
  margin-bottom: 213px;
}

.passport-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 0 89px;
}

.priority-winepass-title {
  color: var(--white);
}

/* left block style */
.passport-left-block {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 50%;
  /* padding-right: 127px; */
}

.passport-left-block-title {
  margin-bottom: 29px;
  color: var(--brown);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 20px;
}

.passport-left-block-subtitle {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 32px;
  color: #24262b;
  margin-bottom: 30px;
}
.passport-left-block-paragraph {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 300;
  color: var(--text-black);
  margin-bottom: 50px;
}

.passport-left-block-span {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
  color: #3a3d43;
  line-height: 30px;
}

.buy-now-btn {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  background-color: var(--brown);
  width: 170px;
  height: 60px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 63px;
}

.buy-now-btn-mob {
  display: none;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  background-color: var(--brown);
  width: 170px;
  height: 60px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 63px;
}

/* right block style */
.passport-right-block {
  background-color: #3a3c42;
  padding: 61px 67px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-left: 120px;
}

.passport-right-block-title {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 32px;
  color: var(--white);
  margin-bottom: 40px;
}

.passport-right-block-subtitle {
  font-family: "Montserrat";
  font-size: 30px;
  color: var(--white);
  opacity: 0.5;
  margin-bottom: 74px;
}

.prioritywine-wrapper {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
}

.prioritywine-wrapper:nth-child(:last-child) {
  margin-bottom: 0;
}

.prioritywine-img {
  margin-right: 10px;
}

.priority-winepass-title {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: var(--white);
}

.priority-wine-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 72px;
}
.span-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.span-wrapper {
  color: var(--white);
  opacity: 0.5;
  font-family: "Poppins";
  font-size: 16px;
}

.span-value {
  font-family: "Poppins";
  font-size: 16px;
  color: var(--white);
  margin-top: 15px;
}

@media (max-width: 1024px) {
  .passport-container {
    margin: 0 150px 213px 150px;
  }
  .passport-wrapper {
    flex-direction: column;
  }
  .passport-right-block {
    margin-left: 0;
    margin-top: 50px;
  }
  .passport-left-block {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .passport-container {
    margin: 0;
    background-color: var(--white);
  }
  .passport-left-block {
    width: 100%;
    padding: 24px;
    background-color: #f1f1f3;
  }
  .passport-right-block {
    margin-top: 0;
  }
  .passport-wrapper {
    align-items: baseline;
    padding: 33px 10px 40px 10px;
  }
  .passport-right-block-subtitle {
    font-size: 28px;
  }
  .buy-now-btn {
    display: none;
  }
  .buy-now-btn-mob {
    display: block;
    margin-top: 30px;
    margin-bottom: 70px;
  }
}
